import { getI18n } from 'react-i18next';

import Config from '../../../../Config';
import Logger from '../../../../lib/Logger';
import Events from '../../../../lib/EventEmitter';

import DashboardAdminScreen from './containers/DashboardAdminScreenContainer';

const register = () => {
  Logger.log('debug', `dashboardAdmin.register()`);

  if (Config.getIn(['MODULE_TOGGLES', 'dashboardAdmin', 'routes'])) {
    Events.dispatch('ADD_ADMIN_ROUTES', {
      DashboardAdminScreen: [
        'PrivateRoute',
        '/admin/dashboard/:page_recommended_utilities(\\d+)?/:page_resources(\\d+)?',
        true,
        DashboardAdminScreen,
        getI18n().t('dashboard_admin_route_list'),
      ],
    });
  }
};

export default register;

Logger.log('silly', `dashboardAdmin.register() loaded.`);
