import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Translation } from 'react-i18next';
import { Row, Col, Typography, Card, DatePicker } from 'antd';

import DashboardUpcomingEvents from '../../calendar/containers/DashboardUpcomingEventsContainer';
import DashboardCareHelpers from '../../careGuideHelpers/containers/DashboardCareHelpersContainer';
import DashboardRecommendedUtilities from '../../recommendedUtilities/containers/DashboardRecommendedUtilitiesContainer';
import Logger from '../../../../../lib/Logger';
import DailyLogs from '../../partnershipFormAnswers/containers/DailyLogsContainer';
import LoadingIcon from '../../../elements/components/LoadingIcon';
import DashboardResources from '../../resources/containers/DashboardResourcesContainer';
import useWindowDimensions from '../../../../../lib/WindowDimensions';
import DashboardEventInvitationsList from '../../calendar/containers/DashboardEventInvitationsListContainer';
import DashboardInvitationsList from '../../careGuideInvitations/containers/DashboardInvitationsListContainer';
import DashboardCareGuideInvitationsQuickList from '../../careGuideHelpers/containers/DashboardCareGuideInvitationsQuickListContainer';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const DashboardDetailPartnership = ({
  careGuideId,
  partnershipId,
  partnership,
  page,
  limit,
  order,
  list,
  load,
  isLoading,
  ...props
}) => {
  const { width } = useWindowDimensions();
  const isSmall = width <= 576;
  const isMedium = (width) => 768 && width <= 992;

  const [page_recommended_utilities, setPageRecommendedUtilities] = useState(1);
  const [page_daily_logs, setPageDailyLogs] = useState(1);
  const [dates, setDates] = useState({});

  const onChangeDateHandler = (dates) => {
    const params = {
      start: dates && dates[0] ? dates[0].format('YYYY-MM-DD') : '',
      end: dates && dates[1] ? dates[1].format('YYYY-MM-DD') : '',
    };
    setDates(params);
  };

  useEffect(() => {
    load(partnershipId, page, limit, order);
  }, [partnershipId, page, limit, order, load]);

  return (
    <Translation>
      {(t) => (
        <Row
          gutter={16}
          style={{
            margin: isSmall ? '0' : isMedium ? '20px 10px 0' : '40px 20px 0',
          }}
        >
          <Col
            lg={{ span: 18, order: 1 }}
            md={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
          >
            {isLoading ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoadingIcon />
              </div>
            ) : (
              list.map(
                (x) =>
                  (x.type !== 2 ||
                    (x.type === 2 &&
                      partnership?.cognito_form_key !== null &&
                      partnership.cognito_form_key !== null)) && (
                    <Card bordered={false} className="section-card">
                      <div>
                        <Row justify="space-between" style={{ width: '100%' }}>
                          <Col>
                            <Title level={4}>
                              {t('dashboard_section_type_' + x.type)}
                            </Title>
                          </Col>
                          {x.type === 2 && (
                            <Col>
                              <RangePicker
                                size="large"
                                format="MM/DD/YYYY"
                                onChange={onChangeDateHandler}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          {x.type === 1 && (
                            <DashboardResources
                              careGuideId={careGuideId}
                              partnershipId={partnershipId}
                            />
                          )}
                          {x.type === 2 && (
                            <DailyLogs
                              page_daily_logs={page_daily_logs}
                              setPageDailyLogs={setPageDailyLogs}
                              careGuideId={careGuideId}
                              partnershipId={partnershipId}
                              dates={dates}
                            />
                          )}
                          {x.type === 3 && (
                            <DashboardRecommendedUtilities
                              page_recommended_utilities={
                                page_recommended_utilities
                              }
                              setPageRecommendedUtilities={
                                setPageRecommendedUtilities
                              }
                              careGuideId={careGuideId}
                              partnershipId={partnershipId}
                              component={'TEST'}
                            />
                          )}
                        </Row>
                      </div>
                    </Card>
                  )
              )
            )}
          </Col>

          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            xs={{ span: 24, order: 1 }}
          >
            <DashboardInvitationsList
              page={1}
              limit={5}
              order={'invited_at.desc'}
            />

            <DashboardEventInvitationsList
              careGuideId={careGuideId}
              start={moment().format('YYYY-MM-DD')}
              page={1}
              limit={5}
              order={'start_at.asc'}
            />

            <DashboardUpcomingEvents
              careGuideId={careGuideId}
              start={moment().format('YYYY-MM-DD')}
              end={moment().add(1, 'Y').format('YYYY-MM-DD')}
              page={1}
              limit={3}
              order={'start_at.asc'}
            />
            <DashboardCareGuideInvitationsQuickList
              careGuideId={careGuideId}
              page={1}
              limit={5}
              order={'joined_at.asc'}
            />

            <DashboardCareHelpers
              careGuideId={careGuideId}
              page={1}
              limit={5}
              order={'joined_at.asc'}
            />
          </Col>
        </Row>
      )}
    </Translation>
  );
};

export default DashboardDetailPartnership;

Logger.log('silly', `DashboardDetailPartnership loaded.`);
