import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation, getI18n } from 'react-i18next';
import { Table, Space, Checkbox, List } from 'antd';
import notification from '../../../elements/lib/NotificationWrapper';
import { StatusTag } from '../../../elements/components/Tags';
import { pathTo } from '../../../Routes';
import Format from '../../../../../lib/Format';
import QueryString from '../../../../../lib/QueryString';
import Logger from '../../../../../lib/Logger';
import ResourceAdminListHeader from './ResourceAdminListHeader';
import ListAction from './ListAction';
import { pinFilled } from '../../../elements/components/Icons';
import ResourceCard from './ResourceCard';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const ResourcesAdminList = ({
  component,
  page,
  limit,
  order,
  filter,
  total,
  view,
  load,
  remove,
  history,
  partnershipName,
  partnershipActiveId,
  showDetail,
  patch,
  search,
  loadIds,
  isLoadingIds,
  ids,
  selectedRowKeys,
  updateSelectedRowKeys,
  removes,
  showList,
  downloadCSV,
  downloadPDF,
  isDownload,
  ...props
}) => {
  const confirmShowPreview = (event, item) => {
    event.preventDefault();
    trackerResource(item, mixpanelEventsEnum.RESOURCE.OPEN_PREVIEW);
    showDetail(item.id);
  };

  const confirmDelete = (
    partnershipActiveId,
    item,
    page,
    limit,
    order,
    filter
  ) => {
    remove(partnershipActiveId, item.id, (success) => {
      if (success) {
        trackerResource(item, mixpanelEventsEnum.RESOURCE.DELETE);

        selectInvertAll(false);
        load(partnershipActiveId, page, limit, order, filter);
        notification.success(getI18n().t('feedback_delete_success'));
      } else {
        notification.error(getI18n().t('feedback_delete_error'));
      }
    });
  };

  const confirmDeletes = (partnershipActiveId, page, limit, order, filter) => {
    removes(
      partnershipActiveId,
      { resources_ids: selectedRowKeys },
      (success) => {
        if (success) {
          const params = QueryString.parse(history.location.search);
          const path = pathTo('ResourcesAdminScreen', {
            page: 1,
          });

          MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.DELETES, {
            partnershipName: partnershipName,
            partnershipId: partnershipActiveId,
          });

          history.push(QueryString.append(path, params));
          selectInvertAll(false);
          load(partnershipActiveId, page, limit, order, filter);
          notification.success(getI18n().t('feedback_delete_success'));
        } else {
          notification.error(getI18n().t('feedback_delete_error'));
        }
      }
    );
  };

  const handlePinned = (
    partnershipActiveId,
    item,
    page,
    limit,
    order,
    filter
  ) => {
    const payload = { is_pinned: !item.is_pinned };

    patch(partnershipActiveId, item.id, payload, (success) => {
      if (success) {
        load(partnershipActiveId, page, limit, order, filter);
        if (!item.is_pinned) {
          trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_PINNED, {
            state: 'Pinned',
          });
          notification.success(
            getI18n().t('resource_form_button_update_pinned_success')
          );
        } else {
          trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_PINNED, {
            state: 'Unpinned',
          });
          notification.success(
            getI18n().t('resource_form_button_update_unpinned_success')
          );
        }
      } else {
        notification.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  const handleDisabled = (
    partnershipActiveId,
    item,
    status,
    page,
    limit,
    order,
    filter
  ) => {
    patch(partnershipActiveId, item.id, { status: status }, (success) => {
      if (success) {
        load(partnershipActiveId, page, limit, order, filter);
        if (status === 1) {
          trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_DISABLE, {
            state: 'Enabled',
          });
          notification.success(
            getI18n().t('resource_form_button_update_enabled_success')
          );
        } else {
          trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_DISABLE, {
            state: 'Disabled',
          });
          notification.success(
            getI18n().t('resource_form_button_update_disabled_success')
          );
          load(partnershipActiveId, page, limit, order, filter);
        }
      } else {
        notification.error(getI18n().t('feedback_form_error'));
      }
    });
  };

  const handleDownloadCSV = (partnershipId, partnershipName, item) => {
    downloadCSV(partnershipId, partnershipName, item.id, (success) => {
      if (success) {
        trackerResource(item, mixpanelEventsEnum.RESOURCE.EXPORT_CSV);

        notification.success(
          getI18n().t('resource_card_action_download_success')
        );
      } else {
        notification.error(
          getI18n().t('resource_card_action_download_failure')
        );
      }
    });
  };

  const handleDownloadPDF = (partnershipId, partnershipName, item) => {
    downloadPDF(partnershipId, partnershipName, item.id, (success) => {
      if (success) {
        trackerResource(item, mixpanelEventsEnum.RESOURCE.EXPORT_PDF);

        notification.success(
          getI18n().t('resource_card_action_download_success')
        );
      } else {
        notification.error(
          getI18n().t('resource_card_action_download_failure')
        );
      }
    });
  };

  const trackerResource = (item, event, params = {}) => {
    MixpanelTracker.mixpanelTrack(event, {
      partnershipName: partnershipName,
      partnershipId: partnershipActiveId,
      resourceId: item.id,
      resourceName: item.name,
      ...params,
    });
  };

  const columns = [
    {
      title: getI18n().t('resources_name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      render: (name, item) => (
        <>
          {item.is_pinned && (
            <Space
              className={'resource-pin-active'}
              onClick={() =>
                handlePinned(
                  partnershipActiveId,
                  item,
                  page,
                  limit,
                  order,
                  filter
                )
              }
            >
              {pinFilled(12, 15)}
            </Space>
          )}
          {/* eslint-disable jsx-a11y/anchor-is-valid */}
          <a href="#" onClick={(e) => confirmShowPreview(e, item)}>
            {item.name}
          </a>
        </>
      ),
    },
    {
      title: getI18n().t('table_header_status'),
      dataIndex: 'status',
      key: 'status',
      render: (code) => <StatusTag status={code} />,
    },
    {
      title: getI18n().t('resources_assigned_to'),
      dataIndex: 'assigned_to',
      key: 'assigned_to',
    },
    {
      title: getI18n().t('resources_created_by'),
      dataIndex: 'profile_name',
      key: 'profile_name',
    },
    {
      title: getI18n().t('table_header_created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => Format.dateTable(date),
      defaultSortOrder:
        order === 'created_at.asc'
          ? 'ascend'
          : order === 'created_at.desc'
          ? 'descend'
          : null,
      sorter: true,
    },
    {
      title: getI18n().t('table_header_action'),
      key: 'actions',
      width: 77,
      fixed: 'right',
      render: (row) => (
        <>
          <ListAction
            row={row}
            partnershipActiveId={partnershipActiveId}
            editScreen="ResourceAdminEditScreen"
            handlePinned={handlePinned}
            handleDisabled={handleDisabled}
            partnershipName={partnershipName}
            handleDownloadCSV={handleDownloadCSV}
            handleDownloadPDF={handleDownloadPDF}
            isDownload={isDownload}
            page={page}
            limit={limit}
            order={order}
            filter={filter}
            confirmDelete={() =>
              confirmDelete(
                partnershipActiveId,
                row,
                page,
                limit,
                order,
                filter
              )
            }
          />
        </>
      ),
    },
  ];

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
    position: ['bottomCenter'],
  };

  const paginationList = {
    onChange: (newPage, pageSize) => {
      let path = props.location.pathname;
      const params = {};

      path = pathTo(component, { page: newPage, limit: pageSize });

      // handle filters
      if (filter) {
        for (const key in filter) {
          if (filter[key]) {
            params[key] = filter[key];
          }
        }
      }

      // handle search
      if (search !== undefined && search !== '') {
        params['search'] = search;
      }

      history.push(QueryString.append(path, params));
    },
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let path = props.location.pathname;
    const paramsOld = QueryString.parse(props.location.search);
    const view = paramsOld.view;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, { page: pagination['current'] });
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const order =
          sorter['field'] +
          '.' +
          (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = order;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    // handle search
    if (search !== undefined && search !== '') {
      params['search'] = search;
    }

    // handle view
    if (view) {
      params['view'] = view;
    }

    history.push(QueryString.append(path, params));
  };

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(partnershipActiveId, page, limit, order, JSON.parse(filterString));
  }, [partnershipActiveId, page, limit, order, filterString, load]);

  useEffect(() => {
    loadIds(partnershipActiveId, JSON.parse(filterString));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipActiveId, filterString]);

  const selectInvertAll = (selectAllData) => {
    if (ids.length > 0 && selectAllData) {
      updateSelectedRowKeys(ids, false, true);
    } else {
      updateSelectedRowKeys([], false, true);
    }
  };

  const onSelectChange = (record, selected) => {
    updateSelectedRowKeys(record.key, selected, false);
  };

  const toggleSelectAll = (e) => {
    if (e.target.checked) {
      selectInvertAll(true);
    } else {
      if (selectedRowKeys.length > 0 && selectedRowKeys.length !== total) {
        selectInvertAll(true);
      } else {
        selectInvertAll(false);
      }
    }
  };

  const headerCheckbox = (
    <Checkbox
      checked={selectedRowKeys.length}
      indeterminate={
        selectedRowKeys.length > 0 && selectedRowKeys.length < total
      }
      onChange={toggleSelectAll}
    />
  );

  const rowSelection = {
    selectedRowKeys,
    type: 'checkbox',
    fixed: true,
    onSelect: onSelectChange,
    columnTitle: headerCheckbox,
  };

  return (
    <Translation>
      {(t) => (
        <>
          <ResourceAdminListHeader
            selectedRowKeys={selectedRowKeys}
            total={total}
            showList={props.list.length}
            partnershipActiveId={partnershipActiveId}
            selectInvertAll={selectInvertAll}
            confirmDeletes={() =>
              confirmDeletes(partnershipActiveId, 1, limit, order, filter)
            }
            view={view}
            page={page}
            limit={limit}
          />

          {view === 'table' && (
            <Table
              className="resource-list-table"
              dataSource={props.list}
              columns={columns}
              pagination={page === 1 && total <= limit ? false : pagination}
              loading={props.isLoading}
              scroll={{ x: true }}
              onChange={handleTableChange}
              rowSelection={!isLoadingIds && rowSelection}
              component={component}
              rowClassName={(record) => record.status !== 1 && 'disabled-row'}
            />
          )}
          {view === 'grid' && (
            <List
              className="resources-list"
              dataSource={props.list}
              onChange={handleTableChange}
              loading={props.isLoading}
              grid={{
                gutter: 18,
                xs: 1,
                sm: 2,
                md: 2,
                lg: 3,
                xl: 3,
                xxl: 3,
              }}
              pagination={page === 1 && total <= limit ? false : paginationList}
              renderItem={(item) =>
                item?.id !== undefined && (
                  <List.Item>
                    <ResourceCard
                      item={item}
                      partnershipActiveId={partnershipActiveId}
                      partnershipName={partnershipName}
                      handlePinned={handlePinned}
                      handleDisabled={handleDisabled}
                      handleDownloadCSV={handleDownloadCSV}
                      handleDownloadPDF={handleDownloadPDF}
                      isDownload={isDownload}
                      confirmDelete={confirmDelete}
                      page={page}
                      limit={limit}
                      order={order}
                      filter={filter}
                      confirmShowPreview={confirmShowPreview}
                      showList={showList}
                    />
                  </List.Item>
                )
              }
            />
          )}
        </>
      )}
    </Translation>
  );
};

export default withRouter(ResourcesAdminList);

Logger.log('silly', `ResourcesAdminList loaded.`);
