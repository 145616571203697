import React from 'react';
import { Typography, Checkbox } from 'antd';
import { pinFilled } from '../../../elements/components/Icons';
import ReactQuill from 'react-quill';
import { TelephoneDisplayAdmin } from '../../../elements/components/TelephoneDisplay';
import { WebsiteDisplayAdmin } from '../../../elements/components/WebsiteDisplay';
import { EmailDisplayAdmin } from '../../../elements/components/EmailDisplay';

const { Title } = Typography;

const modules = {
  toolbar: '#toolbar-read-only-resource-card',
};

const DashboardAdminResourcesCard = ({
  item,
  disabledCheckbox = false,
  defaultChecked,
  onChange,
  addStyle,
  className,
  ...props
}) => {
  return (
    <div
      className={className}
      key={item.id}
      style={{
        border: item?.partnership
          ? `2px solid ${item?.partnership?.color_hex}`
          : null,
        ...addStyle,
      }}
    >
      <div
        className={'icon-active'}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Checkbox
          disabled={disabledCheckbox}
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        {pinFilled(12, 18)}
      </div>

      <Title className={`section-resource-card-admin-list-title`} level={5}>
        {item.name}
      </Title>
      {item?.description !== '<p><br></p>' && item?.description?.length > 0 && (
        <div className={`section-resource-card-admin-list-description`}>
          <div id="toolbar-read-only-resource-card"></div>
          <ReactQuill modules={modules} readOnly value={item.description} />
        </div>
      )}
      {(item?.website || item?.telephone || item?.email) && (
        <div className="info-container">
          {item?.website ? (
            <WebsiteDisplayAdmin website={item.website} />
          ) : null}
          {item?.telephone ? (
            <TelephoneDisplayAdmin telephone={item.telephone} />
          ) : null}
          {item?.email ? <EmailDisplayAdmin email={item.email} /> : null}
        </div>
      )}
    </div>
  );
};

export default DashboardAdminResourcesCard;
