import React from 'react';
import { Col, Row, Typography, Collapse, Button } from 'antd';
import { Translation } from 'react-i18next';

import {
  FileOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import Format from '../../../../../lib/Format';

const { Text } = Typography;
const { Panel } = Collapse;

const DailyLogsList = ({
  list,
  mock,
  onClickExportHandler,
  isActiveKey,
  partnershipId,
  activeKey,
  careGuideId,
  onChangeCollapse,
  ...props
}) => {
  const transformationText = (text) => {
    return Array.isArray(text.split(/(?=[A-Z])/))
      ? text.split(/(?=[A-Z])/).join(' ') + ': '
      : text.split(/(?=[A-Z])/)[0] + ': ';
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Collapse
            style={{ backgroundColor: 'white' }}
            activeKey={activeKey}
            onChange={onChangeCollapse}
          >
            {list
              ? list.map((x) => (
                  <>
                    {x?.answers ? (
                      <Panel
                        header={
                          <>
                            <Text
                              type={!isActiveKey(x.key) && 'secondary'}
                              style={{
                                fontSize: '16px',
                                color: isActiveKey(x.key) && '#4279BD',
                              }}
                            >
                              {Format.dateTable(x.created_at)}
                            </Text>
                          </>
                        }
                        key={x.key}
                        showArrow={false}
                        extra={
                          isActiveKey(x.key) ? (
                            <div
                              style={{
                                marginLeft: '12px',
                                textAlign: 'center',
                              }}
                            >
                              <CaretUpOutlined />
                            </div>
                          ) : (
                            <div
                              style={{
                                marginLeft: '12px',
                                textAlign: 'center',
                              }}
                            >
                              <CaretDownOutlined />
                            </div>
                          )
                        }
                      >
                        <Row gutter={24}>
                          <Col span={11}>
                            <Text strong>
                              {t('care_guide_owners_cognito_field_name') + ': '}
                            </Text>
                            <Text>
                              {x?.answers
                                .filter((item) => item.key === 'Name')
                                .map((item) => {
                                  return item.value;
                                })}
                            </Text>
                          </Col>
                          <Col span={11}>
                            <Text strong>
                              {t('care_guide_owners_cognito_field_date') + ': '}
                            </Text>
                            <Text>
                              {x?.answers
                                .filter((item) => item.key === 'Date')
                                .map((item) => {
                                  return item.value;
                                })}
                            </Text>
                          </Col>
                        </Row>
                        <>
                          {x?.answers
                            .filter(
                              (item) =>
                                item.key !== 'Name' &&
                                item.key !== 'Date' &&
                                item.key !== 'Comments' &&
                                item.key !== 'Information'
                            )
                            .map((item, index, array) => {
                              if (index % 2 === 0) {
                                const nextEntry = array[index + 1];
                                return (
                                  <Row
                                    gutter={24}
                                    style={{ marginTop: '20px' }}
                                    key={index}
                                  >
                                    <Col span={11}>
                                      <Text strong>
                                        {transformationText(item.key)}
                                      </Text>
                                      <Text>
                                        {Array.isArray(item.value)
                                          ? item.value.join(', ')
                                          : item.value}
                                      </Text>
                                    </Col>
                                    {nextEntry && (
                                      <Col span={11}>
                                        <Text strong>
                                          {transformationText(nextEntry.key)}
                                        </Text>
                                        <Text>
                                          {Array.isArray(nextEntry.value)
                                            ? nextEntry.value.join(', ')
                                            : nextEntry.value}
                                        </Text>
                                      </Col>
                                    )}
                                  </Row>
                                );
                              }
                              return null;
                            })}
                        </>
                        <Row style={{ marginTop: '20px' }}>
                          <Col flex="auto">
                            <Text strong>
                              {t('care_guide_owners_cognito_field_comments') +
                                ': '}
                            </Text>
                            <Text>
                              {x?.answers
                                .filter((item) => item.key === 'Comments')
                                .map((item) => {
                                  return item.value;
                                })}
                            </Text>
                          </Col>
                        </Row>
                        {x?.answers
                          .filter((item) => item.key === 'Information')
                          .map((item) => {
                            return (
                              <Row style={{ marginTop: '20px' }}>
                                <Col flex="auto">
                                  <Text>{item.value}</Text>
                                </Col>
                              </Row>
                            );
                          })}
                        <Row style={{ marginTop: '20px' }} justify="end">
                          <Col>
                            <Button
                              style={{ borderRadius: '4px' }}
                              type="primary"
                              icon={<FileOutlined />}
                              onClick={
                                mock
                                  ? null
                                  : () =>
                                      onClickExportHandler(
                                        partnershipId,
                                        careGuideId,
                                        x.id
                                      )
                              }
                            >
                              {t('care_guide_owners_cognito_download_pdf')}
                            </Button>
                          </Col>
                        </Row>
                      </Panel>
                    ) : null}
                  </>
                ))
              : null}
          </Collapse>
        </>
      )}
    </Translation>
  );
};

export default DailyLogsList;
