import { connect } from 'react-redux';
import DashboardRecommendedUtilities from '../components/DashboardRecommendedUtilities';

import { schema } from '../../../../../state/schema';
import {
  loadRecommendedUtilities,
  downloadDocument,
} from '../../../../../state/modules/recommendedUtilities/actions';
import { denormalize } from 'normalizr';

const mapStateToProps = (state, ownProps) => {
  const order = 'created_at.asc';
  const limit = 5;
  const page_recommended_utilities = ownProps.page_recommended_utilities ?? 1;

  const careGuideId = ownProps.careGuideId;
  const partnershipId = ownProps.partnershipId;

  const result = state.recommendedUtilities.getIn([
    'pages',
    partnershipId,
    careGuideId,
    page_recommended_utilities,
    limit,
    order,
  ]);
  return {
    page_recommended_utilities,
    page_resources: 1,
    order,
    limit,
    careGuideId,
    partnershipId,
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['recommendedUtilities', x]),
                schema.recommendedUtility,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    isLoading:
      state.recommendedUtilities.get('isLoading') ||
      state.recommendedUtilities.get('isFileDownloading'),
    total: state.recommendedUtilities.get('total') ?? 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, careGuideId, page, limit, order, cb) => {
      dispatch(
        loadRecommendedUtilities(
          partnershipId,
          careGuideId,
          page,
          limit,
          order,
          cb
        )
      );
    },

    download: (path, fileName, cb) => {
      dispatch(downloadDocument(path, fileName, cb));
    },
  };
};

const DashboardRecommendedUtilitiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardRecommendedUtilities);

export default DashboardRecommendedUtilitiesContainer;
