import React, { useState, useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Card, List, Space, Typography } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import message from '../../../elements/lib/MessageWrapper';
import Format from '../../../../../lib/Format';
import Logger from '../../../../../lib/Logger';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const { Title } = Typography;

const InvitationItem = ({
  item,
  respond,
  load,
  careGuide,
  event,
  careGuideId,
  start,
  end,
  page,
  limit,
  order,
  ...props
}) => {
  const [isAccepting, setIsAccepting] = useState(false);
  const [isDeferring, setIsDeferring] = useState(false);
  const [isDeclining, setIsDeclining] = useState(false);

  const trackerCalendarEvent = (eventMixpanel, params = {}) => {
    if (careGuide?.partnership) {
      MixpanelTracker.mixpanelTrack(eventMixpanel, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        partnershipName: careGuide.partnership.name,
        careGuideId: careGuide.id,
        partnershipId: careGuide.partnership.id,
        eventId: item.id,
        eventName: item.title,
        ...params,
      });
    } else {
      MixpanelTracker.mixpanelTrack(eventMixpanel, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        careGuideId: careGuide.id,
        eventId: item.id,
        eventName: item.title,
        ...params,
      });
    }
  };

  const onClickAcceptHandler = (id) => {
    setIsAccepting(true);
    respond(id, { public_status: 2 }, (success) => {
      setIsAccepting(false);
      if (success) {
        trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.RESPOND, {
          status: 'Accepted',
        });
        message.success(
          getI18n().t('calendar_event_invitations_feedback_accept_success')
        );
        load(careGuideId, start, end, page, limit, order);
      } else {
        message.error(
          getI18n().t('calendar_event_invitations_feedback_accept_error')
        );
      }
    });
  };

  const onClickMaybeHandler = (id) => {
    setIsDeferring(true);
    respond(id, { public_status: 4 }, (success) => {
      setIsDeferring(false);
      if (success) {
        trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.RESPOND, {
          status: 'Maybe',
        });
        message.success(
          getI18n().t('calendar_event_invitations_feedback_maybe_success')
        );
        load(careGuideId, start, end, page, limit, order);
      } else {
        message.error(
          getI18n().t('calendar_event_invitations_feedback_maybe_error')
        );
      }
    });
  };

  const onClickDeclineHandler = (id) => {
    setIsDeclining(true);
    respond(id, { public_status: 3 }, (success) => {
      setIsDeclining(false);
      if (success) {
        trackerCalendarEvent(mixpanelEventsEnum.CALENDAR.RESPOND, {
          status: 'Declined',
        });
        message.success(
          getI18n().t('calendar_event_invitations_feedback_decline_success')
        );
        load(careGuideId, start, end, page, limit, order);
      } else {
        message.error(
          getI18n().t('calendar_event_invitations_feedback_decline_error')
        );
      }
    });
  };

  return (
    <Translation>
      {(t) => (
        <div className={`event-invitations-list-item`}>
          <List.Item key={item.id}>
            <List.Item.Meta
              title={item.title}
              description={
                item.is_all_day
                  ? Format.date(item.start_at, 'ddd, MMM D') // all day event
                  : Format.date(item.start_at, 'YYYY-MM-DD') ===
                    Format.date(item.end_at, 'YYYY-MM-DD')
                  ? Format.date(item.start_at, 'ddd, MMM D h:mm a') // event occurs on a single day
                  : Format.date(item.start_at, 'YYYY-MM') ===
                    Format.date(item.end_at, 'YYYY-MM')
                  ? Format.date(item.start_at, 'MMM D') +
                    '-' +
                    Format.date(item.end_at, 'D') // event occurs over multiple days, same month
                  : Format.date(item.start_at, 'MMM D') +
                    '-' +
                    Format.date(item.end_at, 'MMM D') // event occurs over multiple days, different month
              }
            />

            <Space>
              <Button
                key="accept"
                icon={<CheckCircleOutlined />}
                loading={isAccepting}
                disabled={isAccepting || isDeclining || isDeferring}
                onClick={() => onClickAcceptHandler(item.participantId)}
                size="small"
                type="primary"
                style={{ borderRadius: '2px' }}
              >
                {t('calendar_event_invitations_btn_accecpt')}
              </Button>

              <Button
                icon={<QuestionCircleOutlined />}
                loading={isDeferring}
                disabled={isAccepting || isDeclining || isDeferring}
                onClick={() => onClickMaybeHandler(item.participantId)}
                size="small"
                style={{ borderRadius: '2px' }}
              >
                {t('calendar_event_invitations_btn_maybe')}
              </Button>

              <Button
                danger
                icon={<CloseCircleOutlined />}
                loading={isDeclining}
                disabled={isAccepting || isDeclining || isDeferring}
                onClick={() => onClickDeclineHandler(item.participantId)}
                size="small"
                type="primary"
                style={{ borderRadius: '2px' }}
              >
                {t('calendar_event_invitations_btn_decline')}
              </Button>
            </Space>
          </List.Item>
        </div>
      )}
    </Translation>
  );
};

const DashboardEventInvitationsList = ({
  careGuideId,
  careGuide,
  start,
  end,
  page,
  limit,
  order,
  total,
  load,
  respond,
  ...props
}) => {
  useEffect(() => {
    load(careGuideId, start, end, page, limit, order);
  }, [careGuideId, start, end, page, limit, order, load]);

  return (
    <Translation>
      {(t) => (
        <>
          {total > 0 ? (
            <Card
              bordered={false}
              className="dashboard-events-invitations-list"
            >
              <Title level={4}>
                {t('dashboard_calendar_event_invitations_list_title')}
              </Title>

              <List
                className="event-invitations-list"
                itemLayout="vertical"
                dataSource={props.list}
                renderItem={(item) => (
                  <InvitationItem
                    item={item}
                    careGuideId={careGuideId}
                    careGuide={careGuide}
                    respond={respond}
                    load={load}
                    start={start}
                    end={end}
                    page={page}
                    limit={limit}
                    order={order}
                  />
                )}
              />
            </Card>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default DashboardEventInvitationsList;

Logger.log('silly', `DashboardEventInvitationsList loaded.`);
