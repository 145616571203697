import DashboardAdminResourcesAddModal from '../components/DashboardAdminResourcesAddModal';
import { schema } from '../../../../../state/schema';
import { denormalize } from 'normalizr';

import {
  loadResourcesDashboardAdmin,
  loadResourcesPinnedAdmin,
  patchResourcesAdmin,
} from '../../../../../state/modules/resourcesAdmin/actions';

import { connect } from 'react-redux';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  const order = 'created_at.asc';
  const limit = 9;
  const page = ownProps.page_resources ?? 1;
  const partnershipId = storage.get('local', 'partnershipId');

  const result = state.resourcesAdmin.getIn([
    'pages',
    partnershipId,
    order,
    limit,
    page,
  ]);

  return {
    page,
    order,
    limit,
    partnershipId,
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['resourcesAdmin', x]),
                schema.resourceAdmin,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    isLoading: state.resourcesAdmin.get('isLoadingPinned'),
    total: state.resourcesAdmin.get('totalPinned') ?? 0,
    totalDashboard: state.resourcesAdmin.get('totalDashbord') ?? 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, page, limit, order, filter, cb) => {
      dispatch(
        loadResourcesPinnedAdmin(partnershipId, page, limit, order, filter, cb)
      );
    },
    loadDashboard: (partnershipId, page, limit, order, cb) => {
      dispatch(
        loadResourcesDashboardAdmin(partnershipId, page, limit, order, cb)
      );
    },
    patch: (partnershipActiveId, data, cb) => {
      dispatch(patchResourcesAdmin(partnershipActiveId, data, cb));
    },
  };
};

const DashboardAdminResourcesAddModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAdminResourcesAddModal);

export default DashboardAdminResourcesAddModalContainer;
