import React from 'react';
import { Typography } from 'antd';
import { pinFilled } from '../../../elements/components/Icons';
import ReactQuill from 'react-quill';
import { TelephoneDisplayCard } from '../../../elements/components/TelephoneDisplay';
import { WebsiteDisplayCard } from '../../../elements/components/WebsiteDisplay';
import { EmailDisplayCard } from '../../../elements/components/EmailDisplay';

const { Title } = Typography;

const modules = {
  toolbar: '#toolbar-read-only-resource-card',
};

const DashboardResourcesCard = ({
  item,
  onChange,
  addStyle,
  className,
  showDetail,
  ...props
}) => {
  return (
    <div
      className={className}
      key={item.id}
      onClick={() => showDetail(item.id)}
      style={{
        border: item?.partnership
          ? `2px solid ${item?.partnership?.color_hex}`
          : null,
        ...addStyle,
      }}
    >
      <div
        className={'icon-active'}
        style={{
          display: 'flex',
          justifyContent: 'end',
          width: '100%',
        }}
      >
        {pinFilled(12, 18)}
      </div>

      <Title className="section-resource-card-list-title" level={5}>
        {item.name}
      </Title>
      {item?.description !== '<p><br></p>' && item?.description.length > 0 && (
        <div className="section-resource-card-list-description">
          <div id="toolbar-read-only-resource-card"></div>
          <ReactQuill modules={modules} readOnly value={item.description} />
        </div>
      )}
      {(item?.website || item?.telephone || item?.email) && (
        <div className="info-container">
          {item?.website ? <WebsiteDisplayCard website={item.website} /> : null}
          {item?.telephone ? (
            <TelephoneDisplayCard telephone={item.telephone} />
          ) : null}
          {item?.email ? <EmailDisplayCard email={item.email} /> : null}
        </div>
      )}
    </div>
  );
};

export default DashboardResourcesCard;
