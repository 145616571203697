import React from 'react';
import { getI18n } from 'react-i18next';
import { withRouter } from 'react-router';
import { Button, Cascader } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import {
  PlusOutlined,
  UserOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import {
  RiDashboard3Line,
  RiPenNibLine,
  RiHandHeartLine,
  RiCalendarLine,
  RiTeamLine,
  RiCommunityLine,
} from 'react-icons/ri';

import hasPermission, {
  VIEW_ACCESS_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';

const MembersListActionColumn = ({
  row,
  accessCareGuide,
  openEventForm,
  redirectToScreen,
  showForm,
  profileId,
}) => {
  const access = hasPermission(VIEW_ACCESS_CARE_GUIDE, row.id, row);

  const options = [
    {
      label: (
        <>
          <UserOutlined />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_member_profile')}
        </>
      ),
      value: 'CareGuideMembersActions',
      isRedirect: true,
      access: access ? accessCareGuide?.member_profile : true,
    },
    {
      label: (
        <>
          <PlusOutlined />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_add_resource')}
        </>
      ),
      value: 1,
      isRedirect: false,
      access: access ? accessCareGuide?.add_resource : true,
    },
    {
      label: (
        <>
          <CalendarOutlined />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_schedule_event')}
        </>
      ),
      value: 2,
      isRedirect: false,
      access: access ? accessCareGuide?.schedule_event : true,
    },
    {
      label: (
        <>
          <RiDashboard3Line className="anticon" />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_dashboard')}
        </>
      ),
      value: 'DashboardDetailScreen',
      isRedirect: true,
      access: access ? accessCareGuide?.dashboard : true,
    },
    {
      label: (
        <>
          <RiPenNibLine className="anticon" />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_journal_post')}
        </>
      ),
      value: 'JournalPostsScreen',
      isRedirect: true,
      access: access ? accessCareGuide?.journal_post : true,
    },
    {
      label: (
        <>
          <RiHandHeartLine className="anticon" />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_care_guide')}
        </>
      ),
      value: 'CareGuideDetailScreen',
      isRedirect: true,
      access: access ? accessCareGuide?.care_guide : true,
    },
    {
      label: (
        <>
          <RiCalendarLine className="anticon" />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_calendar')}
        </>
      ),
      value: 'CalendarScreen',
      isRedirect: true,
      access: access ? accessCareGuide?.calendar : true,
    },
    {
      label: (
        <>
          <RiTeamLine className="anticon" />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_care_team')}
        </>
      ),
      value: 'CareTeamScreen',
      isRedirect: true,
      access: access ? accessCareGuide?.care_team : true,
    },
    {
      label: (
        <>
          <RiCommunityLine className="anticon" />{' '}
          {getI18n().t('partnership_accounts_access_care_guide_resources')}
        </>
      ),
      value: 'ResourcesScreen',
      isRedirect: true,
      access: access ? accessCareGuide?.resources : true,
    },
  ];

  const onChange = (option, selectedOptions) => {
    const value = option[0];

    if (selectedOptions[0].isRedirect) {
      redirectToScreen(row.id, value);
      return;
    }

    if (value === 1) {
      showForm(row.id);
    } else if (value === 2) {
      const ownerId = row.owner.profile.id;
      openEventForm(
        [row.id],
        ownerId !== profileId && profileId ? [ownerId, profileId] : [ownerId]
      );
    }

    return;
  };

  return (
    <span>
      <Cascader
        options={options.filter((menu) => menu.access)}
        onChange={onChange}
        dropdownMenuColumnStyle={{
          height: '32px',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        defaultValue={[]}
        value={[]}
      >
        <Button type="text">
          <EllipsisOutlined />
        </Button>
      </Cascader>
    </span>
  );
};

export default withRouter(MembersListActionColumn);
