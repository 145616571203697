import React, { useState, useEffect } from 'react';
import { Button, Typography } from 'antd';
import { Translation, getI18n } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import DashboardAdminResourcesAddModal from '../containers/DashboardAdminResourcesAddModalContainer';
import notification from '../../../elements/lib/NotificationWrapper';
import LoadingIcon from '../../../elements/components/LoadingIcon';
import DashboardAdminResourcesCard from './DashboardAdminResourcesCard';

const { Text } = Typography;

const DashboardAdminResources = ({
  load,
  partnershipId,
  page,
  page_resources,
  page_recommended_utilities,
  component,
  limit,
  order,
  filter,
  list,
  total,
  isLoading,
  pinnedIds,
  patch,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    load(partnershipId, page, limit, order);
  }, [partnershipId, page, limit, order, load]);

  const togglePinned = (id, value) => {
    patch(partnershipId, id, { is_pinned_dashboard: value }, (success) => {
      if (success) {
        load(partnershipId, page, limit, order);
        notification.success(
          getI18n().t('section_resources_update_unpinned_success')
        );
      } else {
        notification.error(
          getI18n().t('section_resources_update_unpinned_failure')
        );
      }
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <LoadingIcon />
      </div>
    );
  }

  const getContentCard = (list) => {
    let content = [];
    list.map((item, index) =>
      content.push(
        <DashboardAdminResourcesCard
          className="section-resource-card-admin-dashboard cursor-pointer"
          item={item}
          defaultChecked={true}
          onChange={(e) => togglePinned(item.id, e.target.checked)}
          addStyle={{ padding: '16px' }}
        />
      )
    );

    return content;
  };

  const getContentAdd = (count) => {
    let content = [];
    for (let i = 0; i < count; i++) {
      content.push(
        <div className="section-resource-card-admin" key={i}>
          <div
            className="section-resource-card-admin-container cursor-pointer"
            onClick={() => setVisible(true)}
          >
            <Button
              style={{ marginBottom: '10px' }}
              type="primary"
              icon={<PlusOutlined />}
              size="large"
            ></Button>
            <Text className="section-resource-card-admin-plus-text ">
              {getI18n().t('section_resources_add_resource_title')}
            </Text>
          </div>
        </div>
      );
    }
    return content;
  };

  return (
    <Translation>
      {(t) => (
        <>
          <div className="section-resource-card-admin-list">
            {getContentCard(list)}
            {getContentAdd(limit - total)}
          </div>
          <DashboardAdminResourcesAddModal
            pageDashboard={page}
            limitDashboard={limit}
            orderDashboard={order}
            page_resources={page_resources}
            page_recommended_utilities={page_recommended_utilities}
            component={component}
            filter={filter}
            visible={visible}
            setVisible={setVisible}
            pinnedIds={pinnedIds}
          />
        </>
      )}
    </Translation>
  );
};

export default DashboardAdminResources;
