import React, { useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Modal, Space, Tag, Typography, Divider, Button } from 'antd';
import { Col, Row } from 'antd';
import { TelephoneDisplayCard } from '../../../elements/components/TelephoneDisplay';
import { WebsiteDisplayCard } from '../../../elements/components/WebsiteDisplay';
import { EmailDisplayCard } from '../../../elements/components/EmailDisplay';
import imageURL from '../../../elements/lib/imageURL';
import ReactQuill from 'react-quill';
import Logger from '../../../../../lib/Logger';
import hasPermission, {
  VIEW_PARTNER,
} from '../../../elements/lib/hasPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pinFilled } from '../../../elements/components/Icons';
import { PlusOutlined, EyeOutlined } from '@ant-design/icons';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import notification from '../../../elements/lib/NotificationWrapper';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';
const { Title } = Typography;

const modules = {
  toolbar: '#toolbar-read-only-resource',
};
const types = {
  link: 'link',
  phone: 'phone',
  email: 'email',
};

const ResourceCardHeader = ({ resource, handleFavorite, careGuideId }) => {
  return (
    <Translation>
      {(t) => (
        <Row align="top" justify="space-between">
          <Col>
            <Space
              onClick={(e) => {
                handleFavorite(careGuideId, resource, resource.is_favorite);
              }}
              className={`cursor-pointer ${
                resource.is_favorite ? 'icon-active' : 'icon-inactive'
              }`}
            >
              {resource.is_favorite ? (
                <>
                  <FontAwesomeIcon icon={fasStar} />
                  {t('resource_card_header_favorite')}
                </>
              ) : (
                <>
                  {!hasPermission(VIEW_PARTNER) && (
                    <>
                      <FontAwesomeIcon icon={farStar} />
                      {t('resource_card_header_favorite')}
                    </>
                  )}
                </>
              )}
            </Space>
          </Col>
          <Col>
            <Space className={`${resource.is_pinned && 'icon-active'}`}>
              {resource.is_pinned && pinFilled(12, 15)}
            </Space>
          </Col>
        </Row>
      )}
    </Translation>
  );
};

const ResourceCardFotter = ({ resource, showList }) => {
  return (
    <Translation>
      {(t) => (
        <Row align="top" justify="space-between">
          <Col>
            {(!hasPermission(VIEW_PARTNER) ||
              (hasPermission(VIEW_PARTNER) && resource?.partnership?.id)) && (
              <Button
                size="small"
                icon={
                  <span className="anticon">
                    {hasPermission(VIEW_PARTNER) ? (
                      <EyeOutlined />
                    ) : (
                      <PlusOutlined />
                    )}
                  </span>
                }
                onClick={() => showList(resource.id)}
                style={{ marginTop: '10px' }}
              >
                {t('resource_notes_button_notes')}
              </Button>
            )}
          </Col>
        </Row>
      )}
    </Translation>
  );
};

const ResourcePreview = ({
  resourceId,
  load,
  resource,
  careGuide,
  careGuideId,
  showList,
  patchFavorite,
  createInteraction,
  hiddenNotes = false,
  ...props
}) => {
  // load record data from API
  useEffect(() => {
    if (resourceId) {
      load(careGuideId, resourceId);
    }
  }, [careGuideId, resourceId, load]);

  const onClickCancel = () => {
    props.hideDetail();
  };

  const trackerResource = (item, event, params = {}) => {
    if (careGuide.partnership) {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        partnershipName: careGuide.partnership.name,
        careGuideId: careGuide.id,
        partnershipId: careGuide.partnership.id,
        resourceId: item.id,
        resourceName: item.name,
        ...params,
      });
    } else {
      MixpanelTracker.mixpanelTrack(event, {
        careGuideName: `${careGuide.first_name} ${careGuide.last_name}`,
        careGuideId: careGuide.id,
        resourceId: item.id,
        resourceName: item.name,
        ...params,
      });
    }
  };

  const handleFavorite = (careGuideId, item, is_favorite) => {
    patchFavorite(
      careGuideId,
      item.id,
      { is_favorite: !is_favorite },
      (success) => {
        if (success) {
          if (!is_favorite) {
            trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_FAVORITE, {
              state: 'Favorite',
            });
            notification.success(
              getI18n().t('resource_card_button_favorite_success')
            );
          } else {
            trackerResource(item, mixpanelEventsEnum.RESOURCE.TOGGLE_FAVORITE, {
              state: 'Unfavorite',
            });
            notification.success(
              getI18n().t('resource_card_button_unfavorite_success')
            );
          }
        } else {
          notification.error(
            getI18n().t('resource_card_button_favorite_failure')
          );
        }
      }
    );
  };

  const onClickLinks = (event, careGuideId, item, type) => {
    event.stopPropagation();
    trackerResource(item, mixpanelEventsEnum.RESOURCE.OPEN_LINK, {
      linkType: types[type],
    });
    createInteraction(careGuideId, item.id, { [types[type]]: true });
  };

  if (resource === null) {
    return null;
  }

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              <Title level={3} className="resource-filters-title">
                {t('resources_preview')}
              </Title>
            }
            centered
            className="resource-preview"
            visible={true}
            onCancel={onClickCancel}
            footer={null}
            width={900}
            bodyStyle={{ paddingTop: 0 }}
            forceRender={true}
            zIndex={1}
          >
            <div
              className="resource-card"
              style={{
                marginTop: '24px',
                border: resource?.partnership
                  ? `2px solid ${resource?.partnership.color_hex}`
                  : resource?.is_universal
                  ? '2px solid #1A2E5F'
                  : null,
              }}
            >
              <ResourceCardHeader
                resource={resource}
                handleFavorite={handleFavorite}
                careGuideId={careGuideId}
              />
              <Row align="top" justify="space-between">
                <Col
                  xs={{ span: resource?.logo ? 16 : 24, order: 1 }}
                  md={{ span: resource?.logo ? 16 : 24, order: 1 }}
                  lg={{ span: resource?.logo ? 16 : 24, order: 1 }}
                  xl={{ span: resource?.logo ? 16 : 24, order: 1 }}
                >
                  <Title className={'resource-card-title'} level={3}>
                    {resource.name}
                  </Title>
                </Col>
                <Col
                  xs={{ span: 8, order: 2 }}
                  md={{ span: 8, order: 2 }}
                  lg={{ span: 8, order: 2 }}
                  xl={{ span: 8, order: 2 }}
                >
                  <div className={'resources-card-image'}>
                    {resource?.logo &&
                      resource?.logo.file_directory &&
                      resource?.logo.file_name &&
                      resource?.logo.file_extension && (
                        <figure>
                          <img
                            style={{ maxWidth: '100%' }}
                            src={imageURL(
                              resource.logo.file_directory,
                              resource.logo.file_name,
                              resource.logo.file_extension,
                              145,
                              35,
                              'ratio'
                            )}
                            alt=""
                          />
                        </figure>
                      )}
                  </div>
                </Col>
              </Row>

              <Row align="middle">
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                >
                  {!resource.is_universal ? (
                    resource?.partnership ? (
                      <Tag
                        color={resource.partnership?.color_hex}
                        className="cursor-pointer"
                      >
                        {resource.partnership?.name}
                      </Tag>
                    ) : (
                      <Tag color="#FE971F" className="cursor-pointer">
                        {t('resource_notes_tag_custom_resource')}
                      </Tag>
                    )
                  ) : (
                    <Tag color={'#1A2E5F'} className="cursor-pointer">
                      {t('resource_notes_tag_general_resource')}
                    </Tag>
                  )}
                </Col>
              </Row>

              <Divider style={{ margin: '12px 0' }} />

              {resource?.service_tags
                ? resource?.service_tags?.length > 0 && (
                    <div className="resource-list-tag-card">
                      {resource.service_tags.map((x, i) => (
                        <Tag color={x.color_hex} key={i}>
                          {x.label}
                        </Tag>
                      ))}{' '}
                    </div>
                  )
                : null}

              {resource?.description !== '<p><br></p>' &&
                resource?.description.length > 0 && (
                  <div className="resource-list-description-preview">
                    <div id="toolbar-read-only-resource"></div>
                    <ReactQuill
                      modules={modules}
                      readOnly
                      value={resource.description}
                    />
                  </div>
                )}
              <div className="info-container">
                {resource?.website ? (
                  <WebsiteDisplayCard
                    website={resource.website}
                    onClick={(e) =>
                      onClickLinks(e, careGuideId, resource, 'link')
                    }
                  />
                ) : null}
                {resource?.telephone ? (
                  <TelephoneDisplayCard
                    telephone={resource.telephone}
                    onClick={(e) =>
                      onClickLinks(e, careGuideId, resource, 'phone')
                    }
                  />
                ) : null}
                {resource?.email ? (
                  <EmailDisplayCard
                    email={resource.email}
                    onClick={(e) =>
                      onClickLinks(e, careGuideId, resource, 'email')
                    }
                  />
                ) : null}
              </div>

              {!hiddenNotes && (
                <ResourceCardFotter
                  resource={resource}
                  showList={showList}
                  hiddenNotes
                />
              )}
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default ResourcePreview;

Logger.log('silly', `ResourcePreview loaded.`);
