import React from 'react';
import { Translation } from 'react-i18next';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Logger from '../../../../../lib/Logger';

const InviteButtonCareGuideHelpers = ({
  canInvite,
  isLoading,
  showForm,
  text = null,
  style = null,
  mock = false,
  ...props
}) => {
  return (
    <Translation>
      {(t) => (
        <>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={!canInvite}
            onClick={(e) => (mock ? null : showForm())}
          >
            {t('dashboard_admin_care_members_btn_invite')}
          </Button>
        </>
      )}
    </Translation>
  );
};

export default InviteButtonCareGuideHelpers;

Logger.log('silly', `InviteButtonCareGuideHelpers loaded.`);
