import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { Translation } from 'react-i18next';
import { Card, List, Tag } from 'antd';

import UserAvatar from '../../../elements/components/UserAvatar';
import { TelephoneDisplay } from '../../../elements/components/TelephoneDisplay';
import { MessageLink } from '../../messages/components/MessageLink';
import Logger from '../../../../../lib/Logger';

const HelperCard = ({ item, ...props }) => {
  return (
    <Translation>
      {(t) => (
        <Card>
          <Card.Meta
            avatar={<UserAvatar profile={item.profile} showTooltp={false} />}
            title={
              item.profile
                ? item.profile.first_name + ' ' + item.profile.last_name
                : null
            }
            description={
              <>
                {t('care_guide_helpers_role')}:{' '}
                {item.is_owner
                  ? t('care_guide_helpers_role_owner')
                  : t('care_guide_helpers_role_' + item.role)}
                {item.public_status === 2 ? (
                  <Tag>{t('care_guide_helpers_public_status_2')}</Tag>
                ) : null}
                {item.public_status === 3 ? (
                  <Tag>{t('care_guide_helpers_public_status_3')}</Tag>
                ) : null}
              </>
            }
          />
          <ul className="helpers-list-contact-list">
            {item.profile && item.profile.telephone ? (
              <li>
                <TelephoneDisplay telephone={item.profile.telephone} />
              </li>
            ) : null}
            {item.profile && !item.is_self ? (
              <li>
                <MessageLink
                  profile={item.profile}
                  careGuideId={props.careGuideId}
                />
              </li>
            ) : null}
          </ul>

          <div className="helpers-list-tag-list">
            {item.tags ? item.tags.map((x, i) => <Tag key={i}>{x}</Tag>) : null}
          </div>
        </Card>
      )}
    </Translation>
  );
};

const CareGuideMembersList = ({
  careGuideId,
  component,
  parentPage,
  page,
  limit,
  order,
  total,
  load,
  showForm,
  showInvitationForm,
  history,
  ...props
}) => {
  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    position: ['bottomCenter'],
  };

  useEffect(() => {
    load(careGuideId, page, limit, order);
  }, [careGuideId, page, limit, order, load]);

  return (
    <Translation>
      {(t) => (
        <List
          className="helpers-list"
          dataSource={props.list}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          pagination={page === 1 && total <= limit ? false : pagination}
          renderItem={(item) => (
            <List.Item>
              <HelperCard item={item} careGuideId={careGuideId} />
            </List.Item>
          )}
        />
      )}
    </Translation>
  );
};

export default withRouter(CareGuideMembersList);

Logger.log('silly', `CareGuideMembersList loaded.`);
