import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';
import Format from '../../../lib/Format';

export const TYPES = {
  LIST_REQUEST: 'CARE_GUIDE_DOCUMENTS/LIST_REQUEST',
  LIST_SUCCESS: 'CARE_GUIDE_DOCUMENTS/LIST_SUCCESS',
  LIST_FAILURE: 'CARE_GUIDE_DOCUMENTS/LIST_FAILURE',
  CREATE_REQUEST: 'CARE_GUIDE_DOCUMENTS/CREATE_REQUEST',
  CREATE_SUCCESS: 'CARE_GUIDE_DOCUMENTS/CREATE_SUCCESS',
  CREATE_FAILURE: 'CARE_GUIDE_DOCUMENTS/CREATE_FAILURE',
  UPDATE_REQUEST: 'CARE_GUIDE_DOCUMENTS/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'CARE_GUIDE_DOCUMENTS/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'CARE_GUIDE_DOCUMENTS/UPDATE_FAILURE',
  PATCH_REQUEST: 'CARE_GUIDE_DOCUMENTS/PATCH_REQUEST',
  PATCH_SUCCESS: 'CARE_GUIDE_DOCUMENTS/PATCH_SUCCESS',
  PATCH_FAILURE: 'CARE_GUIDE_DOCUMENTS/PATCH_FAILURE',
  FORM_DESTROY: 'CARE_GUIDE_DOCUMENTS/FORM_DESTROY',
  SHOW_FORM: 'CARE_GUIDE_DOCUMENTS/SHOW_FORM',
  HIDE_FORM: 'CARE_GUIDE_DOCUMENTS/HIDE_FORM',
  PREVIEW_DESTROY: 'CARE_GUIDE_DOCUMENTS/PREVIEW_DESTROY',
  SHOW_PREVIEW: 'CARE_GUIDE_DOCUMENTS/SHOW_PREVIEW',
  HIDE_PREVIEW: 'CARE_GUIDE_DOCUMENTS/HIDE_PREVIEW',
  DOCUMENT_UPLOAD_REQUEST: 'MEDIA/DOCUMENT_UPLOAD_REQUEST',
  DOCUMENT_UPLOAD_SUCCESS: 'MEDIA/DOCUMENT_UPLOAD_SUCCESS',
  DOCUMENT_UPLOAD_FAILURE: 'MEDIA/DOCUMENT_UPLOAD_FAILURE',
  DOCUMENT_DOWNLOAD_REQUEST: 'MEDIA/DOCUMENT_DOWNLOAD_REQUEST',
  DOCUMENT_DOWNLOAD_SUCCESS: 'MEDIA/DOCUMENT_DOWNLOAD_SUCCESS',
  DOCUMENT_DOWNLOAD_FAILURE: 'MEDIA/DOCUMENT_DOWNLOAD_FAILURE',
  DOCUMENT_DESTROY_REQUEST: 'MEDIA/DOCUMENT_DESTROY_REQUEST',
  DOCUMENT_DESTROY_SUCCESS: 'MEDIA/DOCUMENT_DESTROY_SUCCESS',
  DOCUMENT_DESTROY_FAILURE: 'MEDIA/DOCUMENT_DESTROY_FAILURE',
  CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_REQUEST:
    'CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_REQUEST',
  CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_SUCCESS:
    'CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_SUCCESS',
  CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_FAILURE:
    'CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_FAILURE',
};

export function careGuideDocumentListRequest(
  careGuideId,
  page,
  limit,
  order,
  filter
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentListRequest(${careGuideId}, ${page}, ${limit}, ${order}, %j)`,
    filter
  );
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    page: page,
    limit: limit,
    order: order,
    filter: filter,
  };
}

export function careGuideDocumentListSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentListSuccess(%j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentListFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentListFailure(%j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideDocumentUpdateRequest(careGuideId, id, data) {
  return {
    type: TYPES.UPDATE_REQUEST,
    careGuideId: careGuideId,
    id: id,
    data: data,
  };
}

export function careGuideDocumentUpdateSuccess(careGuideId, id, data) {
  return {
    type: TYPES.UPDATE_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentUpdateFailure(careGuideId, error) {
  return {
    type: TYPES.UPDATE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideDocumentPatchRequest(careGuideId, id, data) {
  return {
    type: TYPES.PATCH_REQUEST,
    careGuideId: careGuideId,
    id: id,
    data: data,
  };
}

export function careGuideDocumentPatchSuccess(careGuideId, id, data) {
  return {
    type: TYPES.PATCH_SUCCESS,
    careGuideId: careGuideId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentPatchFailure(careGuideId, error) {
  return {
    type: TYPES.PATCH_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideDocumentFormDestroy(formState = null) {
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function careGuideDocumentShowForm(id = null) {
  return {
    type: TYPES.SHOW_FORM,
    id: id,
  };
}

export function careGuideDocumentHideForm() {
  return {
    type: TYPES.HIDE_FORM,
  };
}

export function careGuideDocumentPreviewDestroy(previewState = null) {
  return {
    type: TYPES.PREVIEW_DESTROY,
    preview: previewState,
  };
}

export function careGuideDocumentShowPreview(id = null) {
  return {
    type: TYPES.SHOW_PREVIEW,
    id: id,
  };
}

export function careGuideDocumentHidePreview() {
  return {
    type: TYPES.HIDE_PREVIEW,
  };
}

export function careGuideDocumentCreateRequest(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentCreateRequest(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_REQUEST,
    careGuideId: careGuideId,
    data: data,
  };
}

export function careGuideDocumentCreateSuccess(careGuideId, data) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentCreateSuccess(${careGuideId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SUCCESS,
    careGuideId: careGuideId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function careGuideDocumentCreateFailure(careGuideId, error) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentCreateFailure(${careGuideId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_FAILURE,
    careGuideId: careGuideId,
    error: error,
  };
}

export function careGuideDocumentUploadRequest() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentUploadRequest()`
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_REQUEST,
  };
}

export function careGuideDocumentUploadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentUploadSuccess()`
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_SUCCESS,
  };
}

export function careGuideDocumentUploadFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentUploadFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_FAILURE,
    error: error,
  };
}

export function careGuideDocumentDownloadRequest() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentDownloadRequest()`
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_REQUEST,
  };
}

export function careGuideDocumentDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentDownloadSuccess()`
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_SUCCESS,
  };
}

export function careGuideDocumentDownloadFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentDownloadFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_FAILURE,
    error: error,
  };
}

export function careGuideDocumentDestroyRequest() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentDestroyRequest()`
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_REQUEST,
  };
}

export function careGuideDocumentDestroySuccess() {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentDestroySuccess()`
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_SUCCESS,
  };
}

export function careGuideDocumentDestroyFailure(error) {
  Logger.log(
    'debug',
    `[state.careGuideDocument.actions] careGuideDocumentDestroyFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_FAILURE,
    error: error,
  };
}

export function careGuideDocumentsCSVDownloadRequest(order, filter) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentsCSVDownloadRequest()`
  );
  return {
    type: TYPES.CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_REQUEST,
    order: order,
    filter: filter,
  };
}

export function careGuideDocumentsCSVDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentsCSVDownloadSuccess()`
  );
  return {
    type: TYPES.CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_SUCCESS,
  };
}

export function careGuideDocumentsCSVDownloadFailure() {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] careGuideDocumentsCSVDownloadFailure()`
  );
  return {
    type: TYPES.CARE_GUIDE_DOCUMENTS_CSV_DOWNLOAD_FAILURE,
  };
}

// API THUNK ACTION CREATORS

export function createCareGuideDocument(
  careGuideId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] createCareGuideDocument(${careGuideId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentCreateRequest(careGuideId, data));

    // call API
    const response = await api.postCareGuideDocument(careGuideId, data);
    let success = false;

    // post care guide document success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API care guide document success. Care Guide ID: ${careGuideId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentCreateSuccess(careGuideId, data));
      success = true;

      // post care guide document failure
    } else {
      Logger.log(
        'info',
        `POST API care guide document failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        careGuideDocumentCreateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateCareGuideDocument(
  careGuideId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] updateCareGuideDocument(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentUpdateRequest(careGuideId, id, data));

    // call API
    const response = await api.putCareGuideDocument(careGuideId, id, data);
    let success = false;

    // put care guide document success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `UPDATE API care guide document success. Care Guide ID: ${careGuideId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentUpdateSuccess(careGuideId, data));
      success = true;

      // put care guide document failure
    } else {
      Logger.log(
        'info',
        `UPDATE API care guide document failure. Care Guide ID: ${careGuideId}, ID: ${id}.`
      );
      dispatch(
        careGuideDocumentUpdateFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function patchCareGuideDocument(
  careGuideId = null,
  id = null,
  data = {},
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] patchCareGuideDocument(${careGuideId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentPatchRequest(careGuideId, id, data));

    // call API
    const response = await api.patchCareGuideDocument(careGuideId, id, data);
    let success = false;

    // patch care guide document success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Patch API care guide document success. Care Guide ID: ${careGuideId} ID: ${id}.`
      );
      const normalizedEntities = normalize(
        [response.getIn(['data', 'care_guide_document'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'care_guide_document', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentPatchSuccess(careGuideId, id, data));
      success = true;

      // patch care guide document failure
    } else {
      Logger.log(
        'info',
        `Patch API care guide document failure. Care Guide ID: ${careGuideId}.`
      );
      dispatch(
        careGuideDocumentPatchFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function destroyDocument(path, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] destroyDocument(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentDestroyRequest());

    // call API
    const response = await api.deleteDocumentAWS(path);
    let success = false;

    // put document success
    if (response['success']) {
      Logger.log('info', `Delete S3 document success.`);
      success = true;
      dispatch(careGuideDocumentDestroySuccess());

      // put document failure
    } else {
      Logger.log('info', `Delete S3 document failure.`);
      dispatch(careGuideDocumentDestroyFailure('Delete S3 error.'));
    }

    // callback function
    cb(success);
  };
}

export function loadCareGuideDocuments(
  careGuideId = null,
  page = 1,
  limit = 15,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] loadCareGuideDocuments(${careGuideId}, ${page}, ${limit}, ${order}, %j, ###)`,
    filter
  );
  return async function (dispatch) {
    dispatch(
      careGuideDocumentListRequest(careGuideId, page, limit, order, filter)
    );

    // call API
    const response = await api.getCareGuideDocuments(
      careGuideId,
      page,
      limit,
      order,
      filter
    );
    let success = false;

    // get care guide documents list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents list success. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'care_guide_documents']),
        [schema.careGuideDocument]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };
      dispatch(addEntities(normalizedEntities));
      dispatch(careGuideDocumentListSuccess(careGuideId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents list success [empty]. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };

      dispatch(careGuideDocumentListSuccess(careGuideId, data));
      success = true;

      // get care guide documents list failure
    } else {
      Logger.log(
        'info',
        `Get API care guide documents list failure. Care Guide ID: ${careGuideId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        careGuideDocumentListFailure(
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function uploadDocument(path, file, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] uploadDocument(${path}, ###, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentUploadRequest());

    // call API
    const response = await api.putDocumentUpload(path, file);
    let success = false;

    // put document success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT S3 document success.`);
      success = true;
      dispatch(careGuideDocumentUploadSuccess());

      // put document failure
    } else {
      Logger.log('info', `PUT S3 document failure.`);
      dispatch(careGuideDocumentUploadFailure('Upload error.'));
    }

    // callback function
    cb(success);
  };
}

export function downloadDocument(path, fileName, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] downloadDocument(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentDownloadRequest());

    // call API
    const response = await api.createGetPresignedUrlAWS(path, fileName);
    let success = false;

    let url = null;

    // create presigned url success
    if (response['success']) {
      Logger.log('info', `Create presigned URL success.`);
      success = true;

      url = response['presignedUrl'];

      dispatch(careGuideDocumentDownloadSuccess());

      // create presigned url failure
    } else {
      Logger.log('info', `Create presigned URL failure.`);
      dispatch(careGuideDocumentDownloadFailure('Download error.'));
    }

    // callback function
    cb(success, url);
  };
}

export function downloadCareGuideDocumentsCSV(
  partnershipName = null,
  careGuideId = null,
  payload,
  order = null,
  filter = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.careGuideDocuments.actions] downloadCareGuideDocumentsCSV(${order}, ${filter}, ###)`
  );

  return async function (dispatch) {
    dispatch(careGuideDocumentsCSVDownloadRequest(order, filter));

    // call API
    const response = await api.postCareGuideDocumentsCSV(
      careGuideId,
      payload,
      order,
      filter
    );
    let success = false;

    // get care guide documents CSV success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API care guide documents CSV success. Order: ${order}`
      );
      const now = new Date();

      // trigger browser download
      const url = window.URL.createObjectURL(new Blob([response.get('data')]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `cv-${partnershipName}-care-guide-documents-${Format.date(
          now,
          'YYYY-MM-DDTHHmm'
        )}.csv`
      );
      document.body.appendChild(link);
      link.click();

      dispatch(careGuideDocumentsCSVDownloadSuccess());
      success = true;

      // get care guide documents CSV failure
    } else {
      Logger.log('info', `Get API care guide documents CSV failure.`);
      dispatch(careGuideDocumentsCSVDownloadFailure());
    }
    // callback function
    cb(success);
  };
}

Logger.log('silly', `state.careGuideDocuments.actions loaded.`);
