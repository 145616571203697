import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Translation } from 'react-i18next';
import { Button, Row, Col, Card } from 'antd';
import JournalPostForm from '../../journalPosts/containers/JournalPostFormContainer';
import JournalPostModalForm from '../../journalPosts/containers/JournalPostModalFormContainer';
import JournalReplyModalForm from '../../journalReplies/containers/JournalReplyModalFormContainer';
import JournalPostsList from '../../journalPosts/containers/JournalPostsListContainer';
import InvitationsList from '../../careGuideInvitations/containers/InvitationsListContainer';
import EventInvitationsList from '../../calendar/containers/EventInvitationsListContainer';
import EventList from '../../calendar/containers/EventListContainer';
import CareGuideInvitationsQuickList from '../../careGuideHelpers/containers/CareGuideInvitationsQuickListContainer';
import LoadingIcon from '../../../elements/components/LoadingIcon';
import CareGuideHelpersQuickList from '../../careGuideHelpers/containers/CareGuideHelpersQuickListContainer';
import { pathTo } from '../../../Routes';
import InviteButton from '../../careGuideInvitations/containers/InviteButtonContainer';

import Logger from '../../../../../lib/Logger';

import hasPermission, {
  CREATE_JOURNAL_POST,
  CREATE_CARE_GUIDE_INVITATION,
} from '../../../elements/lib/hasPermission';

const DashboardDetail = ({
  careGuideId,
  areJournalsLoading,
  page,
  limit,
  ...props
}) => {
  return (
    <Translation>
      {(t) => (
        <>
          <Row gutter={16}>
            <Col
              lg={{ span: 16, order: 1 }}
              md={{ span: 24, order: 2 }}
              sm={{ span: 24, order: 2 }}
              xs={{ span: 24, order: 2 }}
              className="dashboard-content"
            >
              <h2 className="section-header">{t('journal_posts_title')}</h2>

              {hasPermission(CREATE_JOURNAL_POST) ? (
                <Card>
                  <JournalPostForm
                    careGuideId={careGuideId}
                    page={page}
                    limit={limit}
                    order={'published_at.desc'}
                  />
                </Card>
              ) : null}

              <JournalPostsList
                key={page}
                component="DashboardDetailScreen"
                careGuideId={careGuideId}
                page={page}
                limit={limit}
                order={'published_at.desc'}
              />
            </Col>

            <Col
              lg={{ span: 8, order: 2 }}
              md={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              xs={{ span: 24, order: 1 }}
              className="content-sidebar"
            >
              <InvitationsList page={1} limit={5} order={'invited_at.desc'} />

              <>
                <EventInvitationsList
                  careGuideId={careGuideId}
                  start={moment().format('YYYY-MM-DD')}
                  page={1}
                  limit={5}
                  order={'start_at.asc'}
                />

                <EventList
                  careGuideId={careGuideId}
                  start={moment().format('YYYY-MM-DD')}
                  end={moment().add(1, 'Y').format('YYYY-MM-DD')}
                  page={1}
                  limit={3}
                  order={'start_at.asc'}
                />
              </>

              <CareGuideInvitationsQuickList
                careGuideId={careGuideId}
                page={1}
                limit={5}
                order={'joined_at.asc'}
              />

              <Card
                title={t('dashboard_care_guide_helper_list_title')}
                extra={areJournalsLoading ? <LoadingIcon size={16} /> : null}
                size="small"
                bordered={false}
              >
                <CareGuideHelpersQuickList
                  careGuideId={careGuideId}
                  page={1}
                  limit={5}
                  order={'joined_at.asc'}
                />
                <Button>
                  <Link to={pathTo('CareTeamScreen', { id: careGuideId })}>
                    {t('dashboard_care_guide_helper_btn_view_helpers')}
                  </Link>
                </Button>
                {hasPermission(CREATE_CARE_GUIDE_INVITATION) ? (
                  <InviteButton
                    careGuideId={careGuideId}
                    text={t('dashboard_care_guide_helper_btn_invite')}
                    style={{ float: 'right' }}
                  />
                ) : null}
              </Card>
            </Col>
          </Row>

          <JournalPostModalForm
            careGuideId={careGuideId}
            page={page}
            limit={limit}
            order={'published_at.desc'}
          />

          <JournalReplyModalForm careGuideId={careGuideId} />
        </>
      )}
    </Translation>
  );
};

export default DashboardDetail;

Logger.log('silly', `DashboardDetail loaded.`);
