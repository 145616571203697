import React, { useState, useEffect } from 'react';
import { Modal, Typography, Button, Input, List, Checkbox, Space } from 'antd';
import { Translation, getI18n } from 'react-i18next';
import { withRouter } from 'react-router';

import { pathTo } from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import notification from '../../../elements/lib/NotificationWrapper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableList, faBorderAll } from '@fortawesome/free-solid-svg-icons';
import useDebounce from '../../../elements/components/useDebounce';
import DashboardAdminResourcesCard from './DashboardAdminResourcesCard';

const { Title, Text } = Typography;

const DashboardAdminResourcesAddModal = ({
  loadDashboard,
  visible,
  setVisible,
  page,
  limit,
  order,
  filter,
  partnershipId,
  load,
  list,
  total,
  history,
  isLoading,
  component,
  page_resources,
  page_recommended_utilities,
  pinnedIds,
  patch,
  pageDashboard,
  limitDashboard,
  orderDashboard,
  ...props
}) => {
  let params = QueryString.parse(history.location.search);
  const [pinnedResources, setPinnedResources] = useState(pinnedIds);
  const [view, setView] = useState('grid');
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 1000);

  const onSearch = (value) => {
    setQuery(value);
  };

  const onChangeSearch = (value) => {
    params['search'] = value;
    if (params['search'] === '') {
      delete params['search'];
    }
    const path = pathTo(component, {
      page_recommended_utilities: page_recommended_utilities,
      page_resources: 1,
    });
    history.push(QueryString.append(path, params));
  };

  const paginationList = {
    onChange: (newPage, pageSize) => {
      let path = props.location.pathname;
      const params = {};

      path = pathTo(component, {
        page_recommended_utilities: page_recommended_utilities,
        page_resources: newPage,
      });

      // handle filters
      if (filter) {
        for (const key in filter) {
          if (filter[key]) {
            params[key] = filter[key];
          }
        }
      }

      history.push(QueryString.append(path, params));
    },
    current: page_resources,
    pageSize: limit,
    total: total,
    showSizeChanger: false,
  };

  useEffect(() => {
    onChangeSearch(debouncedQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery]);

  const filterString = JSON.stringify(filter);
  useEffect(() => {
    load(partnershipId, page, limit, order, JSON.parse(filterString));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipId, page, limit, order, filterString]);

  const togglePinned = (element) => {
    setPinnedResources((prevArray) =>
      prevArray.includes(element)
        ? prevArray.filter((item) => item !== element)
        : [...prevArray, element]
    );
  };

  const clearModal = () => {
    setVisible(false);
    setPinnedResources(pinnedIds);
    setQuery('');
    setView('grid');
    let path = props.location.pathname;
    const params = {};

    path = pathTo(component, {
      page_recommended_utilities: page_recommended_utilities,
      page_resources: 1,
    });
    history.push(QueryString.append(path, params));
  };

  const onOk = () => {
    patch(partnershipId, { resources_ids: pinnedResources }, (success) => {
      if (success) {
        loadDashboard(
          partnershipId,
          pageDashboard,
          limitDashboard,
          orderDashboard
        );
        clearModal();
        notification.success(
          getI18n().t('section_resources_update_unpinned_success')
        );
      } else {
        notification.error(
          getI18n().t('section_resources_update_unpinned_failure')
        );
      }
    });
  };

  return (
    <Translation>
      {(t) => (
        <>
          <Modal
            title={
              <Title level={3} className="add-resources-dashboard-title">
                {t('section_resources_add_resource_title')}
              </Title>
            }
            destroyOnClose={true}
            width={700}
            closable={true}
            className="section-resource-card-admin-modal"
            centered
            onCancel={clearModal}
            visible={visible}
            footer={[
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Button
                  block
                  size={'large'}
                  key="cancel"
                  style={{ marginRight: '8px', flex: 1 }}
                  onClick={clearModal}
                >
                  {t('section_resources_btn_cancel')}
                </Button>

                <Button
                  block
                  size={'large'}
                  key="apply"
                  style={{ marginLeft: '8px', flex: 1 }}
                  type="primary"
                  onClick={onOk}
                >
                  {t('section_resources_btn_apply')}
                </Button>
              </div>,
            ]}
            bodyStyle={{ paddingBottom: 0 }}
          >
            <div>
              <Text type="secondary">{t('section_resources_description')}</Text>
              <Input
                placeholder="Search"
                style={{ margin: '10px 0' }}
                onChange={(e) => onSearch(e.target.value)}
              />
              <Space
                align="end"
                style={{
                  justifyContent: 'flex-end',
                  width: '100%',
                  margin: '10px 0',
                }}
              >
                <Button
                  onClick={() => setView('grid')}
                  type="text"
                  className={`${
                    view === 'grid' ? 'btn-view-active' : 'btn-view-desactive'
                  }`}
                >
                  <FontAwesomeIcon icon={faBorderAll} />
                </Button>
                <Button
                  type="text"
                  onClick={() => setView('list')}
                  className={`${
                    view === 'list' ? 'btn-view-active' : 'btn-view-desactive'
                  }`}
                >
                  <FontAwesomeIcon icon={faTableList} />
                </Button>
              </Space>
            </div>
            <div>
              <List
                dataSource={list}
                loading={isLoading}
                className="section-resource-card-admin-list-items"
                pagination={
                  page === 1 && total <= limit ? false : paginationList
                }
                grid={
                  view === 'list'
                    ? false
                    : {
                        gutter: 16,
                        column: 3,
                      }
                }
                renderItem={(item) =>
                  item?.id !== undefined && view === 'list' ? (
                    <List.Item>
                      <div
                        key={item.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Text strong>{item.name}</Text>
                        <Checkbox
                          disabled={
                            pinnedResources.length === 6 &&
                            !pinnedResources.includes(item.id)
                          }
                          defaultChecked={pinnedResources.includes(item.id)}
                          onChange={(e) => togglePinned(item.id)}
                        />
                      </div>
                    </List.Item>
                  ) : (
                    <List.Item>
                      <DashboardAdminResourcesCard
                        className="section-resource-card-admin-dashboard cursor-pointer"
                        disabledCheckbox={
                          pinnedResources.length === 6 &&
                          !pinnedResources.includes(item.id)
                        }
                        item={item}
                        defaultChecked={pinnedResources.includes(item.id)}
                        onChange={(e) => togglePinned(item.id)}
                        addStyle={{ padding: '10px' }}
                      />
                    </List.Item>
                  )
                }
              />
            </div>
          </Modal>
        </>
      )}
    </Translation>
  );
};

export default withRouter(DashboardAdminResourcesAddModal);
