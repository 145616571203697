import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  isGeneratingPDF: false,
  results: {},
  form: {},
  formIsVisible: false,
  formId: null,
  resourceDetailIsVisible: false,
  resourceDetailResourceId: null,
});

export default function resources(state = initialState, action) {
  Logger.log(
    'debug',
    `[state.resources.reducers] resources(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          results: {
            [action.careGuideId]: {
              total: action.total,
              pages: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null,
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'results',
            action.careGuideId,
            'pages',
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.LIST_DASHBOARD_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_DASHBOARD_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          total: action.total,
          pages: {
            [action.partnershipId]: {
              [action.careGuideId]: {
                [action.order]: {
                  [action.limit]: {
                    [action.page]: null,
                  },
                },
              },
            },
          },
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.careGuideId,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_DASHBOARD_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.CREATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.CREATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.UPDATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.UPDATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PATCH_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.PATCH_FAVORITE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.PATCH_FAVORITE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.PATCH_FAVORITE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DELETE_FAILURE:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case TYPES.PDF_REQUEST:
      return state.mergeDeep({
        isGeneratingPDF: true,
      });

    case TYPES.PDF_SUCCESS:
      return state.mergeDeep({
        isGeneratingPDF: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.PDF_FAILURE:
      return state.mergeDeep({
        isGeneratingPDF: false,
      });

    case TYPES.FORM_DESTROY:
      return state
        .mergeDeep({
          form: {},
        })
        .set('form', action.form);

    case TYPES.SHOW_FORM:
      return state.mergeDeep({
        formIsVisible: true,
        formId: action.id,
      });

    case TYPES.HIDE_FORM:
      return state.mergeDeep({
        formIsVisible: false,
        formId: null,
      });

    case TYPES.SHOW_PREVIEW_DETAIL:
      return state.mergeDeep({
        resourceDetailIsVisible: true,
        resourceDetailResourceId: action.resourceId,
      });

    case TYPES.HIDE_PREVIEW_DETAIL:
      return state.mergeDeep({
        resourceDetailIsVisible: false,
        resourceDetailResourceId: null,
      });

    case TYPES.CREATE_INTERACTION_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
        },
      });

    case TYPES.CREATE_INTERACTION_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.CREATE_INTERACTION_FAILURE:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: false,
        },
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.resources.reducers loaded.`);
