import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { loadCareGuide } from '../../../../../state/modules/careGuides/actions';
import DashboardDetailScreen from '../components/DashboardDetailScreen';
import {
  careGuideSetActive,
  careGuideSetAccess,
} from '../../../../../state/modules/careGuides/actions';

const mapStateToProps = (state, ownProps) => {
  const page = parseInt(
    ownProps.match.params.page ? ownProps.match.params.page : 1
  );

  const activeId = state.careGuides.get('activeId') ?? ownProps.match.params.id;

  const careGuide = activeId
    ? denormalize(
        state.entities.getIn(['careGuides', activeId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  return {
    accessCareGuide: state.careGuides.get('accessCareGuide'),
    areJournalsLoading: state.journalPosts.get('isLoading'),
    page: page,
    careGuideId: activeId,
    isCareGuideLoading: careGuide === undefined ? true : false,
    isCareGuideActive:
      careGuide && careGuide.account && careGuide.account.is_subscription_valid,
    partnership: careGuide?.partnership,
    isLoading: state.careGuides.get('isLoading'),
    careGuide: careGuide,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadCareGuide(id, cb));
    },
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setActiveAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  };
};

const DashboardDetailScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDetailScreen);

export default DashboardDetailScreenContainer;
