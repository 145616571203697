import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/LIST_REQUEST',
  LIST_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/LIST_SUCCESS',
  LIST_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/LIST_FAILURE',
  CREATE_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/CREATE_REQUEST',
  CREATE_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/CREATE_SUCCESS',
  CREATE_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/CREATE_FAILURE',
  UPDATE_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/UPDATE_REQUEST',
  UPDATE_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/UPDATE_SUCCESS',
  UPDATE_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/UPDATE_FAILURE',
  FORM_DESTROY: 'RECOMMENDED_UTILITIES_ADMIN/FORM_DESTROY',
  SHOW_FORM: 'RECOMMENDED_UTILITIES_ADMIN/SHOW_FORM',
  HIDE_FORM: 'RECOMMENDED_UTILITIES_ADMIN/HIDE_FORM',
  PATCH_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/PATCH_REQUEST',
  PATCH_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/PATCH_SUCCESS',
  PATCH_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/PATCH_FAILURE',
  DELETE_REQUEST: 'RECOMMENDED_UTILITIES_ADMIN/DELETE_REQUEST',
  DELETE_SUCCESS: 'RECOMMENDED_UTILITIES_ADMIN/DELETE_SUCCESS',
  DELETE_FAILURE: 'RECOMMENDED_UTILITIES_ADMIN/DELETE_FAILURE',
  DOCUMENT_UPLOAD_REQUEST: 'MEDIA/DOCUMENT_UPLOAD_REQUEST',
  DOCUMENT_UPLOAD_SUCCESS: 'MEDIA/DOCUMENT_UPLOAD_SUCCESS',
  DOCUMENT_UPLOAD_FAILURE: 'MEDIA/DOCUMENT_UPLOAD_FAILURE',
  DOCUMENT_DOWNLOAD_REQUEST: 'MEDIA/DOCUMENT_DOWNLOAD_REQUEST',
  DOCUMENT_DOWNLOAD_SUCCESS: 'MEDIA/DOCUMENT_DOWNLOAD_SUCCESS',
  DOCUMENT_DOWNLOAD_FAILURE: 'MEDIA/DOCUMENT_DOWNLOAD_FAILURE',
  DOCUMENT_DESTROY_REQUEST: 'MEDIA/DOCUMENT_DESTROY_REQUEST',
  DOCUMENT_DESTROY_SUCCESS: 'MEDIA/DOCUMENT_DESTROY_SUCCESS',
  DOCUMENT_DESTROY_FAILURE: 'MEDIA/DOCUMENT_DESTROY_FAILURE',
};

export function recommendedUtilityAdminListRequest(
  partnershipId,
  page,
  limit,
  order
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminListRequest(${partnershipId}, ${page}, ${limit}, ${order})`
  );
  return {
    type: TYPES.LIST_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
  };
}

export function recommendedUtilityAdminListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminUpdateRequest(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUpdateRequest(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_REQUEST,
    partnershipId: partnershipId,
    id: id,
    data: data,
  };
}

export function recommendedUtilityAdminUpdateSuccess(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUpdateSuccess(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.UPDATE_SUCCESS,
    partnershipId: partnershipId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminUpdateFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUpdateFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.UPDATE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminFormDestroy(formState = null) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminFormDestroy()`
  );
  return {
    type: TYPES.FORM_DESTROY,
    form: formState,
  };
}

export function recommendedUtilityAdminShowForm(id = null) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminShowForm()`
  );
  return {
    type: TYPES.SHOW_FORM,
    id: id,
  };
}

export function recommendedUtilityAdminHideForm() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminHideForm()`
  );
  return {
    type: TYPES.HIDE_FORM,
  };
}

export function recommendedUtilityAdminCreateRequest(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminCreateRequest(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_REQUEST,
    partnershipId: partnershipId,
    data: data,
  };
}

export function recommendedUtilityAdminCreateSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminCreateSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.CREATE_SUCCESS,
    partnershipId: partnershipId,
    id: data.id,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminCreateFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminCreateFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.CREATE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminUploadRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadRequest()`
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_REQUEST,
  };
}

export function recommendedUtilityAdminUploadSuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadSuccess()`
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_SUCCESS,
  };
}

export function recommendedUtilityAdminUploadFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminUploadFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_UPLOAD_FAILURE,
    error: error,
  };
}

export function recommendedUtilityAdminDownloadRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDownloadRequest()`
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_REQUEST,
  };
}

export function recommendedUtilityAdminDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDownloadSuccess()`
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_SUCCESS,
  };
}

export function recommendedUtilityAdminDownloadFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDownloadFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_FAILURE,
    error: error,
  };
}

export function recommendedUtilityAdminDestroyRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDestroyRequest()`
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_REQUEST,
  };
}

export function recommendedUtilityAdminDestroySuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDestroySuccess()`
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_SUCCESS,
  };
}

export function recommendedUtilityAdminDestroyFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDestroyFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_DESTROY_FAILURE,
    error: error,
  };
}

export function recommendedUtilityAdminPatchRequest(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminPatchRequest(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_REQUEST,
    partnershipId: partnershipId,
    id: id,
    data: data,
  };
}

export function recommendedUtilityAdminPatchSuccess(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminPatchSuccess(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_SUCCESS,
    partnershipId: partnershipId,
    id: id,
    data: data,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityAdminPatchFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDestroyFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityAdminDeleteRequest(partnershipId, id) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDeleteRequest(${partnershipId}, ${id})`
  );
  return {
    type: TYPES.DELETE_REQUEST,
    partnershipId: partnershipId,
    id: id,
  };
}

export function recommendedUtilityAdminDeleteSuccess(partnershipId, id) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDeleteSuccess(${partnershipId}, ${id})`
  );
  return {
    type: TYPES.DELETE_SUCCESS,
    partnershipId: partnershipId,
    id: id,
  };
}

export function recommendedUtilityAdminDeleteFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] recommendedUtilityAdminDeleteFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.DELETE_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function createRecommendedUtilityAdmin(
  partnershipId,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] createRecommendedUtilityAdmin(${partnershipId}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminCreateRequest(partnershipId, data));

    // call API
    const response = await api.postRecommendedUtilityAdmin(partnershipId, data);
    let success = false;

    // post recommended utility success
    if (201 === response.get('status')) {
      Logger.log(
        'info',
        `POST API recommended utility success. Partnership ID: ${partnershipId}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtilityAdmin]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityAdminCreateSuccess(partnershipId, data));
      success = true;

      // post recommended utility failure
    } else {
      Logger.log(
        'info',
        `POST API recommended utility failure. Partnership ID: ${partnershipId}.`
      );
      dispatch(
        recommendedUtilityAdminCreateFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function updateRecommendedUtilityAdmin(
  partnershipId,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] updateRecommendedUtilityAdmin(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminUpdateRequest(partnershipId, id, data));

    // call API
    const response = await api.putRecommendedUtilityAdmin(
      partnershipId,
      id,
      data
    );
    let success = false;

    // put recommended utility success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `UPDATE API recommended utility success. Partnership ID: ${partnershipId}, User: ${id}.`
      );

      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.recommendedUtilityAdmin]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityAdminUpdateSuccess(partnershipId, data));
      success = true;

      // put recommended utility failure
    } else {
      Logger.log(
        'info',
        `UPDATE API recommended utility failure. Partnership ID: ${partnershipId}, ID: ${id}.`
      );
      dispatch(
        recommendedUtilityAdminUpdateFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function destroyDocument(path, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] destroyDocument(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminDestroyRequest());

    // call API
    const response = await api.deleteDocumentAWS(path);
    let success = false;

    // put document success
    if (response['success']) {
      Logger.log('info', `Delete S3 document success.`);
      success = true;
      dispatch(recommendedUtilityAdminDestroySuccess());

      // put document failure
    } else {
      Logger.log('info', `Delete S3 document failure.`);
      dispatch(recommendedUtilityAdminDestroyFailure('Delete S3 error.'));
    }

    // callback function
    cb(success);
  };
}

export function loadRecommendedUtilitiesAdmin(
  partnershipId = null,
  page = 1,
  limit = 15,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] loadRecommendedUtilities(${partnershipId}, ${page}, ${limit}, ${order})`
  );
  return async function (dispatch) {
    dispatch(
      recommendedUtilityAdminListRequest(partnershipId, page, limit, order)
    );

    // call API
    const response = await api.getRecommendedUtilitiesAdmin(
      partnershipId,
      page,
      limit,
      order
    );
    let success = false;

    // get recommended utilities admin list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities admin list success. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'recommended_utilities']),
        [schema.recommendedUtilityAdmin]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityAdminListSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities admin list success [empty]. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };

      dispatch(recommendedUtilityAdminListSuccess(partnershipId, data));
      success = true;

      // get recommended utilities admin list failure
    } else {
      Logger.log(
        'info',
        `Get API recommended utilities admin list failure. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        recommendedUtilityAdminListFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function uploadDocument(path, file, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] uploadDocument(${path}, ###, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminUploadRequest());

    // call API
    const response = await api.putDocumentUpload(path, file);
    let success = false;

    // put document success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT S3 document success.`);
      success = true;
      dispatch(recommendedUtilityAdminUploadSuccess());

      // put document failure
    } else {
      Logger.log('info', `PUT S3 document failure.`);
      dispatch(recommendedUtilityAdminUploadFailure('Upload error.'));
    }

    // callback function
    cb(success);
  };
}

export function patchRecommendedUtilityAdmin(
  partnershipId = null,
  id = null,
  data = {},
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] patchRecommendedUtilityAdmin(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminPatchRequest(partnershipId, id, data));

    // call API
    const response = await api.patchRecommendedUtilityAdmin(
      partnershipId,
      id,
      data
    );
    let success = false;

    // patch recommended utility admin success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Patch API recommended utility admin success. Partnership ID: ${partnershipId} ID: ${id}.`
      );
      const normalizedEntities = normalize(
        [response.getIn(['data', 'recommended_utility'])],
        [schema.careGuideDocument]
      );
      const data = {
        id: response.getIn(['data', 'recommended_utility', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityAdminPatchSuccess(partnershipId, id, data));
      success = true;

      // patch recommended utility admin failure
    } else {
      Logger.log(
        'info',
        `Patch API recommended utility admin failure. Partnership ID: ${partnershipId}.`
      );
      dispatch(
        recommendedUtilityAdminPatchFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function deleteRecommendedUtilityAdmin(
  partnershipId = null,
  id = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] deleteRecommendedUtilityAdmin(${partnershipId}, ${id}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminDeleteRequest(partnershipId, id));

    // call API
    const response = await api.deleteRecommendedUtilityAdmin(partnershipId, id);
    let success = false;

    // delete recommended utility admin success
    if (204 === response.get('status')) {
      Logger.log(
        'info',
        `Delete API recommended utility admin success. Partnership ID: ${partnershipId} ID: ${id}.`
      );
      dispatch(recommendedUtilityAdminDeleteSuccess(partnershipId, id));
      success = true;

      // get recommended utility admin failure
    } else {
      Logger.log(
        'info',
        `Delete API recommended utility admin failure. Partnership ID: ${partnershipId}  ID: ${id}.`
      );
      dispatch(
        recommendedUtilityAdminDeleteFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadDocument(path, fileName, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.actions] downloadDocument(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityAdminDownloadRequest());

    // call API
    const response = await api.createGetPresignedUrlAWS(path, fileName);
    let success = false;

    let url = null;

    // create presigned url success
    if (response['success']) {
      Logger.log('info', `Create presigned URL success.`);
      success = true;

      url = response['presignedUrl'];

      dispatch(recommendedUtilityAdminDownloadSuccess());

      // create presigned url failure
    } else {
      Logger.log('info', `Create presigned URL failure.`);
      dispatch(recommendedUtilityAdminDownloadFailure('Download error.'));
    }

    // callback function
    cb(success, url);
  };
}

Logger.log('silly', `state.recommendedUtilitiesAdmin.actions loaded.`);
