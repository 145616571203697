import React from 'react';
import { Typography } from 'antd';
import { Translation } from 'react-i18next';

const { Title, Text } = Typography;

const DashboardAdminHeaderScreen = ({ ...props }) => {
  return (
    <Translation>
      {(t) => (
        <div style={{ marginBottom: '20px' }}>
          <Title level={3} className="dashboard-admin-title">
            {t('dashboard_admin_title')}
          </Title>
          <div>
            <Text type="secondary" style={{ fontSize: '16px' }}>
              {t('dashboard_admin_description')}
            </Text>
          </div>
        </div>
      )}
    </Translation>
  );
};

export default DashboardAdminHeaderScreen;
