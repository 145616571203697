import { Map, List } from 'immutable';

import { TYPES } from './actions';

import Logger from '../../../lib/Logger';

export default function resourcesAdmin(
  state = Map({
    isLoading: false,
    isLoadingPinned: false,
    isLoadingDashboard: false,
    isLoadingIds: false,
    isCSVDownloading: false,
    ids: List([]),
    pages: {},
    resourceDetailIsVisible: false,
    resourceDetailResourceId: null,
    total: 0,
    totalDashboard: 0,
    totalPinned: 0,
  }),
  action
) {
  Logger.log(
    'debug',
    `[resourcesAdmin.reducers] resourcesAdmin(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.RESOURCE_ADMIN_LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.RESOURCE_ADMIN_LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.RESOURCE_ADMIN_LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.RESOURCE_ADMIN_LIST_PINNED_REQUEST:
      return state.mergeDeep({
        isLoadingPinned: true,
      });

    case TYPES.RESOURCE_ADMIN_LIST_PINNED_SUCCESS:
      return state
        .mergeDeep({
          isLoadingPinned: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          totalPinned: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.RESOURCE_ADMIN_LIST_PINNED_FAILURE:
      return state.mergeDeep({
        isLoadingPinned: false,
      });

    case TYPES.RESOURCE_ADMIN_LIST_DASHBOARD_REQUEST:
      return state.mergeDeep({
        isLoadingDashboard: true,
      });

    case TYPES.RESOURCE_ADMIN_LIST_DASHBOARD_SUCCESS:
      return state
        .mergeDeep({
          isLoadingDashboard: false,
          pages: {
            [action.order]: {
              [action.limit]: {
                [action.page]: null,
              },
            },
          },
          totalDashboard: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.RESOURCE_ADMIN_LIST_DASHBOARD_FAILURE:
      return state.mergeDeep({
        isLoadingDashboard: false,
      });

    case TYPES.RESOURCE_ADMIN_IDS_REQUEST:
      return state.mergeDeep({
        isLoadingIds: true,
      });

    case TYPES.RESOURCE_ADMIN_IDS_SUCCESS:
      return state
        .mergeDeep({
          isLoadingIds: false,
        })
        .setIn(['ids'], List(action.data));

    case TYPES.RESOURCE_ADMIN_IDS_FAILURE:
      return state.mergeDeep({
        isLoadingIds: false,
      });

    case TYPES.RESOURCE_ADMIN_READ_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.RESOURCE_ADMIN_READ_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.RESOURCE_ADMIN_READ_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.RESOURCE_ADMIN_UPDATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.RESOURCE_ADMIN_UPDATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.RESOURCE_ADMIN_UPDATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.RESOURCE_ADMIN_CREATE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.RESOURCE_ADMIN_CREATE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        form: {
          isSubmitting: false,
          success: true,
          created_id: action.id,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.RESOURCE_ADMIN_CREATE_FAILURE:
      return state
        .mergeDeep({
          isLoading: false,
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.RESOURCE_ADMIN_DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.RESOURCE_ADMIN_DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.RESOURCE_ADMIN_DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.RESOURCE_ADMIN_DELETES_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.RESOURCE_ADMIN_DELETES_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.RESOURCE_ADMIN_DELETES_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.RESOURCE_ADMIN_FORM_DESTROY:
      return state
        .mergeDeep({
          form: null,
        })
        .set('form', action.form);

    case TYPES.RESOURCE_ADMIN_PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.RESOURCE_ADMIN_PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.RESOURCE_ADMIN_PATCH_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.RESOURCES_ADMIN_PATCH_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.RESOURCES_ADMIN_PATCH_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.RESOURCES_ADMIN_PATCH_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.RESOURCE_ADMIN_SHOW_PREVIEW_DETAIL:
      return state.mergeDeep({
        resourceDetailIsVisible: true,
        resourceDetailResourceId: action.resourceId,
      });

    case TYPES.RESOURCE_ADMIN_HIDE_PREVIEW_DETAIL:
      return state.mergeDeep({
        resourceDetailIsVisible: false,
        resourceDetailResourceId: null,
      });

    case TYPES.RESOURCE_ADMIN_CSV_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isCSVDownloading: true,
      });

    case TYPES.RESOURCE_ADMIN_CSV_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case TYPES.RESOURCE_ADMIN_CSV_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isCSVDownloading: false,
      });

    case TYPES.RESOURCE_ADMIN_PDF_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isPDFDownloading: true,
      });

    case TYPES.RESOURCE_ADMIN_PDF_DOWNLOAD_SUCCESS:
      return state.mergeDeep({
        isPDFDownloading: false,
      });

    case TYPES.RESOURCE_ADMIN_PDF_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isPDFDownloading: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `resourcesAdmin.reducers loaded.`);
