import React, { useState, useRef, useEffect } from 'react';
import { Translation } from 'react-i18next';
import { Col, Tag, Row, Space, Divider, Typography, Button } from 'antd';
import imageURL from '../../../elements/lib/imageURL';
import { TelephoneDisplayAdmin } from '../../../elements/components/TelephoneDisplay';
import { WebsiteDisplayAdmin } from '../../../elements/components/WebsiteDisplay';
import { EmailDisplayAdmin } from '../../../elements/components/EmailDisplay';
import ReactQuill from 'react-quill';
import { pinFilled } from '../../../elements/components/Icons';
import ListAction from './ListAction';
import { EyeOutlined } from '@ant-design/icons';
import {
  MixpanelTracker,
  mixpanelEventsEnum,
} from '../../../../../lib/Mixpanel';

const { Title } = Typography;

const modules = {
  toolbar: '#toolbar-read-only-resource',
};

const types = {
  link: 'link',
  phone: 'phone',
  email: 'email',
};

const ResourceCardHeader = ({
  item,
  partnershipActiveId,
  partnershipName,
  handlePinned,
  handleDisabled,
  handleDownloadCSV,
  handleDownloadPDF,
  isDownload,
  confirmDelete,
  page,
  limit,
  order,
  filter,
}) => {
  return (
    <Translation>
      {(t) => (
        <Row align="top" justify="end" style={{ height: '26px' }}>
          <Col>
            <Space
              className={`cursor-pointer ${item.is_pinned && 'icon-active'}`}
              onClick={(e) => {
                e.stopPropagation();
                handlePinned(
                  partnershipActiveId,
                  item,
                  page,
                  limit,
                  order,
                  filter
                );
              }}
            >
              {item.is_pinned && pinFilled(12, 15)}
            </Space>
            <ListAction
              row={item}
              partnershipName={partnershipName}
              partnershipActiveId={partnershipActiveId}
              editScreen="ResourceAdminEditScreen"
              handlePinned={handlePinned}
              handleDisabled={handleDisabled}
              handleDownloadCSV={handleDownloadCSV}
              handleDownloadPDF={handleDownloadPDF}
              isDownload={isDownload}
              page={page}
              limit={limit}
              order={order}
              filter={filter}
              confirmDelete={() =>
                confirmDelete(
                  partnershipActiveId,
                  item,
                  page,
                  limit,
                  order,
                  filter
                )
              }
            />
          </Col>
        </Row>
      )}
    </Translation>
  );
};

const ResourceCardFotter = ({
  item,
  showList,
  partnershipName,
  partnershipActiveId,
}) => {
  return (
    <Translation>
      {(t) => (
        <Row align="top" justify="space-between">
          <Col>
            <Button
              size="small"
              icon={
                <span className="anticon">
                  <EyeOutlined />
                </span>
              }
              onClick={(e) => {
                e.stopPropagation();

                MixpanelTracker.mixpanelTrack(
                  mixpanelEventsEnum.RESOURCE.OPEN_NOTES,
                  {
                    partnershipName: partnershipName,
                    partnershipId: partnershipActiveId,
                    resourceId: item.id,
                    resourceName: item.name,
                  }
                );

                showList(item.id);
              }}
              style={{ marginTop: '10px' }}
            >
              {t('resource_notes_button_notes')}
            </Button>
          </Col>
        </Row>
      )}
    </Translation>
  );
};

const ResourceCard = ({
  item,
  partnershipActiveId,
  partnershipName,
  handlePinned,
  handleDisabled,
  handleDownloadCSV,
  handleDownloadPDF,
  confirmDelete,
  confirmShowPreview,
  isDownload,
  page,
  limit,
  order,
  filter,
  showList,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [applyLineClamp, setApplyLineClamp] = useState(false);
  const textRef = useRef(null);

  const onClickLinks = (event, item, type) => {
    event.stopPropagation();

    MixpanelTracker.mixpanelTrack(mixpanelEventsEnum.RESOURCE.OPEN_LINK, {
      partnershipName: partnershipName,
      partnershipId: partnershipActiveId,
      resourceId: item.id,
      resourceName: item.name,
      linkType: types[type],
    });
  };

  const checkAndApplyLineClamp = () => {
    if (textRef.current && isHovered) {
      const lineHeight = 43;
      const exceedsOneLine = textRef.current.clientHeight > lineHeight;
      setApplyLineClamp(exceedsOneLine);
    } else {
      setApplyLineClamp(false);
    }
  };

  useEffect(() => {
    checkAndApplyLineClamp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered]);

  const hoverTimeout = useRef(null);

  const handleMouseEnter = () => {
    clearTimeout(hoverTimeout.current);
    hoverTimeout.current = setTimeout(() => {
      setIsHovered(true);
    }, 50);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverTimeout.current);
    hoverTimeout.current = setTimeout(() => {
      setIsHovered(false);
    }, 50);
  };

  useEffect(() => {
    return () => {
      clearTimeout(hoverTimeout.current);
    };
  }, []);

  const showTags = (tags) => {
    if (tags.length > 4) {
      return (
        <>
          {tags.slice(0, 4).map((x, i) => (
            <Tag color={x.color_hex} key={i}>
              {x.label}
            </Tag>
          ))}
          <Tag color={'magenta'}>{'+' + (tags.length - 4)}</Tag>
        </>
      );
    } else {
      return tags.map((x, i) => (
        <Tag color={x.color_hex} key={i}>
          {x.label}
        </Tag>
      ));
    }
  };

  return (
    <Translation>
      {(t) => (
        <div
          onClick={(e) => confirmShowPreview(e, item)}
          className={`resource-card ${item.status === 2 && 'disabled'}`}
          style={{
            marginTop: '6px',
            height: '400px',
            border: item?.partnership
              ? `2px solid ${item?.partnership.color_hex}`
              : item.is_universal
              ? '2px solid #1A2E5F'
              : null,
            cursor: 'pointer',
          }}
        >
          <ResourceCardHeader
            item={item}
            partnershipActiveId={partnershipActiveId}
            partnershipName={partnershipName}
            handlePinned={handlePinned}
            handleDisabled={handleDisabled}
            handleDownloadCSV={handleDownloadCSV}
            handleDownloadPDF={handleDownloadPDF}
            isDownload={isDownload}
            confirmDelete={confirmDelete}
            page={page}
            limit={limit}
            order={order}
            filter={filter}
          />
          <Row align="top" justify="space-between" style={{ marginTop: '8px' }}>
            <Col
              xs={{ span: item?.logo ? 16 : 24, order: 1 }}
              md={{ span: item?.logo ? 16 : 24, order: 1 }}
              lg={{ span: item?.logo ? 16 : 24, order: 1 }}
              xl={{ span: item?.logo ? 16 : 24, order: 1 }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={textRef}
              >
                <Title className={'resource-card-title'} level={3}>
                  {item.name}
                </Title>
              </div>
            </Col>
            <Col
              xs={{ span: 8, order: 2 }}
              md={{ span: 8, order: 2 }}
              lg={{ span: 8, order: 2 }}
              xl={{ span: 8, order: 2 }}
            >
              <div className={'resources-card-image'}>
                {item?.logo &&
                  item?.logo.file_directory &&
                  item?.logo.file_name &&
                  item?.logo.file_extension && (
                    <figure>
                      <img
                        style={{ maxWidth: '100%' }}
                        src={imageURL(
                          item.logo.file_directory,
                          item.logo.file_name,
                          item.logo.file_extension,
                          145,
                          35,
                          'ratio'
                        )}
                        alt=""
                      />
                    </figure>
                  )}
              </div>
            </Col>
          </Row>

          <Row align="middle">
            <Col
              xs={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
            >
              {!item.is_universal ? (
                item?.partnership ? (
                  <Tag
                    color={item.partnership?.color_hex}
                    className="cursor-pointer"
                  >
                    {item.partnership?.name}
                  </Tag>
                ) : (
                  <Tag color="#FE971F" className="cursor-pointer">
                    {t('resource_notes_tag_custom_resource')}
                  </Tag>
                )
              ) : (
                <Tag color={'#1A2E5F'} className="cursor-pointer">
                  {t('resource_notes_tag_general_resource')}
                </Tag>
              )}
            </Col>
          </Row>

          <Divider style={{ margin: '8px 0 8px 0' }} />

          {item?.service_tags
            ? item?.service_tags?.length > 0 && (
                <div className="resource-list-tag-card">
                  {showTags(item?.service_tags)}
                </div>
              )
            : null}

          {item?.description &&
            item?.description !== '<p><br></p>' &&
            item?.description.length > 0 && (
              <div
                className={`resource-list-description ${
                  applyLineClamp ? 'resource-list-description-expanded' : ''
                }`}
              >
                <div id="toolbar-read-only-resource"></div>
                <ReactQuill
                  modules={modules}
                  readOnly
                  value={item.description}
                />
              </div>
            )}
          {(item?.website || item?.telephone || item?.email) && (
            <div className="info-container">
              {item?.website ? (
                <WebsiteDisplayAdmin
                  website={item.website}
                  onClick={(e) => onClickLinks(e, item, 'link')}
                />
              ) : null}
              {item?.telephone ? (
                <TelephoneDisplayAdmin
                  telephone={item.telephone}
                  onClick={(e) => onClickLinks(e, item, 'phone')}
                />
              ) : null}
              {item?.email ? (
                <EmailDisplayAdmin
                  email={item.email}
                  onClick={(e) => onClickLinks(e, item, 'email')}
                />
              ) : null}
            </div>
          )}
          <ResourceCardFotter
            item={item}
            showList={showList}
            partnershipName={partnershipName}
            partnershipActiveId={partnershipActiveId}
          />
        </div>
      )}
    </Translation>
  );
};

export default ResourceCard;
