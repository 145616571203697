import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  pages: {},
  total: 0,
  results: {},
  isFileDownloading: false,
  form: {},
  isFormVisible: false,
  id: null,
  isSubmitting: false,
  isDocumentDestroying: false,
});

export default function recommendedUtilitiesAdmin(
  state = initialState,
  action
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilitiesAdmin.reducers] recommendedUtilitiesAdmin(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.partnershipId]: {
              [action.order]: {
                [action.limit]: {
                  [action.page]: null,
                },
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.order,
            action.limit,
            action.page,
          ],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DOCUMENT_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isFileDownloading: true,
      });

    case TYPES.DOCUMENT_DOWNLOAD_SUCCESS:
    case TYPES.DOCUMENT_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isFileDownloading: false,
      });

    case TYPES.CREATE_REQUEST:
    case TYPES.UPDATE_REQUEST:
      return state.mergeDeep({
        form: {
          isSubmitting: true,
          success: null,
          errors: null,
        },
      });

    case TYPES.UPDATE_SUCCESS:
    case TYPES.CREATE_SUCCESS:
      return state.mergeDeep({
        form: {
          isSubmitting: false,
          success: true,
          errors: null,
        },
        lastUpdated: action.receivedAt,
      });

    case TYPES.UPDATE_FAILURE:
    case TYPES.CREATE_FAILURE:
      return state
        .mergeDeep({
          form: {
            isSubmitting: false,
            success: false,
            errors: null,
          },
        })
        .setIn(['form', 'errors'], action.error);

    case TYPES.FORM_DESTROY:
      return state
        .mergeDeep({
          form: {},
          id: null,
        })
        .set('form', action.form);

    case TYPES.SHOW_FORM:
      return state.mergeDeep({
        isFormVisible: true,
        id: action.id,
      });

    case TYPES.HIDE_FORM:
      return state.mergeDeep({
        isFormVisible: false,
      });

    case TYPES.DOCUMENT_UPLOAD_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.DOCUMENT_UPLOAD_FAILURE:
    case TYPES.DOCUMENT_UPLOAD_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DOCUMENT_DESTROY_REQUEST:
      return state.mergeDeep({
        isDocumentDestroying: true,
      });

    case TYPES.DOCUMENT_DESTROY_FAILURE:
    case TYPES.DOCUMENT_DESTROY_SUCCESS:
      return state.mergeDeep({
        isDocumentDestroying: false,
      });

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
        total: state.get('total') - 1,
      });

    case TYPES.DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.recommendedUtilitiesAdmin.reducers loaded.`);
