import DashboardAdminResources from '../components/DashboardAdminResources';
import { schema } from '../../../../../state/schema';
import { denormalize } from 'normalizr';

import {
  loadResourcesDashboardAdmin,
  patchResourceAdmin,
} from '../../../../../state/modules/resourcesAdmin/actions';

import { connect } from 'react-redux';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  const order = 'is_pinned_dashboard.asc';
  const limit = 6;
  const page = 1;

  const partnershipId = storage.get('local', 'partnershipId');
  const pinnedIds = [];
  const result = state.resourcesAdmin.getIn([
    'pages',
    partnershipId,
    order,
    limit,
    page,
  ]);
  const list = result
    ? result
        .map((x) => {
          pinnedIds.push(x);
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['resourcesAdmin', x]),
              schema.resourceAdmin,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];
  return {
    page,
    order,
    limit,
    partnershipId,
    list,
    pinnedIds,
    isLoading: state.resourcesAdmin.get('isLoadingDashboard'),
    total: state.resourcesAdmin.get('totalDashboard') ?? 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, page, limit, order, cb) => {
      dispatch(
        loadResourcesDashboardAdmin(partnershipId, page, limit, order, cb)
      );
    },
    patch: (partnershipActiveId, id, data, cb) => {
      dispatch(patchResourceAdmin(partnershipActiveId, id, data, cb));
    },
  };
};

const DashboardAdminResourcesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAdminResources);

export default DashboardAdminResourcesContainer;
