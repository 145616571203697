import { connect } from 'react-redux';
import DashboardDetailPartnership from '../components/DashboardDetailPartnership';
import { schema } from '../../../../../state/schema';
import { denormalize } from 'normalizr';
import { loadDashboardSections } from '../../../../../state/modules/dashboardSections/actions';

const mapStateToProps = (state, ownProps) => {
  const partnershipId = ownProps.partnershipId;
  const page = 1;
  const limit = 10;
  const order = 'order.asc';

  const result = state.dashboardSections.getIn([
    'pages',
    partnershipId,
    order,
    limit,
    page,
  ]);

  const list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['dashboardSections', x]),
              schema.dashboardSection,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {
    list,
    page,
    limit,
    order,
    partnershipId,
    isLoading: state.dashboardSections.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, page, limit, order, cb) => {
      dispatch(loadDashboardSections(partnershipId, page, limit, order, cb));
    },
  };
};

const DashboardDetailPartnershipContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDetailPartnership);

export default DashboardDetailPartnershipContainer;
