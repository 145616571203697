import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { Translation, getI18n } from 'react-i18next';
import { Button, Checkbox, Form, Radio, Select, Space, Table } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import Logger from '../../../../../lib/Logger';
import message from '../../../elements/lib/MessageWrapper';

const UserAccountAlertsForm = ({ data, ...props }) => {
  const [alertsToken, setAlertsToken] = useState('alerts-token');
  const [valuesInApp, setValuesInApp] = useState(data.alerts_in_app);
  const [valuesEmail, setValuesEmail] = useState(data.alerts_email);
  const [valuesSMS, setValuesSMS] = useState(data.alerts_sms);
  const [remindersToken, setRemindersToken] = useState('reminders-token');
  const [valuesRemindersEmail, setValuesRemindersEmail] = useState(
    data.reminders_email
  );
  const [valuesRemindersSMS, setValuesRemindersSMS] = useState(
    data.reminders_sms
  );
  const [valueSummary, setValueSummary] = useState(
    data.alert_summary === true ? 2 : 1
  );
  const [valueSummaryAt, setValueSummaryAt] = useState(data.alert_summary_at);
  const [form] = Form.useForm();

  useEffect(() => {
    setValuesEmail({ ...data.alerts_email });
    setValuesSMS({ ...data.alerts_sms });
    setValuesRemindersEmail({ ...data.reminders_email });
    setValuesRemindersSMS({ ...data.reminders_sms });
    setValueSummary(data.alert_summary === true ? 2 : 1);
    setValueSummaryAt(data.alert_summary_at);
  }, [data]);

  const alertOptions = [
    'journal_new',
    'journal_comment',
    'journal_emoji',
    'calendar_event_new',
    'calendar_event_update',
    'care_guide_update',
    'member_invite_respond',
    'resource_new',
    'resource_update',
    'partnership_form_answer_new',
  ];

  const alertColumns = [
    {
      title: getI18n().t('user_account_alerts_form_table_alerts_title'),
      dataIndex: 'alerts_type',
    },
    {
      title: getI18n().t('user_account_alerts_form_input_alerts_in_app'),
      dataIndex: 'alerts_in_app',
    },
    {
      title: getI18n().t('user_account_alerts_form_input_alerts_email'),
      dataIndex: 'alerts_email',
    },
    {
      title: getI18n().t('user_account_alerts_form_input_alerts_sms'),
      dataIndex: 'alerts_sms',
    },
  ];

  const alertsData = alertOptions.map((x, i) => {
    return {
      key: i,
      alerts_type: getI18n().t('user_accounts_alert_' + x),
      alerts_in_app: (
        <Checkbox
          name="alerts_in_app"
          checked={valuesInApp[x]}
          onChange={(e) => onChangeInApp(x)}
        ></Checkbox>
      ),
      alerts_email: (
        <Checkbox
          name="alerts_email"
          checked={valuesEmail[x]}
          onChange={(e) => onChangeEmails(x)}
        ></Checkbox>
      ),
      alerts_sms: (
        <Checkbox
          name="alerts_sms"
          checked={valuesSMS[x]}
          onChange={(e) => onChangeSMS(x)}
        ></Checkbox>
      ),
    };
  });

  const onChangeInApp = (val) => {
    if (valuesInApp[val]) {
      setValuesInApp({ ...valuesInApp, [val]: false });
    } else {
      setValuesInApp({ ...valuesInApp, [val]: true });
    }
  };

  const onChangeEmails = (val) => {
    if (valuesEmail[val]) {
      setValuesEmail({ ...valuesEmail, [val]: false });
    } else {
      setValuesEmail({ ...valuesEmail, [val]: true });
    }
  };

  const onChangeSMS = (val) => {
    if (valuesSMS[val]) {
      setValuesSMS({ ...valuesSMS, [val]: false });
    } else {
      setValuesSMS({ ...valuesSMS, [val]: true });
    }
  };

  useEffect(() => {
    setAlertsToken(
      'alerts-' +
        Object.keys(valuesEmail).join(',') +
        ':' +
        Object.keys(valuesSMS).join(',')
    );
  }, [valuesEmail, valuesSMS]);

  const reminderOptions = ['calendar_event_1_hour', 'calendar_event_24_hours'];

  const reminderColumns = [
    {
      title: getI18n().t('user_account_alerts_form_table_reminders_title'),
      dataIndex: 'reminders_type',
    },
    {
      title: getI18n().t('user_account_alerts_form_input_reminders_email'),
      dataIndex: 'reminders_email',
    },
    {
      title: getI18n().t('user_account_alerts_form_input_reminders_sms'),
      dataIndex: 'reminders_sms',
    },
  ];

  const reminderData = reminderOptions.map((x, i) => {
    return {
      key: i,
      reminders_type: getI18n().t('user_accounts_reminder_' + x),
      reminders_email: (
        <Checkbox
          name="reminders_email"
          checked={valuesRemindersEmail[x]}
          onChange={(e) => onChangeReminderEmails(x)}
        ></Checkbox>
      ),
      reminders_sms: (
        <Checkbox
          name="reminders_sms"
          checked={valuesRemindersSMS[x]}
          onChange={(e) => onChangeReminderSMS(x)}
        ></Checkbox>
      ),
    };
  });

  const onChangeReminderEmails = (val) => {
    if (valuesRemindersEmail[val]) {
      setValuesRemindersEmail({ ...valuesRemindersEmail, [val]: false });
    } else {
      setValuesRemindersEmail({ ...valuesRemindersEmail, [val]: true });
    }
  };

  const onChangeReminderSMS = (val) => {
    if (valuesRemindersSMS[val]) {
      setValuesRemindersSMS({ ...valuesRemindersSMS, [val]: false });
    } else {
      setValuesRemindersSMS({ ...valuesRemindersSMS, [val]: true });
    }
  };

  useEffect(() => {
    setRemindersToken(
      'reminders-' +
        Object.keys(valuesRemindersEmail).join(',') +
        ':' +
        Object.keys(valuesRemindersSMS).join(',')
    );
  }, [valuesRemindersEmail, valuesRemindersSMS]);

  // initialize summary at options
  const summaryAtOptions = [];
  const interval = 60;
  const ap = ['AM', 'PM'];
  let tt = 0;

  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60);
    var mm = tt % 60;
    let valueSA = ('0' + hh).slice(-2) + ':' + ('0' + mm).slice(-2) + ':00';
    let labelSA =
      ('' + (hh === 12 || hh === 0 ? 12 : hh % 12)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ' ' +
      ap[Math.floor(hh / 12)];
    summaryAtOptions[i] = {
      value: valueSA,
      label: labelSA,
    };
    tt = tt + interval;
  }

  // handle errors reported by API
  useEffect(() => {
    let firstFieldName = '';
    for (const field in props.errors) {
      form.setFields([{ name: field, errors: props.errors[field] }]);
      if (firstFieldName === '') {
        firstFieldName = field;
      }
    }
    form.scrollToField(firstFieldName);
  }, [form, props.errors]);

  // submit data handler
  const submitData = async (values) => {
    Logger.log('debug', `UserAccountAlertsForm.submitData(###)`);

    // API POST/PUT payload
    const payload = {
      alerts_in_app: valuesInApp,
      alerts_email: valuesEmail,
      alerts_sms: valuesSMS,
      reminders_email: valuesRemindersEmail,
      reminders_sms: valuesRemindersSMS,
      alert_summary: valueSummary === 2,
      alert_summary_at: valueSummaryAt,
    };

    // update
    props.submit(payload, (success) => {
      if (success) {
        message.success(i18next.t('user_account_alerts_form_message_success'));
      } else {
        message.error(i18next.t('user_account_alerts_form_message_failure'));
      }
    });
  };

  // form submit handler
  const handleFinish = async (values) => {
    Logger.log('debug', `ResourceForm.handleFinish(###)`);
    if (!props.isSubmitting) {
      await submitData(values);
    }
  };

  // form error handler
  const handleFinishFailed = ({ values, errorFields, outOfDate }) => {
    Logger.log('debug', `ResourceForm.handleFinishFailed(###)`);
    message.error(getI18n().t('feedback_form_error'));
    if (errorFields && errorFields.length > 0) {
      form.scrollToField(errorFields[0].name);
    }
  };

  return (
    <Translation>
      {(t) => (
        <div className="user-account-alerts-form">
          <Form
            name="user_alerts_account_form"
            onFinish={handleFinish}
            onFinishFailed={handleFinishFailed}
            form={form}
          >
            <Table
              key={alertsToken}
              columns={alertColumns}
              dataSource={alertsData}
              size="small"
              pagination={false}
            />

            <div className="form-group">
              <Form.Item
                label={t('user_account_alerts_form_input_email_frequency')}
              >
                <Radio.Group
                  onChange={(e) => setValueSummary(e.target.value)}
                  value={valueSummary}
                >
                  <Space direction="vertical">
                    <div>
                      <Radio value={1}>
                        {t(
                          'user_account_alerts_form_input_email_frequency_every_time'
                        )}
                      </Radio>
                    </div>
                    <div>
                      <Radio value={2}>
                        {t(
                          'user_account_alerts_form_input_email_frequency_daily_summary'
                        )}
                        :
                      </Radio>

                      <Select
                        value={valueSummaryAt}
                        onChange={(e) => setValueSummaryAt(e)}
                        style={{ width: 110 }}
                      >
                        {summaryAtOptions.map((x) => (
                          <Select.Option key={x.value} value={x.value}>
                            {x.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>

            <Table
              key={remindersToken}
              columns={reminderColumns}
              dataSource={reminderData}
              size="small"
              pagination={false}
            />

            <div className="form-actions">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<CheckCircleOutlined />}
                  loading={props.isSubmitting}
                >
                  {t('user_account_alerts_form_button_submit')}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </Translation>
  );
};

export default UserAccountAlertsForm;

Logger.log('silly', `UserAccountAlertsForm loaded.`);
