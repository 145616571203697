import { schema as s } from 'normalizr';

import Logger from '../lib/Logger';

// users
const userProfile = new s.Entity('userProfiles');

// simple lists
const relationship = new s.Entity('relationships');
const plan = new s.Entity('plans');
const planAdmin = new s.Entity('plansAdmin');

// accounts
const account = new s.Entity('accounts', {
  plan: plan,
});

// partnership
const partnership = new s.Entity('partnerships');

// locations
const region = new s.Entity('regions');
const subregion = new s.Entity('subregions');
const city = new s.Entity('cities');
const postalCode = new s.Entity('postalCodes');

// care guides
const careGuideHelperTag = new s.Entity('careGuideHelperTags');
const careGuideHelper = new s.Entity('careGuideHelpers', {
  profile: userProfile,
});
const careGuideMember = new s.Entity('careGuideMembers', {
  profile: userProfile,
});

const careGuidesMembersPartnership = new s.Entity(
  'careGuidesMembersPartnerships',
  {
    profile: userProfile,
  }
);

const careGuideMemberPartnership = new s.Entity(
  'careGuidesMembersPartnership',
  {}
);

const careGuideSegmentContent = new s.Entity('CareGuideSegmentContents');
const careGuideSegment = new s.Entity('careGuideSegments', {
  contents: [careGuideSegmentContent],
});
const careGuide = new s.Entity('careGuides', {
  account: account,
  helpers: [careGuideHelper],
  relationship: relationship,
  segments: [careGuideSegment],
  partnership: partnership,
  profile: userProfile,
});

const careGuideAdmin = new s.Entity('careGuidesAdmin', {
  owner: userProfile,
  segments: [careGuideSegment],
});

const careGuideInvitation = new s.Entity('careGuideInvitations', {
  profile: userProfile,
  care_guide: careGuide,
});

// media
const medium = new s.Entity('media', {
  care_guide: careGuide,
  owner: userProfile,
  partnership: partnership,
});

// journals

const journalTag = new s.Entity('journalTags');
const journalPartnerTagAdmin = new s.Entity('journalPartnerTagsAdmin');
const journalPartnerTag = new s.Entity('journalPartnerTags');

const journalPost = new s.Entity('journalPosts', {
  profile: userProfile,
  media: [medium],
  tags: [journalTag],
  partner_tags: [journalPartnerTag],
});
const journalReply = new s.Entity('journalReplies', {
  profile: userProfile,
});

// messages
const messageRecipient = new s.Entity('messageRecipients', {
  profile: userProfile,
});
const message = new s.Entity('messages', {
  profile: userProfile,
  recipients: [messageRecipient],
});
const messageThread = new s.Entity('messageThreads', {
  messages: [message],
  care_guide: careGuide,
});

// calendar
const calendarEventParticipant = new s.Entity('calendarEventParticipants', {
  profile: userProfile,
});
const calendarEvent = new s.Entity('calendarEvents', {
  profile: userProfile,
  participants: [calendarEventParticipant],
});
const calendarEventException = new s.Entity('calendarEventExceptions', {
  event: calendarEvent,
});

const userProfilePartnership = new s.Entity('userProfilesPartnerships', {
  partnership: partnership,
  profile: userProfile,
});

const userProfilePartnershipAdmin = new s.Entity(
  'userProfilesPartnershipsAdmin',
  {
    partnership: partnership,
    profile: userProfile,
  }
);

// resources
const resourceTag = new s.Entity('resourceTags');
const resourceServiceTag = new s.Entity('resourceServiceTags');
const resource = new s.Entity('resources', {
  service_tags: [resourceServiceTag],
  care_guide: careGuide,
  care_guides: [careGuide],
  partnership: partnership,
});
const resourceNote = new s.Entity('resourceNotes', {
  profile: userProfile,
});

const resourceAdmin = new s.Entity('resourcesAdmin', {
  care_guide: careGuide,
  care_guides: [careGuide],
  partnership: partnership,
  service_tags: [resourceServiceTag],
});

// alerts
const alert = new s.Entity('alerts', {
  profile: userProfile,
  care_guide: careGuide,
  subject_profile: userProfile,
  object_profile: userProfile,
  object_journal: journalPost,
  object_journal_comment: journalReply,
  object_calendar_event: calendarEvent,
  object_care_guide: careGuide,
  object_resource: resource,
});

// payments
const subscription = new s.Entity('subscriptions', {
  plan: plan,
  account: account,
});

const registrationCode = new s.Entity('registrationCodes', {
  profile: userProfile,
  plan: planAdmin,
  partnership: partnership,
});

const calendarEventAdmin = new s.Entity('calendarEventsAdmin');
calendarEventAdmin.define({
  care_guide: careGuide,
  care_guides: [careGuide],
  partnership: partnership,
  profile: userProfile,
  participants: [calendarEventParticipant],
  parent_event: calendarEvent,
});

const careGuidePartnership = new s.Entity('careGuidesPartnership', {
  account: account,
  helpers: [careGuideHelper],
  relationship: relationship,
  segments: [careGuideSegment],
});

const careGuidePartnerTagsAdminPartnership = new s.Entity(
  'careGuidePartnerTagsAdminPartnerships'
);

const adminPartnerClass = new s.Entity('adminPartnerClasses', {
  partnership: partnership,
});

const adminPartnerClassAdmin = new s.Entity('adminPartnerClassesAdmin', {
  partnership: partnership,
});

const careGuidePartnerTagAdmin = new s.Entity('careGuidePartnerTagsAdmin', {
  admin_partner_class: adminPartnerClassAdmin,
});

const careGuideNote = new s.Entity('careGuideNotes');
const careGuideDocument = new s.Entity('careGuideDocuments');

const partnershipFormAnswer = new s.Entity('partnershipFormAnswers', {
  profile: userProfile,
  care_guide: careGuide,
  partnership: partnership,
});

const dashboardSection = new s.Entity('dashboardSections', {
  partnership: partnership,
});

const recommendedUtility = new s.Entity('recommendedUtilities', {
  partnership: partnership,
});

const recommendedUtilityAdmin = new s.Entity('recommendedUtilitiesAdmin', {
  partnership: partnership,
});

// construct schema for export
const schema = {
  userProfile: userProfile,
  relationship: relationship,
  plan: plan,
  account: account,
  region: region,
  subregion: subregion,
  city: city,
  postalCode: postalCode,
  careGuide: careGuide,
  careGuideHelper: careGuideHelper,
  careGuideHelperTag: careGuideHelperTag,
  careGuideMember: careGuideMember,
  careGuidesMembersPartnership: careGuidesMembersPartnership,
  careGuideInvitation: careGuideInvitation,
  careGuideSegment: careGuideSegment,
  CareGuideSegmentContent: careGuideSegmentContent,
  medium: medium,
  journalPost: journalPost,
  journalReply: journalReply,
  journalTag: journalTag,
  messageThread: messageThread,
  message: message,
  messageRecipient: messageRecipient,
  calendarEvent: calendarEvent,
  calendarEventParticipant: calendarEventParticipant,
  calendarEventException: calendarEventException,
  resource: resource,
  resourceTag: resourceTag,
  resourceServiceTag: resourceServiceTag,
  resourceNote: resourceNote,
  alert: alert,
  subscription: subscription,
  partnership: partnership,
  userProfilePartnership: userProfilePartnership,
  registrationCode: registrationCode,
  planAdmin: planAdmin,
  journalPartnerTagAdmin: journalPartnerTagAdmin,
  journalPartnerTag: journalPartnerTag,
  userProfilePartnershipAdmin: userProfilePartnershipAdmin,
  calendarEventAdmin: calendarEventAdmin,
  careGuideMemberPartnership: careGuideMemberPartnership,
  careGuidePartnership: careGuidePartnership,
  resourceAdmin: resourceAdmin,
  careGuideAdmin: careGuideAdmin,
  careGuidePartnerTagAdmin: careGuidePartnerTagAdmin,
  careGuidePartnerTagsAdminPartnership: careGuidePartnerTagsAdminPartnership,
  adminPartnerClass: adminPartnerClass,
  adminPartnerClassAdmin: adminPartnerClassAdmin,
  careGuideNote: careGuideNote,
  careGuideDocument: careGuideDocument,
  partnershipFormAnswer: partnershipFormAnswer,
  dashboardSection: dashboardSection,
  recommendedUtility: recommendedUtility,
  recommendedUtilityAdmin: recommendedUtilityAdmin,
};
export { schema };

Logger.log('silly', `schema loaded.`);
