import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';

import DailyLogs from '../components/DailyLogs';
import {
  loadPartnershipFormAnswers,
  generatePartnershipFormAnswerPDF,
} from '../../../../../state/modules/partnershipFormAnswers/actions';

const mapStateToProps = (state, ownProps) => {
  const partnershipId = ownProps.partnershipId;
  const careGuideId = ownProps.careGuideId;
  const page_daily_logs = ownProps.page_daily_logs ?? 1;
  const limit = 5;
  const order = 'created_at.desc';

  const result = state.partnershipFormAnswers.getIn([
    'pages',
    partnershipId,
    careGuideId,
    page_daily_logs,
    limit,
    order,
  ]);

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['partnershipFormAnswers', x]),
                schema.partnershipFormAnswer,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
      : [],
    isLoading: state.partnershipFormAnswers.get('isLoading'),
    total: state.partnershipFormAnswers.get('total'),
    isGeneratingPDF: state.partnershipFormAnswers.get('isGeneratingPDF'),
    page: page_daily_logs,
    limit,
    order,
    filter: {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, careGuideId, page, limit, order, filter, cb) => {
      dispatch(
        loadPartnershipFormAnswers(
          partnershipId,
          careGuideId,
          page,
          limit,
          order,
          filter,
          cb
        )
      );
    },
    generatePDF: (partnershipId, careGuideId, id, cb) => {
      dispatch(
        generatePartnershipFormAnswerPDF(partnershipId, careGuideId, id, cb)
      );
    },
  };
};

const DailyLogsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyLogs);

export default DailyLogsContainer;
