import React, { useEffect, useState } from 'react';
import { Translation, getI18n } from 'react-i18next';

import { Table } from 'antd';
import { FaRegTrashAlt } from 'react-icons/fa';
import ResizableTitle from '../../careGuideOwners/components/ResizableTitle';
import QueryString from '../../../../../lib/QueryString';
import { pathTo } from '../../../Routes';
import { getTableColumns } from './CareGuideDocumentsTableColumns';
import { withRouter } from 'react-router';

import notification from '../../../elements/lib/NotificationWrapper';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';

const CareGuideDocumentsTable = ({
  component,
  page,
  limit,
  order,
  filter,
  total,
  history,
  load,
  careGuideId,
  showForm,
  showPreview,
  remove,
  patch,
  ...props
}) => {
  const modalTexts = {
    title: getI18n().t('care_guide_document_delete_modal_title'),
    description: getI18n().t('care_guide_document_delete_modal_description'),
    saveText: getI18n().t('care_guide_document_delete_modal_cancel'),
    leaveText: getI18n().t('care_guide_document_delete_modal_delete'),
  };

  const filterString = JSON.stringify(filter);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);

  const archiveDoc = (path, row) => {
    const { id } = row;
    patch(careGuideId, id, { status: 3 }, (success) => {
      if (success) {
        load(careGuideId, page, limit, order, filter);
        notification.success(
          getI18n().t('care_guide_documents_delete_success')
        );
      } else {
        notification.error(
          getI18n().t('care_guide_document_message_form_failure_delete')
        );
      }
    });
  };

  const openDeleteConfirmationModal = (path, row) => {
    setDocumentToDelete({ path, row });
    setConfirmationModalOpen(true);
  };

  const deleteDocumentConfirm = () => {
    const { path, row } = documentToDelete;
    archiveDoc(path, row);
    setConfirmationModalOpen(false);
    setDocumentToDelete(null);
  };

  const deleteDocumentCancel = () => {
    setConfirmationModalOpen(false);
    setDocumentToDelete(null);
  };

  const [columns, setColumns] = useState(
    getTableColumns(order, showForm, openDeleteConfirmationModal, handlePinned)
  );

  function handlePinned(id, is_pinned) {
    const payload = { is_pinned: !is_pinned };
    const pinStatus = is_pinned ? 'unpinned' : 'pinned';

    patch(careGuideId, id, payload, (success) => {
      if (success) {
        notification.success(
          getI18n().t('care_guide_document_message_pin_success', {
            pinStatus,
          })
        );
      } else {
        notification.error(
          getI18n().t('care_guide_document_message_form_failure_update')
        );
      }
    });
    load(careGuideId, page, limit, order, JSON.parse(filterString));
  }

  const pushToHistory = (params, path) => {
    const toPath = path ?? props.location.pathname;
    history.push(QueryString.append(toPath, params));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let path = props.location.pathname;
    const params = {};

    // handle pagination
    if ('current' in pagination && pagination['current']) {
      path = pathTo(component, {
        id: careGuideId,
        page: pagination['current'],
        limit: pagination['pageSize'],
      });
    }

    // handle sorting
    if ('field' in sorter && 'order' in sorter) {
      if (sorter['field'] && sorter['order']) {
        const orderUserProfilePartnership =
          sorter['field'] +
          '.' +
          (sorter['order'] === 'ascend' ? 'asc' : 'desc');
        params['order'] = orderUserProfilePartnership;
      }
    }

    // handle filters
    if (filters) {
      for (const key in filters) {
        if (filters[key]) {
          params[key] = filters[key].join(',');
        }
      }
    }

    pushToHistory({ ...params, ...filter }, path);
  };

  const getPageSizeOptions = (total) => {
    const options = ['10', '20'];
    if (total > 20) options.push('50');
    if (total > 50) options.push('100');
    return options;
  };

  const pagination = {
    current: page,
    pageSize: limit,
    total: total,
    showSizeChanger: total > 10,
    position: ['bottomCenter'],
    pageSizeOptions: getPageSizeOptions(total),
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      setColumns((prevColumns) => {
        const nextColumns = [...prevColumns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const resizableColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  const tableComponents = {
    header: {
      cell: ResizableTitle,
    },
  };

  useEffect(() => {
    load(careGuideId, page, limit, order, JSON.parse(filterString));
  }, [careGuideId, page, limit, order, filterString, load]);

  return (
    <Translation>
      {(t) => (
        <>
          <Table
            className="care-guide-files-table members-list-table"
            components={tableComponents}
            columns={resizableColumns}
            dataSource={props.list}
            loading={props.isLoading}
            pagination={total > 10 && pagination}
            scroll={{ x: 1320, y: 555 }}
            onChange={handleTableChange}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  if (!event.target.closest('.ant-cascader-menu')) {
                    showPreview(record.id);
                  }
                },
                style: { cursor: 'pointer' },
              };
            }}
          />
          <ConfirmationModal
            icon={<FaRegTrashAlt style={{ width: '27px' }} />}
            texts={modalTexts}
            openConfirmationModal={confirmationModalOpen}
            onLeaveConfirmation={deleteDocumentConfirm}
            onSaveConfirmation={deleteDocumentCancel}
            onCancel={deleteDocumentCancel}
          />
        </>
      )}
    </Translation>
  );
};

export default withRouter(CareGuideDocumentsTable);
