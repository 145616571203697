import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import DashboardEventInvitationsList from '../components/DashboardEventInvitationsList';
import { schema } from '../../../../../state/schema';
import {
  loadCalendarParticipantEvents,
  respondCalendarParticipantEvent,
} from '../../../../../state/modules/calendar/actions';

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;
  const start = ownProps.start || null;
  const end = ownProps.end || null;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const profileId = state.session.get('profileId');

  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const period = start + '.' + end;
  const result = state.calendar.getIn([
    'participantEvents',
    careGuideId,
    period,
    order,
    limit,
    page,
  ]);
  const list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['calendarEvents', x]),
              schema.calendarEvent,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];
  for (const e of list) {
    e.participantId = e.participants.filter(
      (x) => x.profile.id === profileId
    )[0]['id'];
  }

  return {
    careGuide,
    list: list,
    total: state.calendar.getIn(['participantEvents', careGuideId, 'total'], 0),
    isLoading: state.calendar.get('areParticipantEventsLoading'),
    start: start,
    end: end,
    page: page,
    limit: limit,
    order: order,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careGuideId, start, end, page, limit, order, cb) => {
      dispatch(
        loadCalendarParticipantEvents(
          careGuideId,
          start,
          end,
          page,
          limit,
          order,
          { public_status: 1 },
          cb
        )
      );
    },
    respond: (id, data, cb) => {
      dispatch(respondCalendarParticipantEvent(id, data, cb));
    },
  };
};

const DashboardEventInvitationsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardEventInvitationsList);

export default DashboardEventInvitationsListContainer;
