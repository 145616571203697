import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import DashboardAdminSections from '../components/DashboardAdminSections';
import store from '../../../../../state/store';

import {
  loadDashboardSections,
  patchDashboardSection,
} from '../../../../../state/modules/dashboardSections/actions';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  const partnershipId = storage.get('local', 'partnershipId');
  const page = 1;
  const limit = 10;
  const order = 'order.asc';

  return {
    page,
    limit,
    order,
    partnershipId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (partnershipId, page, limit, order, cb) => {
      dispatch(
        loadDashboardSections(
          partnershipId,
          page,
          limit,
          order,
          (success, result) => {
            if (success && result) {
              const state = store.getState(); // Obtén el estado actual desde el store

              // Normaliza el resultado
              const normalizedResult = result.map((x) => {
                return {
                  key: x,
                  ...denormalize(
                    state.entities.getIn(['dashboardSections', x]),
                    schema.dashboardSection,
                    state.entities.toJS()
                  ),
                };
              });

              // Llama al callback original con el resultado normalizado
              cb(true, normalizedResult);
            } else {
              cb(false, null); // En caso de error o fallo
            }
          }
        )
      );
    },
    patch: (partnershipId, id, data, cb) => {
      dispatch(patchDashboardSection(partnershipId, id, data, cb));
    },
  };
};

const DashboardAdminSectionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAdminSections);

export default DashboardAdminSectionsContainer;
