import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Typography, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Logger from '../../../../../lib/Logger';

const InviteButton = ({
  count,
  limit,
  canInvite,
  isLoading,
  showForm,
  showCount = false,
  text = null,
  style = null,
  ...props
}) => {
  return (
    <Translation>
      {(t) => (
        <>
          {showCount && Number.isInteger(count) && Number.isInteger(limit) ? (
            <Space>
              <Button
                icon={<PlusOutlined />}
                onClick={() => showForm()}
                disabled={!canInvite}
                style={style}
              >
                {text ? text : t('care_guide_invitations_btn_invite')}
              </Button>
              <Typography.Text type="secondary">
                {t('care_guide_invitations_remaining', {
                  count: limit > count ? limit - count : 0,
                })}
              </Typography.Text>
            </Space>
          ) : (
            <Button
              icon={<PlusOutlined />}
              onClick={() => showForm()}
              disabled={!canInvite}
              style={style}
            >
              {text ? text : t('care_guide_invitations_btn_invite')}
            </Button>
          )}
        </>
      )}
    </Translation>
  );
};

export default InviteButton;

Logger.log('silly', `InviteButton loaded.`);
