import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import { List } from 'antd';

import { TelephoneDisplay } from '../../../elements/components/TelephoneDisplay';
import { MessageLink } from '../../messages/components/MessageLink';
import Logger from '../../../../../lib/Logger';

const CareGuideHelpersQuickList = ({
  careGuideId,
  component,
  page,
  limit,
  order,
  total,
  load,
  ...props
}) => {
  useEffect(() => {
    load(careGuideId, page, limit, order);
  }, [careGuideId, page, limit, order, load]);

  return (
    <Translation>
      {(t) => (
        <List
          className="care-guide-helpers-quick-list"
          size="small"
          itemLayout="horizontal"
          dataSource={props.list}
          renderItem={(item) =>
            item.role !== 4 &&
            item.role !== 5 && (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={item.profile_name}
                  description={item.tags ? item.tags.join(', ') : null}
                />

                <ul className="contact-info">
                  {item.telephone ? (
                    <li>
                      <TelephoneDisplay telephone={item.telephone} />
                    </li>
                  ) : null}
                  {!item.is_self ? (
                    <li>
                      <MessageLink
                        profile={item.profile}
                        careGuideId={careGuideId}
                      />
                    </li>
                  ) : null}
                </ul>
              </List.Item>
            )
          }
        />
      )}
    </Translation>
  );
};

export default CareGuideHelpersQuickList;

Logger.log('silly', `CareGuideHelpersQuickList loaded.`);
