import { connect } from 'react-redux';
import DashboardAdminScreen from '../components/DashboardAdminScreen';
import storage from '../../../../../lib/Storage';

const mapStateToProps = (state, ownProps) => {
  return {
    activeId: storage.get('local', 'partnershipId'),
    access_role_admin_in_app: storage.get('local', 'partnershipData')
      ?.access_role_admin_in_app,
  };
};

const DashboardAdminScreenContainer =
  connect(mapStateToProps)(DashboardAdminScreen);

export default DashboardAdminScreenContainer;
