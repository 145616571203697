import React, { useEffect } from 'react';
import { Translation, getI18n } from 'react-i18next';
import { Button, Card, List, Typography, Tag } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

import { pathTo } from '../../../Routes';
import message from '../../../elements/lib/MessageWrapper';
import Logger from '../../../../../lib/Logger';

const { Title } = Typography;

const DashboardCareGuideInvitationsQuickList = ({
  careGuideId,
  component,
  page,
  limit,
  order,
  total,
  load,
  isLoading,
  isResending,
  ...props
}) => {
  useEffect(() => {
    load(careGuideId, page, limit, order);
  }, [careGuideId, page, limit, order, load]);

  const onClickResend = (invitationId) => {
    const payload = {};

    // create URL templates for invitation
    payload['url_template_register'] =
      window.location.href.split('/').slice(0, 3).join('/') +
      pathTo('RegisterStep1Screen') +
      '?invite_code={invite_code}&invite_id={invite_id}';
    payload['url_template_accept'] =
      window.location.href.split('/').slice(0, 3).join('/') +
      pathTo('CareGuideInvitationAccept', { invitationId: '000' }).replace(
        /000/,
        '{invite_id}'
      ) +
      '?invite_code={invite_code}';

    props.resend(careGuideId, invitationId, payload, (success) => {
      if (success) {
        message.success(getI18n().t('care_guide_invitations_resend_success'));
        load(careGuideId, page, limit, order);
      } else {
        message.error(getI18n().t('care_guide_invitations_resend_error'));
      }
    });
  };

  const statuses = {
    1: {
      color: 'green',
      label: 'care_guide_invitations_public_status_accepted',
    },
    2: {
      color: 'red',
      label: 'care_guide_invitations_public_status_rejected',
    },
    3: {
      color: 'blue',
      label: 'care_guide_invitations_public_status_pending',
    },
    4: {
      color: 'orange',
      label: 'care_guide_invitations_public_status_expired',
    },
  };

  return (
    <Translation>
      {(t) => (
        <>
          {total > 0 ? (
            <Card bordered={false} className="dashboard-invitations-quick-list">
              <Title level={4}>
                {t('dashboard_care_guide_invited_list_title')}
              </Title>

              <List
                className="event-invitations-list"
                itemLayout="vertical"
                dataSource={props.list}
                renderItem={(item) => (
                  <div className={`event-invitations-list-item`}>
                    <List.Item key={item.id}>
                      <List.Item.Meta
                        title={item.email_address}
                        description={item.tags ? item.tags.join(', ') : null}
                      />

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {item.invitation &&
                        item.invitation.public_status &&
                        item.invitation.public_status in statuses ? (
                          <Tag
                            color={
                              statuses[item.invitation.public_status].color
                            }
                          >
                            {t(statuses[item.invitation.public_status].label)}
                          </Tag>
                        ) : null}
                        <Button
                          key="resend"
                          size="small"
                          icon={<RedoOutlined />}
                          onClick={() => onClickResend(item.invitation.id)}
                          loading={isResending}
                          type="primary"
                          style={{ borderRadius: '2px', marginRight: '6px' }}
                        >
                          {t('care_guide_invitations_button_resend')}
                        </Button>
                      </div>
                    </List.Item>
                  </div>
                )}
              />
            </Card>
          ) : null}
        </>
      )}
    </Translation>
  );
};

export default DashboardCareGuideInvitationsQuickList;

Logger.log('silly', `DashboardCareGuideInvitationsQuickList loaded.`);
