import React, { useEffect } from 'react';
import { Button, Typography, Card, List, Row, Col, Avatar } from 'antd';
import { Link } from 'react-router-dom';

import { Translation } from 'react-i18next';
import { EyeOutlined } from '@ant-design/icons';
import Format from '../../../../../lib/Format';
import UserAvatar from '../../../elements/components/UserAvatar';
import { calendar_events } from '../../../mock/data';
import { pathTo } from '../../../Routes';
import EventModal from '../containers/EventModalContainer';

const { Title, Text } = Typography;

const DashboardUpcomingEvents = ({
  careGuideId,
  component,
  start,
  end,
  page,
  limit,
  order,
  total,
  load,
  isLoading,
  showDetail,
  list,
  mock = false,
  ...props
}) => {
  useEffect(() => {
    if (mock === false) {
      load(careGuideId, start, end, page, limit, order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careGuideId, start, end, page, limit, order, load]);

  const onClick = (id, view) => {
    showDetail(id, view);
  };
  return (
    <Translation>
      {(t) =>
        ((total > 0 && mock === false) || mock) && (
          <>
            <Card bordered={false} className="event-upcoming-card">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Title level={4}>
                  {t('dashboard_admin_upcoming_events_title')}
                </Title>
                {mock ? (
                  <Button type="primary" icon={<EyeOutlined />}>
                    {t('dashboard_admin_upcoming_events_btn_view')}
                  </Button>
                ) : (
                  <Button type="primary" icon={<EyeOutlined />}>
                    <Link
                      to={pathTo('CalendarScreen', { id: careGuideId })}
                      style={{ color: 'white', marginLeft: '6px' }}
                    >
                      {t('dashboard_admin_upcoming_events_btn_view')}
                    </Link>
                  </Button>
                )}
              </div>

              <List
                itemLayout="horizontal"
                size="small"
                bordered={false}
                dataSource={mock ? calendar_events : list}
                renderItem={(item) => (
                  <div
                    className={`event-upcoming-item ${mock === false && 'cursor-pointer'
                      }`}
                    onClick={(e) => (mock ? null : onClick(item.id, true))}
                  >
                    <Row
                      align="top"
                      justify="space-between"
                      style={{
                        display: 'flex',
                        height: '20px',
                        width: '100%',
                      }}
                    >
                      <Col style={{ flex: '1', overflow: 'hidden' }}> 
                        <Title
                          ellipsis={true}
                          level={5}
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.title}
                        </Title>
                      </Col>
                      <Col style={{ flexShrink: 0 }}>
                        {item.care_guide_id === null ? (
                          <UserAvatar profile={item.profile} size={24} />
                        ) : (
                          <div className="event-content">
                            <Avatar.Group
                              maxCount={3}
                              maxStyle={{
                                color: '#f56a00',
                                backgroundColor: '#fde3cf',
                              }}
                            >
                              {item.participants
                                ? item.participants.map((x, i) => (
                                  <UserAvatar
                                    key={i}
                                    profile={x.profile}
                                    size={24}
                                  />
                                ))
                                : null}
                            </Avatar.Group>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row align="top">
                      <Col>
                        <Text style={{ color: '#676767' }}>
                          {
                            item.is_all_day
                              ? Format.date(item.start_at, 'ddd, MMM D') // all day event
                              : Format.date(item.start_at, 'YYYY-MM-DD') ===
                                Format.date(item.end_at, 'YYYY-MM-DD')
                                ? Format.date(item.start_at, 'ddd, MMM D h:mm a') // event occurs on a single day
                                : Format.date(item.start_at, 'YYYY-MM') ===
                                  Format.date(item.end_at, 'YYYY-MM')
                                  ? Format.date(item.start_at, 'MMM D') +
                                  '-' +
                                  Format.date(item.end_at, 'D') // event occurs over multiple days, same month
                                  : Format.date(item.start_at, 'MMM D') +
                                  '-' +
                                  Format.date(item.end_at, 'MMM D') // event occurs over multiple days, different month
                          }
                        </Text>
                      </Col>
                    </Row>
                  </div>
                )}
              />
            </Card>
            {mock === false && <EventModal />}
          </>
        )
      }
    </Translation>
  );
};

export default DashboardUpcomingEvents;
