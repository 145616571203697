import React from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { emailIcon } from '../../../elements/components/Icons';
import { Space } from 'antd';
import { pathTo } from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';

const MessageLink = ({ careGuideId, profile, key = null, ...props }) => {
  if (profile) {
    return (
      <Translation>
        {(t) => (
          <div className="message-link" key={key}>
            <Link
              to={QueryString.append(pathTo('MessagesInboxScreen'), {
                composeThreadId: careGuideId,
                composeProfileId: profile.id,
              })}
            >
              <MailOutlined />
              {t('messages_action_send_message')}
            </Link>
          </div>
        )}
      </Translation>
    );
  }
  return null;
};

const MessageLinkCard = ({ careGuideId, profile, key = null, ...props }) => {
  if (profile) {
    return (
      <Translation>
        {(t) => (
          <div className="message-link" key={key}>
            <Link
              to={QueryString.append(pathTo('MessagesInboxScreen'), {
                composeThreadId: careGuideId,
                composeProfileId: profile.id,
              })}
            >
              <Space style={{ display: 'flex', alignItems: 'center' }}>
                {emailIcon()}
                <span>{t('messages_action_send_message')}</span>
              </Space>
            </Link>
          </div>
        )}
      </Translation>
    );
  }
  return null;
};

export { MessageLink, MessageLinkCard };
