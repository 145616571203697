import DashboardResources from '../components/DashboardResources';
import { schema } from '../../../../../state/schema';
import { denormalize } from 'normalizr';

import {
  loadResourcesDashboard,
  resourceShowPreviewDetail,
} from '../../../../../state/modules/resources/actions';

import { connect } from 'react-redux';

const mapStateToProps = (state, ownProps) => {
  const order = 'is_pinned_dashboard.asc';
  const page = 1;
  const limit = 6;

  const partnershipId = ownProps.partnershipId;
  const careGuideId = ownProps.careGuideId;

  const result = state.resources.getIn([
    'pages',
    partnershipId,
    careGuideId,
    order,
    limit,
    page,
  ]);

  const list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['resources', x]),
              schema.resource,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];
  return {
    page,
    order,
    limit,
    partnershipId,
    list,
    isLoading: state.resources.get('isLoading'),
    total: state.resources.get('total') ?? 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careGuideId, partnershipId, page, limit, order, cb) => {
      dispatch(
        loadResourcesDashboard(
          careGuideId,
          partnershipId,
          page,
          limit,
          order,
          cb
        )
      );
    },
    showDetail: (resourceId) => {
      dispatch(resourceShowPreviewDetail(resourceId));
    },
  };
};

const DashboardResourcesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardResources);

export default DashboardResourcesContainer;
