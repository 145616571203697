import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import { resourceShowForm } from '../../../../../state/modules/resources/actions';
import ResourcesScreen from '../components/ResourcesScreen';
import storage from '../../../../../lib/Storage';
import {
  careGuideSetActive,
  careGuideSetAccess,
} from '../../../../../state/modules/careGuides/actions';

const mapStateToProps = (state, ownProps) => {
  const page = parseInt(
    ownProps.match.params.page ? ownProps.match.params.page : 1
  );
  const limit = 20;
  const activeId = state.careGuides.get('activeId') ?? ownProps.match.params.id;

  const careGuide = activeId
    ? denormalize(
        state.entities.getIn(['careGuides', activeId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  return {
    isLoading: state.resources.get('isLoading'),
    isGeneratingPDF: state.resources.get('isGeneratingPDF'),
    page: page,
    limit: limit,
    careGuide: careGuide,
    careGuideId: activeId,
    partnershipId: storage.get('local', 'partnershipId'),
    isCareGuideLoading: careGuide === undefined ? true : false,
    isCareGuideActive: careGuide?.account?.is_subscription_valid,
    careGuideParnetshipStatus: careGuide?.partnership?.status,
    accessCareGuide: state.careGuides.get('accessCareGuide'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showForm: (resourceId) => {
      dispatch(resourceShowForm(resourceId));
    },
    setActive: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setActiveAccess: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  };
};

const ResourcesScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesScreen);

export default ResourcesScreenContainer;
