import React, { useEffect } from 'react';
import { Typography, Card, List, Row, Col, Space } from 'antd';
import { Translation } from 'react-i18next';
import UserAvatar from '../../../elements/components/UserAvatar';
import { TelephoneDisplayCard } from '../../../elements/components/TelephoneDisplay';
import { MessageLinkCard } from '../../messages/components/MessageLink';
import { care_members } from '../../../mock/data';
import InviteButtonCareGuideHelpers from '../../careGuideInvitations/containers/InviteButtonCareGuideHelpersContainer';

import hasPermission, {
  CREATE_CARE_GUIDE_INVITATION,
} from '../../../elements/lib/hasPermission';

const { Title, Text } = Typography;

const DashboardCareHelpers = ({
  careGuideId,
  component,
  page,
  limit,
  order,
  total,
  load,
  list,
  mock = false,
  ...props
}) => {
  useEffect(() => {
    if (mock === false) {
      load(careGuideId, page, limit, order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careGuideId, page, limit, order, load]);

  return (
    <Translation>
      {(t) =>
        ((total > 0 && mock === false) || mock) && (
          <Card bordered={false} className="care-members-card">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Title level={4}>{t('dashboard_admin_care_members_title')}</Title>

              {(hasPermission(CREATE_CARE_GUIDE_INVITATION) || mock) && (
                <InviteButtonCareGuideHelpers
                  mock={mock}
                  careGuideId={careGuideId}
                />
              )}
            </div>
            <List
              itemLayout="horizontal"
              size="small"
              bordered={false}
              dataSource={mock ? care_members : list}
              renderItem={(item) =>
                item.role !== 4 &&
                item.role !== 5 && (
                  <div className={`care-member-item`}>
                    <Row>
                      <Col style={{ marginRight: '10px' }}>
                        <UserAvatar profile={item.profile} size={30} />
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <Space direction="horizontal">
                              <Title level={5} style={{ display: 'inline' }}>
                                {item.profile
                                  ? item.profile.first_name +
                                    ' ' +
                                    item.profile.last_name
                                  : ''}
                              </Title>
                              <Text
                                style={{ fontSize: '16px', color: '#676767' }}
                              >
                                -
                              </Text>
                              <Text
                                style={{ fontSize: '16px', color: '#676767' }}
                              >
                                {item.is_owner
                                  ? t('care_guide_helpers_role_owner')
                                  : t('care_guide_helpers_role_' + item.role)}
                              </Text>
                            </Space>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                          {item.telephone && (
                            <Col style={{ marginRight: '10px' }}>
                              <TelephoneDisplayCard
                                telephone={'877-881-094'}
                                onClick={(e) => {}}
                              />
                            </Col>
                          )}
                          {!item.is_self && (
                            <Col>
                              <MessageLinkCard
                                profile={item.profile}
                                careGuideId={careGuideId}
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )
              }
            />
          </Card>
        )
      }
    </Translation>
  );
};

export default DashboardCareHelpers;
