import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

export const TYPES = {
  LIST_REQUEST: 'RECOMMENDED_UTILITIES/LIST_REQUEST',
  LIST_SUCCESS: 'RECOMMENDED_UTILITIES/LIST_SUCCESS',
  LIST_FAILURE: 'RECOMMENDED_UTILITIES/LIST_FAILURE',
  DOCUMENT_DOWNLOAD_REQUEST: 'MEDIA/DOCUMENT_DOWNLOAD_REQUEST',
  DOCUMENT_DOWNLOAD_SUCCESS: 'MEDIA/DOCUMENT_DOWNLOAD_SUCCESS',
  DOCUMENT_DOWNLOAD_FAILURE: 'MEDIA/DOCUMENT_DOWNLOAD_FAILURE',
};

export function recommendedUtilityListRequest(
  partnershipId,
  careGuideId,
  page,
  limit,
  order
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListRequest(${careGuideId}, ${partnershipId}, ${page}, ${limit}, ${order})`
  );
  return {
    type: TYPES.LIST_REQUEST,
    careGuideId: careGuideId,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
  };
}

export function recommendedUtilityListSuccess(
  partnershipId,
  careGuideId,
  data
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListSuccess(${careGuideId}, ${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    careGuideId: careGuideId,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function recommendedUtilityListFailure(
  partnershipId,
  careGuideId,
  error
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityListFailure(${careGuideId}, ${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    careGuideId: careGuideId,
    partnershipId: partnershipId,
    error: error,
  };
}

export function recommendedUtilityDownloadRequest() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadRequest()`
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_REQUEST,
  };
}

export function recommendedUtilityDownloadSuccess() {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadSuccess()`
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_SUCCESS,
  };
}

export function recommendedUtilityDownloadFailure(error) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] recommendedUtilityDownloadFailure(%j)`,
    error
  );
  return {
    type: TYPES.DOCUMENT_DOWNLOAD_FAILURE,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadRecommendedUtilities(
  partnershipId = null,
  careGuideId = null,
  page = 1,
  limit = 15,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] loadRecommendedUtilities(${partnershipId}, ${careGuideId}, ${page}, ${limit}, ${order})`
  );
  return async function (dispatch) {
    dispatch(
      recommendedUtilityListRequest(
        partnershipId,
        careGuideId,
        page,
        limit,
        order
      )
    );

    // call API
    const response = await api.getRecommendedUtilities(
      partnershipId,
      careGuideId,
      page,
      limit,
      order
    );
    let success = false;

    // get recommended utilities list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities list success. Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'recommended_utilities']),
        [schema.recommendedUtility]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(recommendedUtilityListSuccess(partnershipId, careGuideId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API recommended utilities list success [empty].  Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };

      dispatch(recommendedUtilityListSuccess(partnershipId, careGuideId, data));
      success = true;

      // get recommended utilities list failure
    } else {
      Logger.log(
        'info',
        `Get API recommended utilities list failure.  Partnership ID: ${partnershipId}, Care Guide ID: ${careGuideId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(
        recommendedUtilityListFailure(
          partnershipId,
          careGuideId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

export function downloadDocument(path, fileName, cb = function () {}) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.actions] downloadDocument(${path}, ###)`
  );

  return async function (dispatch) {
    dispatch(recommendedUtilityDownloadRequest());

    // call API
    const response = await api.createGetPresignedUrlAWS(path, fileName);
    let success = false;

    let url = null;

    // create presigned url success
    if (response['success']) {
      Logger.log('info', `Create presigned URL success.`);
      success = true;

      url = response['presignedUrl'];

      dispatch(recommendedUtilityDownloadSuccess());

      // create presigned url failure
    } else {
      Logger.log('info', `Create presigned URL failure.`);
      dispatch(recommendedUtilityDownloadFailure('Download error.'));
    }

    // callback function
    cb(success, url);
  };
}

Logger.log('silly', `state.recommendedUtilities.actions loaded.`);
