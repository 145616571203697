import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { Col, Row } from 'antd';

import CareGuideDisabled from '../../careGuides/components/CareGuideDisabled';
import CareGuideWrapper from '../../../layouts/components/CareGuideWrapper';
import DocumentHead from '../../../elements/components/DocumentHead';
import CareGuideInvitationForm from '../../careGuideInvitations/containers/CareGuideInvitationFormContainer';
import InvitationsList from '../../careGuideInvitations/containers/InvitationsListContainer';
import Loading from '../../../elements/components/Loading';
import scrollToTop from '../../../elements/lib/scrollToTop';

import DashboardDetail from './DashboardDetail';
import DashboardDetailPartnership from '../containers/DashboardDetailPartnershipContainer';
import hasPermission, {
  VIEW_ACCESS_CARE_GUIDE,
} from '../../../elements/lib/hasPermission';

import { pathTo } from '../../../Routes';
import Config from '../../../../../Config';
import Logger from '../../../../../lib/Logger';
import { Redirect } from 'react-router-dom';

class DashboardDetailScreen extends Component {
  static defaultProps = {
    limit: Config.get('DEFAULT_LIST_LENGTH', 10),
    areJournalsLoading: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      journalFormIsVisible: false,
    };
  }

  toggleJournalForm = () => {
    this.setState({
      journalFormIsVisible: !this.state.journalFormIsVisible,
    });
  };

  render() {
    const {
      careGuideId,
      page,
      limit,
      areJournalsLoading,
      isCareGuideActive,
      isCareGuideLoading,
      partnership,
    } = this.props;

    if (isCareGuideLoading) {
      return (
        <Translation>
          {(t) => (
            <CareGuideWrapper>
              <DocumentHead title={t('route_dashboard')} />
              <Loading />
            </CareGuideWrapper>
          )}
        </Translation>
      );
    }

    if (isCareGuideActive === null) {
      return <Redirect to={pathTo('HomeScreen')} />;
    }

    if (!isCareGuideActive) {
      return (
        <Translation>
          {(t) => (
            <CareGuideWrapper>
              <DocumentHead title={t('route_dashboard')} />

              <Row gutter={16}>
                <Col
                  lg={{ span: 16, order: 1 }}
                  md={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  xs={{ span: 24, order: 2 }}
                  className="dashboard-content"
                >
                  <CareGuideDisabled careGuideId={careGuideId} />
                </Col>
                {!partnership && (
                  <Col
                    lg={{ span: 8, order: 2 }}
                    md={{ span: 24, order: 1 }}
                    sm={{ span: 24, order: 1 }}
                    xs={{ span: 24, order: 1 }}
                    className="content-sidebar"
                  >
                    <InvitationsList
                      page={1}
                      limit={5}
                      order={'invited_at.desc'}
                    />
                  </Col>
                )}
              </Row>
            </CareGuideWrapper>
          )}
        </Translation>
      );
    }

    return (
      <Translation>
        {(t) => (
          <CareGuideWrapper>
            <DocumentHead title={t('route_dashboard')} />

            {partnership ? (
              <DashboardDetailPartnership
                careGuideId={careGuideId}
                partnershipId={partnership.id}
                partnership={partnership}
              />
            ) : (
              <DashboardDetail
                careGuideId={careGuideId}
                areJournalsLoading={areJournalsLoading}
                page={page}
                limit={limit}
              />
            )}
            <CareGuideInvitationForm careGuideId={careGuideId} />
          </CareGuideWrapper>
        )}
      </Translation>
    );
  }

  /* LIFECYCLE EVENTS */

  componentDidMount() {
    this.props.load(this.props.match.params.id);
    scrollToTop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isCareGuideActive) {
      if (this.props.careGuide?.partnership !== prevProps.partnershipActiveId) {
        if (this.props.careGuide.partnership) {
          this.props.setActiveAccess({
            access_role_admin_in_care_guide:
              this.props.careGuide.partnership.access_role_admin_in_care_guide,
          });
          if (this.props.accessCareGuide) {
            if (hasPermission(VIEW_ACCESS_CARE_GUIDE)) {
              if (!this.props.accessCareGuide?.dashboard) {
                this.setState({ redirect: 'HomeScreen' });
              }
            }
          }
        }
      }
    }
  }
}

export default DashboardDetailScreen;

Logger.log('silly', `DashboardDetailScreen loaded.`);
