import { normalize } from 'normalizr';

import api from '../../api';
import { schema } from '../../schema';
import { addEntities } from '../../actions';
import Logger from '../../../lib/Logger';

// ACTION TYPES
export const TYPES = {
  LIST_REQUEST: 'DASHBOARD_SECTION/LIST_REQUEST',
  LIST_SUCCESS: 'DASHBOARD_SECTION/LIST_SUCCESS',
  LIST_FAILURE: 'DASHBOARD_SECTION/LIST_FAILURE',
  PATCH_REQUEST: 'DASHBOARD_SECTION/PATCH_REQUEST',
  PATCH_SUCCESS: 'DASHBOARD_SECTION/PATCH_SUCCESS',
  PATCH_FAILURE: 'DASHBOARD_SECTION/PATCH_FAILURE',
};

// ACTION CREATORS

export function dashboardSectionListRequest(partnershipId, page, limit, order) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionListRequest(
    ${partnershipId},${page}, ${limit}, ${order})`
  );
  return {
    type: TYPES.LIST_REQUEST,
    partnershipId: partnershipId,
    page: page,
    limit: limit,
    order: order,
  };
}

export function dashboardSectionListSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionListSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.LIST_SUCCESS,
    partnershipId: partnershipId,
    page: data.page,
    limit: data.limit,
    order: data.order,
    result: data.result,
    total: data.total,
    receivedAt: Date.now(),
  };
}

export function dashboardSectionListFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionListFailure(${partnershipId}, %j)`,
    error
  );
  return {
    type: TYPES.LIST_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

export function dashboardSectionPatchRequest(partnershipId, id, data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionPatchRequest(${partnershipId}, ${id}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_REQUEST,
    partnershipId: partnershipId,
  };
}

export function dashboardSectionPatchSuccess(partnershipId, data) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionPatchSuccess(${partnershipId}, %j)`,
    data
  );
  return {
    type: TYPES.PATCH_SUCCESS,
    id: data.id,
    partnershipId: partnershipId,
    receivedAt: Date.now(),
  };
}

export function dashboardSectionPatchFailure(partnershipId, error) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] dashboardSectionPatchFailure(%j)`,
    error
  );
  return {
    type: TYPES.PATCH_FAILURE,
    partnershipId: partnershipId,
    error: error,
  };
}

// API THUNK ACTION CREATORS

export function loadDashboardSections(
  partnershipId = null,
  page = 1,
  limit = 10,
  order = null,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] loadDashboardSections(${partnershipId}, ${page}, ${limit}, ${order}, ###)`
  );

  return async function (dispatch) {
    dispatch(dashboardSectionListRequest(partnershipId, page, limit, order));

    // call API
    const response = await api.getDashboardSections(
      partnershipId,
      page,
      limit,
      order
    );
    let success = false;
    let result = [];

    // get dashboard sections list success
    if (200 === response.get('status')) {
      Logger.log(
        'info',
        `Get API dashboard sections list success. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );

      const normalizedEntities = normalize(
        response.getIn(['data', 'dashboard_sections']),
        [schema.dashboardSection]
      );
      const data = {
        page: response.getIn(['data', 'page']),
        limit: response.getIn(['data', 'limit']),
        order: order,
        total: response.getIn(['data', 'total']),
        result: normalizedEntities.result,
      };

      result = normalizedEntities.result;
      dispatch(addEntities(normalizedEntities));
      dispatch(dashboardSectionListSuccess(partnershipId, data));
      success = true;
    } else if (1 === page && 204 === response.get('status')) {
      Logger.log(
        'info',
        `Get API dashboard sections success [empty]. Partnership ID: ${partnershipId}, Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      const data = {
        page: page,
        limit: limit,
        order: order,
        total: 0,
        result: [],
      };
      dispatch(dashboardSectionListSuccess(partnershipId, data));
      success = true;

      // get dashboard sections list failure
    } else {
      Logger.log(
        'info',
        `Get API dashboard sections list failure. Partnership ID: ${partnershipId} Page: ${page}, Limit: ${limit}, Order: ${order}.`
      );
      dispatch(dashboardSectionListFailure(response.getIn(['data', 'error'])));
    }

    // callback function
    cb(success, result);
  };
}

export function patchDashboardSection(
  partnershipId = null,
  id,
  data,
  cb = function () {}
) {
  Logger.log(
    'debug',
    `[dashboardSections.actions] updateDashboardSection(${partnershipId}, ${id}, %j, ###)`,
    data
  );

  return async function (dispatch) {
    dispatch(dashboardSectionPatchRequest(partnershipId, id, data));

    // call API
    const response = await api.patchDashboardSection(partnershipId, id, data);
    let success = false;

    // put dashboard section success
    if (200 === response.get('status')) {
      Logger.log('info', `PUT API dashboard section success. User: ${id}.`);

      const normalizedEntities = normalize(
        [response.getIn(['data', 'dashboard_section'])],
        [schema.dashboardSection]
      );
      const data = {
        id: response.getIn(['data', 'dashboard_section', 'id']),
      };

      dispatch(addEntities(normalizedEntities));
      dispatch(dashboardSectionPatchSuccess(partnershipId, data));
      success = true;

      // get dashboard section failure
    } else {
      Logger.log('info', `PUT API dashboard section failure. ID: ${id}.`);
      dispatch(
        dashboardSectionPatchFailure(
          partnershipId,
          response.getIn(['data', 'error'])
        )
      );
    }

    // callback function
    cb(success);
  };
}

Logger.log('silly', `dashboardSections.actions loaded.`);
