import Logger from './Logger';
import mixpanel from 'mixpanel-browser';
import Auth from './Auth';
import Config from '../Config';

const mixpanelEventsEnum = {
  LOGIN: {
    STARTED_LOGIN: 'Login | Started Login',
    SUCCESSFUL_LOGIN: 'Login | Successful Login',
    CREDENTIALS_FAILED: 'Login | Failed Login due to credentials',
  },
  PAGES: {
    ACCESS_CARE_GUIDE: 'Pages | Access Care Guide',
    ACCESS_PAGE: 'Pages | Access Page',
    ACCESS_PAGE_ADMINISTRATOR: 'Pages | Access Page Administrator',
  },
  RESOURCE: {
    OPEN_PREVIEW: 'Resource | Open Preview',
    TOGGLE_FAVORITE: 'Resource | Toggle Favorite',
    TOGGLE_PINNED: 'Resource | Toggle Pinned',
    TOGGLE_DISABLE: 'Resource | Toggle Disable',
    OPEN_LINK: 'Resource | Open Link',
    CREATE: 'Resource | Create',
    EDIT: 'Resource | Edit',
    DELETE: 'Resource | Delete',
    DELETES: 'Resource | Deletes',
    EXPORT_CSV: 'Resource | Export Csv',
    EXPORT_PDF: 'Resource | Export Pdf',
    EXPORT_RESOURCES_PDF: 'Resource | Export Pdf Resources',
    OPEN_NOTES: 'Resource | Open Notes',
    CREATE_NOTE: 'Resource | Create Note',
    DELETE_NOTE: 'Resource | Delete Note',
    EDIT_NOTE: 'Resource | Edit Note',
    EXPORT_NOTES_CSV: 'Resource | Export Csv Notes',
  },
  CALENDAR: {
    CREATE_EVENT: 'Calendar | Create Event',
    EDIT_EVENT: 'Calendar | Edit Event',
    DELETE_EVENT: 'Calendar | Delete Event',
    VIEW_EVENT: 'Calendar | View Event',
    RESPOND: 'Calendar | Respond Event',
  },
  CARE_GUIDE: {
    EXPORT_PDF: 'Care Guide | Export PDF',
    EXPORT_PDF_SEGMENT: 'Care Guide | Export PDF Segment',
  },
  JOURNAL: {
    CREATE_POST: 'Journal | Create Post',
    EDIT_POST: 'Journal | Edit Post',
    CREATE_REPLY_POST: 'Journal | Create Reply Post',
    EDIT_REPLY_POST: 'Journal | Edit Reply Post',
    DELETE_REPLY_POST: 'Journal | Delete Reply Post',
    EXPORT_PDF: 'Journal | Export PDF',
    CREATE_REACTION_EMOJI_POST: 'Journal | Create Reaction Emoji Post',
    DELETE_REACTION_EMOJI_POST: 'Journal | Delete Reaction Emoji Post',
  },
};

class Mixpanel {
  constructor() {
    Logger.log('debug', `Mixpanel.constructor()`);
    mixpanel.init(Config.get('MIXPANEL_TOKEN'), {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
  }

  track(eventName, props) {
    mixpanel.track(eventName, props);
  }
  identify(userId) {
    mixpanel.identify(userId);
  }
  setUserInfo(props) {
    mixpanel.people.set_once(props);
  }

  mixpanelTrack(eventDescription, customProps = {}) {
    const { authToken, userId } = Auth.getSession();
    const sessionId = authToken?.split('.')?.pop();
    this.identify(userId);
    this.track(eventDescription, {
      session_id: sessionId,
      ...customProps,
    });
  }
}

const MixpanelTracker = new Mixpanel();
export { MixpanelTracker, mixpanelEventsEnum };

Logger.log('silly', `Mixpanel loaded.`);
