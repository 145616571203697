import { List, Map } from 'immutable';

import { TYPES } from './actions';
import { SESSION_DESTROY } from '../../actions';
import Logger from '../../../lib/Logger';

const initialState = Map({
  isLoading: false,
  pages: {},
  total: 0,
  results: {},
  isFileDownloading: false,
});

export default function recommendedUtilities(state = initialState, action) {
  Logger.log(
    'debug',
    `[state.recommendedUtilities.reducers] recommendedUtilities(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.partnershipId]: {
              [action.careGuideId]: {
                [action.page]: {
                  [action.limit]: {
                    [action.order]: null,
                  },
                },
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.careGuideId,
            action.page,
            action.limit,
            action.order,
          ],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DOCUMENT_DOWNLOAD_REQUEST:
      return state.mergeDeep({
        isFileDownloading: true,
      });

    case TYPES.DOCUMENT_DOWNLOAD_SUCCESS:
    case TYPES.DOCUMENT_DOWNLOAD_FAILURE:
      return state.mergeDeep({
        isFileDownloading: false,
      });

    case SESSION_DESTROY:
      return initialState;

    default:
      return state;
  }
}

Logger.log('silly', `state.recommendedUtilities.reducers loaded.`);
