import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import DashboardCareHelpers from '../components/DashboardCareHelpers';
import { schema } from '../../../../../state/schema';
import { loadCareGuideMembers } from '../../../../../state/modules/careGuideHelpers/actions';

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;
  const page = ownProps.page;
  const limit = ownProps.limit;
  const order = ownProps.order;
  const result = state.careGuideHelpers.getIn([
    'members',
    careGuideId,
    'pages',
    order,
    limit,
    page,
  ]);
  const myProfileId = state.session.get('profileId');

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['careGuideMembers', x]),
                schema.careGuideMember,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .map((x) => {
            // flatten some nested objects
            return {
              profile_name: x.profile
                ? x.profile.first_name + ' ' + x.profile.last_name
                : '',
              telephone: x.profile ? x.profile.telephone : '',
              is_self: x.profile ? myProfileId === x.profile.id : false,
              ...x,
            };
          })
      : [],
    total: state.careGuideHelpers.getIn(['members', careGuideId, 'total']),
    isLoading: state.careGuideHelpers.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careGuideId, page, limit, order, filter, cb) => {
      dispatch(
        loadCareGuideMembers(careGuideId, page, limit, order, filter, cb)
      );
    },
  };
};

const DashboardCareHelpersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardCareHelpers);

export default DashboardCareHelpersContainer;
