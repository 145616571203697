import React, { useEffect } from 'react';
import { Translation } from 'react-i18next';
import DashboardResourcesCard from './DashboardResourcesCard';
import { Empty, Typography } from 'antd';
import ResourcePreview from '../containers/ResourcePreviewContainer';

const { Text } = Typography;

const DashboardResources = ({
  load,
  careGuideId,
  partnershipId,
  page,
  limit,
  order,
  filter,
  list,
  total,
  isLoading,
  showDetail,
  ...props
}) => {
  useEffect(() => {
    load(careGuideId, partnershipId, page, limit, order);
  }, [careGuideId, partnershipId, page, limit, order, load]);

  return (
    <Translation>
      {(t) => (
        <>
          {total > 0 ? (
            <div className="section-resource-card-list">
              {list.map((item, index) => (
                <DashboardResourcesCard
                  className="section-resource-card-dashboard cursor-pointer"
                  item={item}
                  onChange={(e) => {}}
                  addStyle={{ padding: '16px' }}
                  showDetail={showDetail.bind(this)}
                />
              ))}
            </div>
          ) : (
            <div
              style={{
                height: '260px',
                backgroundColor: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                width: '100%',
              }}
            >
              <Empty
                description={<Text>{t('section_resources_empty')}</Text>}
              />
            </div>
          )}
          <ResourcePreview hiddenNotes={true} />
        </>
      )}
    </Translation>
  );
};

export default DashboardResources;
