import React, { useEffect } from 'react';
import {
  Col,
  Row,
  Typography,
  DatePicker,
  Collapse,
  Pagination,
  Button,
  Alert,
} from 'antd';
import { Translation } from 'react-i18next';

import {
  FileOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Format from '../../../../../lib/Format';
import { pathTo } from '../../../Routes';
import QueryString from '../../../../../lib/QueryString';
import { withRouter } from 'react-router';
import moment from 'moment';
import { Empty } from 'antd';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const DailyLogsList = ({
  careGuide,
  list,
  load,
  careGuideId,
  partnershipId,
  component,
  history,
  page,
  limit,
  filter,
  order,
  total,
  setConfirmationModalOpen,
  setDailyLogId,
  generatePDF,
  isGeneratingPDF,
  waitEntries,
  setActiveKey,
  activeKey,
  showFirstKey,
  setShowFirstKey,
  ...props
}) => {
  let params = QueryString.parse(history.location.search);
  const defaultStart = 'start' in params ? moment(params['start']) : null;
  const defaultEnd = 'end' in params ? moment(params['end']) : null;

  const onChangeDateHandler = (dates) => {
    params['start'] = dates && dates[0] ? dates[0].format('YYYY-MM-DD') : '';
    if (params['start'] === '') {
      delete params['start'];
    }
    params['end'] = dates && dates[1] ? dates[1].format('YYYY-MM-DD') : '';
    if (params['end'] === '') {
      delete params['end'];
    }
    const path = pathTo(component, { id: careGuideId });
    history.push(QueryString.append(path, params));
    setShowFirstKey(false);
    setActiveKey([]);
  };

  const onChangeCollapse = (key) => {
    setShowFirstKey(false);
    setActiveKey(key);
  };

  const isActiveKey = (key) => activeKey.includes(key);

  const onChange = (newPage, pageSize) => {
    setShowFirstKey(false);
    setActiveKey([]);
    const path = pathTo(component, {
      id: careGuideId,
      page: newPage,
      limit: pageSize,
    });
    history.push(QueryString.append(path, { order, ...filter }));
  };

  const onClickExportHandler = (partnershipId, careGuideId, id) => {
    generatePDF(partnershipId, careGuideId, id);
  };

  const filterString = JSON.stringify(filter);

  useEffect(() => {
    load(
      partnershipId,
      careGuideId,
      page,
      limit,
      order,
      JSON.parse(filterString)
    );
  }, [careGuideId, partnershipId, page, limit, filterString, order, load]);

  const genExtra = (id) => (
    <DeleteOutlined
      style={{
        fontSize: '16px',
        color: 'red',
      }}
      onClick={(event) => {
        event.stopPropagation();
        setDailyLogId(id);
        setConfirmationModalOpen(true);
      }}
    />
  );

  const transformationText = (text) => {
    return Array.isArray(text.split(/(?=[A-Z])/))
      ? text.split(/(?=[A-Z])/).join(' ') + ': '
      : text.split(/(?=[A-Z])/)[0] + ': ';
  };

  useEffect(() => {
    if (showFirstKey && page === 1 && total > 0) {
      setActiveKey([list[0].id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFirstKey, page, total]);

  return (
    <Translation>
      {(t) => (
        <>
          <Col
            flex="auto"
            sm={{ span: 24 }}
            className={`info-col`}
            style={{
              width: '100%',
              padding: '40px',
            }}
          >
            <Title level={3}>{t('care_guide_owners_cognito')}</Title>
            <RangePicker
              dropdownClassName={'daily-log-date-picker'}
              format="MM/DD/YYYY"
              size="large"
              onChange={onChangeDateHandler}
              value={[defaultStart, defaultEnd]}
              style={{ width: '100%' }}
            />

            <br />
            <br />
            {waitEntries > 0 && (
              <>
                <Alert
                  className="alert-waiting-socket"
                  message={t(
                    'care_guide_owners_cognito_daily_logs_count_entries',
                    {
                      count: waitEntries,
                    }
                  )}
                  showIcon
                  icon={
                    <LoadingOutlined style={{ fontSize: 16, color: 'white' }} />
                  }
                ></Alert>
                <br />
              </>
            )}
            {total > 0 ? (
              <>
                <Collapse
                  style={{ backgroundColor: 'white' }}
                  activeKey={activeKey}
                  onChange={onChangeCollapse}
                >
                  {list
                    ? list.map((x) => (
                        <>
                          {x?.answers ? (
                            <Panel
                              header={
                                <>
                                  <Text
                                    type={!isActiveKey(x.key) && 'secondary'}
                                    style={{
                                      fontSize: '16px',
                                      color: isActiveKey(x.key) && '#4279BD',
                                    }}
                                  >
                                    {Format.dateTable(x.created_at)}
                                  </Text>
                                  {isActiveKey(x.key) ? (
                                    <div
                                      style={{
                                        marginLeft: '12px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <CaretUpOutlined />
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        marginLeft: '12px',
                                        textAlign: 'center',
                                      }}
                                    >
                                      <CaretDownOutlined />
                                    </div>
                                  )}
                                </>
                              }
                              key={x.key}
                              extra={genExtra(x.id)}
                              showArrow={false}
                            >
                              <Row gutter={24}>
                                <Col span={11}>
                                  <Text strong>
                                    {t('care_guide_owners_cognito_field_name') +
                                      ': '}
                                  </Text>
                                  <Text>
                                    {x?.answers
                                      .filter((item) => item.key === 'Name')
                                      .map((item) => {
                                        return item.value;
                                      })}
                                  </Text>
                                </Col>
                                <Col span={11}>
                                  <Text strong>
                                    {t('care_guide_owners_cognito_field_date') +
                                      ': '}
                                  </Text>
                                  <Text>
                                    {x?.answers
                                      .filter((item) => item.key === 'Date')
                                      .map((item) => {
                                        return item.value;
                                      })}
                                  </Text>
                                </Col>
                              </Row>
                              <>
                                {x?.answers
                                  .filter(
                                    (item) =>
                                      item.key !== 'Name' &&
                                      item.key !== 'Date' &&
                                      item.key !== 'Comments' &&
                                      item.key !== 'Information'
                                  )
                                  .map((item, index, array) => {
                                    if (index % 2 === 0) {
                                      const nextEntry = array[index + 1];
                                      return (
                                        <Row
                                          gutter={24}
                                          style={{ marginTop: '20px' }}
                                          key={index}
                                        >
                                          <Col span={11}>
                                            <Text strong>
                                              {transformationText(item.key)}
                                            </Text>
                                            <Text>
                                              {Array.isArray(item.value)
                                                ? item.value.join(', ')
                                                : item.value}
                                            </Text>
                                          </Col>
                                          {nextEntry && (
                                            <Col span={11}>
                                              <Text strong>
                                                {transformationText(
                                                  nextEntry.key
                                                )}
                                              </Text>
                                              <Text>
                                                {Array.isArray(nextEntry.value)
                                                  ? nextEntry.value.join(', ')
                                                  : nextEntry.value}
                                              </Text>
                                            </Col>
                                          )}
                                        </Row>
                                      );
                                    }
                                    return null;
                                  })}
                              </>
                              <Row style={{ marginTop: '20px' }}>
                                <Col flex="auto">
                                  <Text strong>
                                    {t(
                                      'care_guide_owners_cognito_field_comments'
                                    ) + ': '}
                                  </Text>
                                  <Text>
                                    {x?.answers
                                      .filter((item) => item.key === 'Comments')
                                      .map((item) => {
                                        return item.value;
                                      })}
                                  </Text>
                                </Col>
                              </Row>
                              {x?.answers
                                .filter((item) => item.key === 'Information')
                                .map((item) => {
                                  return (
                                    <Row style={{ marginTop: '20px' }}>
                                      <Col flex="auto">
                                        <Text>{item.value}</Text>
                                      </Col>
                                    </Row>
                                  );
                                })}

                              <Row style={{ marginTop: '20px' }} justify="end">
                                <Col>
                                  <Button
                                    style={{ borderRadius: '4px' }}
                                    type="primary"
                                    icon={<FileOutlined />}
                                    disabled={isGeneratingPDF}
                                    onClick={() =>
                                      onClickExportHandler(
                                        partnershipId,
                                        careGuideId,
                                        x.id
                                      )
                                    }
                                  >
                                    {t(
                                      'care_guide_owners_cognito_download_pdf'
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            </Panel>
                          ) : null}
                        </>
                      ))
                    : null}
                </Collapse>
                <br />
                <div style={{ textAlign: 'center' }}>
                  {total > 10 && (
                    <Pagination
                      defaultCurrent={5}
                      style={{ textAlign: 'center' }}
                      current={page}
                      pageSize={limit}
                      showSizeChanger={total > 10}
                      position={['bottomCenter']}
                      onChange={onChange}
                      total={total}
                      pageSizeOptions={['10', '20']}
                    />
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  height: '826px',
                  backgroundColor: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Empty
                  description={
                    <Text>
                      {t('care_guide_owners_cognito_empty_answers_description')}
                    </Text>
                  }
                />
              </div>
            )}
          </Col>
        </>
      )}
    </Translation>
  );
};

export default withRouter(DailyLogsList);
