import { Map, List } from 'immutable';
import { TYPES } from './actions';
import Logger from '../../../lib/Logger';

export default function partnershipFormAnswer(
  state = Map({
    isLoading: false,
    isGeneratingPDF: false,
    pages: {},
  }),
  action
) {
  Logger.log(
    'debug',
    `[partnershipFormAnswers.reducers] partnershipFormAnswers(%j, %j)`,
    state,
    action
  );

  switch (action.type) {
    case TYPES.LIST_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.LIST_SUCCESS:
      return state
        .mergeDeep({
          isLoading: false,
          pages: {
            [action.partnershipId]: {
              [action.careGuideId]: {
                [action.page]: {
                  [action.limit]: {
                    [action.order]: null,
                  },
                },
              },
            },
          },
          total: action.total,
          lastUpdated: action.receivedAt,
        })
        .setIn(
          [
            'pages',
            action.partnershipId,
            action.careGuideId,
            action.page,
            action.limit,
            action.order,
          ],
          List(action.result)
        );

    case TYPES.LIST_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.DELETE_REQUEST:
      return state.mergeDeep({
        isLoading: true,
      });

    case TYPES.DELETE_SUCCESS:
      return state.mergeDeep({
        isLoading: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.DELETE_FAILURE:
      return state.mergeDeep({
        isLoading: false,
      });

    case TYPES.PDF_REQUEST:
      return state.mergeDeep({
        isGeneratingPDF: true,
      });

    case TYPES.PDF_SUCCESS:
      return state.mergeDeep({
        isGeneratingPDF: false,
        lastUpdated: action.receivedAt,
      });

    case TYPES.PDF_FAILURE:
      return state.mergeDeep({
        isGeneratingPDF: false,
      });

    default:
      return state;
  }
}

Logger.log('silly', `partnershipFormAnswers.reducers loaded.`);
