import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import InviteButtonCareGuideHelpers from '../components/InviteButtonCareGuideHelpers';
import { schema } from '../../../../../state/schema';
import { careGuideInvitationShowForm } from '../../../../../state/modules/careGuideInvitations/actions';

const mapStateToProps = (state, ownProps) => {
  const careGuideId = ownProps.careGuideId;
  const careGuide = careGuideId
    ? denormalize(
        state.entities.getIn(['careGuides', careGuideId]),
        schema.careGuide,
        state.entities.toJS()
      )
    : null;

  const count = state.careGuideHelpers.getIn(['helperCounts', careGuideId]);
  const limit =
    careGuide && careGuide.account ? careGuide.account.helper_limit : null;

  return {
    canInvite: ownProps.mock
      ? true
      : Number.isInteger(count) && Number.isInteger(limit) && limit > count
      ? true
      : false,
    isLoading: state.careGuideHelpers.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showForm: (helperId) => {
      dispatch(careGuideInvitationShowForm(helperId));
    },
  };
};

const InviteButtonCareGuideHelpersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteButtonCareGuideHelpers);

export default InviteButtonCareGuideHelpersContainer;
