import { connect } from 'react-redux';
import { denormalize } from 'normalizr';

import {
  loadCareGuideDocuments,
  careGuideDocumentShowPreview,
  patchCareGuideDocument,
} from '../../../../../state/modules/careGuideDocuments/actions';
import { schema } from '../../../../../state/schema';

import CareGuideDocumentsTable from '../components/CareGuideDocumentsTable';

const mapStateToProps = (state, ownProps) => {
  const order = ownProps.order;
  const limit = ownProps.limit;
  const page = ownProps.page;
  const careGuideId = ownProps.careGuideId;

  const result = state.careGuideDocuments.getIn([
    'pages',
    careGuideId,
    order,
    limit,
    page,
  ]);

  const list = result
    ? result
        .map((x) => {
          return {
            key: x,
            ...denormalize(
              state.entities.getIn(['careGuideDocuments', x]),
              schema.careGuideDocument,
              state.entities.toJS()
            ),
          };
        })
        .toArray()
    : [];

  return {
    list,
    total: state.careGuideDocuments.get('total'),
    isLoading: state.careGuideDocuments.get('isLoading'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (careguideId, page, limit, order, filter, cb) => {
      dispatch(
        loadCareGuideDocuments(careguideId, page, limit, order, filter, cb)
      );
    },
    patch: (careGuideId, id, data, cb) => {
      dispatch(patchCareGuideDocument(careGuideId, id, data, cb));
    },
    showPreview: (careGuideDocumentId) => {
      dispatch(careGuideDocumentShowPreview(careGuideDocumentId));
    },
  };
};

const CareGuideDocumentsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CareGuideDocumentsTable);

export default CareGuideDocumentsTableContainer;
