import React, { useState } from 'react';
import { getI18n } from 'react-i18next';

import { Button, Cascader } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { FiEdit } from 'react-icons/fi';
import { withRouter } from 'react-router';
import { pathTo } from '../../../Routes';
import ConfirmationModal from '../../../elements/components/ConfirmationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faBan,
  faThumbtack,
  faCheck,
  faFileArrowDown,
  faFileCsv,
} from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

const confirmTexts = {
  title: getI18n().t('resource_card_action_delete_title'),
  description: null,
  leaveText: getI18n().t('resource_card_action_delete_leave'),
  saveText: getI18n().t('resource_card_action_delete_save'),
};

const ListAction = ({
  row,
  partnershipName,
  partnershipActiveId,
  editScreen,
  history,
  handlePinned,
  handleDisabled,
  handleDownloadCSV,
  handleDownloadPDF,
  confirmDelete,
  page,
  limit,
  order,
  filter,
  isDownload,
  ...props
}) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalDisabledOpen, setConfirmationModalDisabledOpen] =
    useState(false);

  const { is_pinned, id, status } = row;

  const confirmTextsDisabled = {
    title:
      status === 1
        ? getI18n().t('resource_card_action_disabled_title')
        : getI18n().t('resource_card_action_enabled_title'),
    description: null,
    leaveText:
      status === 1
        ? getI18n().t('resource_card_action_disable_leave')
        : getI18n().t('resource_card_action_enable_leave'),
    saveText: getI18n().t('resource_card_action_disabled_save'),
  };

  const menuItems = [
    {
      label: (
        <span className="resource-table-menu-item">
          <FiEdit />
          <span className="text-align">
            {getI18n().t('resource_card_action_edit')}
          </span>
        </span>
      ),
      disabled: status !== 1,
      value: 1,
    },
    {
      label: (
        <span className={'resource-table-menu-item'}>
          {is_pinned ? (
            <FontAwesomeIcon
              icon={faThumbtack}
              size="2x"
              style={{ opacity: 0.5 }}
            />
          ) : (
            <FontAwesomeIcon icon={faThumbtack} size="2x" />
          )}
          <span className="text-align">
            {is_pinned
              ? getI18n().t('resource_card_action_un_pin')
              : getI18n().t('resource_card_action_pinned')}
          </span>
        </span>
      ),
      disabled: status !== 1,
      value: 2,
    },
    {
      label: (
        <span className="resource-table-menu-item" style={{ color: '#F44336' }}>
          <FontAwesomeIcon icon={faTrashCan} size="2x" />
          <span className="text-align">
            {getI18n().t('resource_card_action_delete')}
          </span>
        </span>
      ),
      value: 3,
    },
    {
      label: (
        <span className="resource-table-menu-item">
          {status === 1 ? (
            <FontAwesomeIcon icon={faBan} size="2x" />
          ) : (
            <FontAwesomeIcon icon={faCheck} size="2x" />
          )}
          <span className="text-align">
            {status === 1
              ? getI18n().t('resource_card_action_disabled')
              : getI18n().t('resource_card_action_enabled')}
          </span>
        </span>
      ),
      value: 4,
    },
    {
      label: (
        <span className="resource-table-menu-item">
          <FontAwesomeIcon icon={faFileCsv} size="2x" />
          <span className="text-align">
            {getI18n().t('resource_card_action_download_csv')}
          </span>
        </span>
      ),
      disabled: isDownload,
      value: 5,
    },
    {
      label: (
        <span className="resource-table-menu-item">
          <FontAwesomeIcon icon={faFileArrowDown} size="2x" />
          <span className="text-align">
            {getI18n().t('resource_card_action_download_pdf')}
          </span>
        </span>
      ),
      disabled: isDownload,
      value: 6,
    },
  ];

  const onChange = (item) => {
    const value = item[0];

    if (value === 1) {
      history.push(
        pathTo(editScreen, { id_partnership: partnershipActiveId, id: id })
      );
    } else if (value === 2) {
      handlePinned(partnershipActiveId, row, page, limit, order, filter);
    } else if (value === 3) {
      setConfirmationModalOpen(true);
    } else if (value === 4) {
      setConfirmationModalDisabledOpen(true);
    } else if (value === 5) {
      handleDownloadCSV(partnershipActiveId, partnershipName, row);
    } else if (value === 6) {
      handleDownloadPDF(partnershipActiveId, partnershipName, row);
    }
  };

  const handleConfirmLeave = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmDelete = () => {
    setConfirmationModalOpen(false);
    confirmDelete();
  };

  const handleConfirmLeaveDisabled = () => {
    setConfirmationModalDisabledOpen(false);
  };

  const handleConfirmDisabled = () => {
    handleDisabled(
      partnershipActiveId,
      row,
      status === 1 ? 2 : 1,
      page,
      limit,
      order,
      filter
    );
    setConfirmationModalDisabledOpen(false);
  };

  return (
    <span onClick={(e) => e.stopPropagation()} className="actions">
      <Cascader
        options={menuItems}
        onChange={onChange}
        dropdownMenuColumnStyle={{ height: '32px' }}
        defaultValue={[]}
        value={[]}
      >
        <Button
          style={{ verticalAlign: '2px' }}
          icon={<EllipsisOutlined />}
          type="text"
          onClick={(e) => e.stopPropagation()}
        ></Button>
      </Cascader>
      <ConfirmationModal
        texts={confirmTexts}
        openConfirmationModal={confirmationModalOpen}
        onLeaveConfirmation={handleConfirmDelete}
        onSaveConfirmation={handleConfirmLeave}
        onCancel={handleConfirmLeave}
        setConfirmationModalOpen={setConfirmationModalOpen}
      />
      <ConfirmationModal
        texts={confirmTextsDisabled}
        openConfirmationModal={confirmationModalDisabledOpen}
        onLeaveConfirmation={handleConfirmDisabled}
        onSaveConfirmation={handleConfirmLeaveDisabled}
        onCancel={handleConfirmLeaveDisabled}
        setConfirmationModalOpen={setConfirmationModalDisabledOpen}
      />
    </span>
  );
};

export default withRouter(ListAction);
