import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu } from 'antd';
import { Translation } from 'react-i18next';

import {
  BarcodeOutlined,
  TagsOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
  ShareAltOutlined,
  ContactsOutlined,
  SolutionOutlined,
  ControlOutlined,
} from '@ant-design/icons';

import { pathTo, hasRoute } from '../../Routes';
import Logger from '../../../../lib/Logger';
import { MixpanelTracker, mixpanelEventsEnum } from '../../../../lib/Mixpanel';

function DrawerMenuAdmin(props) {
  const routes = [
    {
      key: 'partnership_account',
      screen: 'PartnershipAccountScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'partnership_account_menu_item_list',
      icon: <ShareAltOutlined />,
      parentScreen: 'PartnershipAccountScreen',
      access:
        props.role === 2
          ? props.accessAdmin.partnership_account
          : props.role === 1
          ? true
          : false,
    },
    {
      key: 'registration_code',
      screen: 'RegistrationCodesScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'registration_code_menu_item_list',
      icon: <BarcodeOutlined />,
      parentScreen: 'RegistrationCodesScreen',
      access:
        props.role === 2
          ? props.accessAdmin.register_code
          : props.role === 1
          ? true
          : false,
    },
    {
      key: 'journal_partner_tag_admin',
      screen: 'JournalPartnerTagsAdminScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'journal_partner_tag_menu_item_list',
      icon: <TagsOutlined />,
      parentScreen: 'JournalPartnerTagsAdminScreen',
      access:
        props.role === 2
          ? props.accessAdmin.journal_partner_tags
          : props.role === 1
          ? true
          : false,
    },
    {
      key: 'care_guide_partner_tag_admin',
      screen: 'CareGuidePartnerTagsAdminScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'care_guide_partner_tag_menu_item_list',
      icon: <TagsOutlined />,
      parentScreen: 'CareGuidePartnerTagsAdminScreen',
      access:
        props.role === 2
          ? props.accessAdmin.care_guide_partner_tags
          : props.role === 1
          ? true
          : false,
    },
    {
      key: 'user_profile_partnership',
      screen: 'UserProfilesPartnershipsAdminScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'user_profiles_partnerships_menu_item_list',
      icon: <UserSwitchOutlined />,
      parentScreen: 'UserProfilesPartnershipsAdminScreen',
      access:
        props.role === 2
          ? props.accessAdmin.user_roles
          : props.role === 1
          ? true
          : false,
    },
    {
      key: 'calendar_events_admin',
      screen: 'CalendarEventsAdminScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'calendar_events_menu_item_list',
      icon: <CalendarOutlined />,
      parentScreen: 'CalendarEventsAdminScreen',
      access:
        props.role === 2
          ? props.accessAdmin.calendar
          : props.role === 1
          ? true
          : false,
    },
    {
      key: 'resources_admin',
      screen: 'ResourcesAdminScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'resources_menu_item_list',
      icon: <ContactsOutlined />,
      parentScreen: 'ResourcesAdminScreen',
      access:
        props.role === 2
          ? props.accessAdmin.resources
          : props.role === 1
          ? true
          : false,
    },
    {
      key: 'care_guide_admin',
      screen: 'CareGuidesAdminScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'care_guide_menu_item_list',
      icon: <SolutionOutlined />,
      parentScreen: 'CareGuidesAdminScreen',
      access:
        props.role === 2
          ? props.accessAdmin.care_guides
          : props.role === 1
          ? true
          : false,
    },
    {
      key: 'dashboard',
      screen: 'DashboardAdminScreen',
      pathParams: props.activeId ? { id: props.activeId } : null,
      title: 'dashboard_admin_menu_item_list',
      icon: <ControlOutlined />,
      parentScreen: 'DashboardAdminScreen',
      access:
        props.role === 2
          ? props.accessAdmin.dashboard
          : props.role === 1
          ? true
          : false,
    },
  ];

  const handleChangePage = (page) => {
    if (props.partnershipName) {
      MixpanelTracker.mixpanelTrack(
        mixpanelEventsEnum.PAGES.ACCESS_PAGE_ADMINISTRATOR,
        {
          partnershipName: props.partnershipName,
          partnershipId: props.activeId,
          page: page,
        }
      );
    }
  };

  return (
    <Translation>
      {(t) => (
        <Menu theme="light" mode="inline" selectedKeys={null} inlineIndent={0}>
          {routes.map((x) =>
            hasRoute(x.screen) && x.access ? (
              <Menu.Item key={x.key} onClick={() => handleChangePage(x.screen)}>
                <Link
                  to={pathTo(x.screen, x.pathParams)}
                  onClick={props.toggleDrawer}
                >
                  <span className="drawer-menu-icon">{x.icon}</span>
                  <span>{t(x.title)}</span>
                </Link>
              </Menu.Item>
            ) : null
          )}
        </Menu>
      )}
    </Translation>
  );
}

export default withRouter(DrawerMenuAdmin);

Logger.log('silly', `DrawerMenu loaded.`);
