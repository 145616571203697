import storage from '../../../../lib/Storage';

export const hasPermissionAdmin = () => {
  const accessList = storage.get(
    'local',
    'partnershipData'
  )?.access_role_admin_in_app;
  const role = storage.get('local', 'partnershipData')?.role;
  let accessListModules = [];
  if (accessList && role === 2) {
    for (let access in accessList) {
      if (accessList[access]) {
        accessListModules.push(access);
      }
    }
    return accessListModules;
  } else if (role === 1) {
    for (let access in accessList) {
      accessListModules.push(access);
    }
    return accessListModules;
  }
  return null;
};

export const pathRedirect = (accessList) => {
  let accessListModules = {
    partnership_account: 'PartnershipAccountScreen',
    register_code: 'RegistrationCodesScreen',
    journal_partner_tags: 'JournalPartnerTagsAdminScreen',
    user_roles: 'UserProfilesPartnershipsAdminScreen',
    calendar: 'CalendarEventsAdminScreen',
    resources: 'ResourcesAdminScreen',
    care_guides: 'CareGuidesAdminScreen',
    care_guide_partner_tags: 'CareGuidePartnerTagsAdminScreen',
    dashboard: 'DashboardAdminScreen',
  };

  let accessScreens = [];

  if (accessList) {
    accessList.map((access, index) => {
      if (accessListModules[access]) {
        accessScreens.push(accessListModules[access]);
      }
      return null;
    });
  }
  return accessScreens.length > 0 ? accessScreens : null;
};
