import { connect } from 'react-redux';
import { denormalize } from 'normalizr';
import { schema } from '../../../../../state/schema';
import PartnershipsSelector from '../components/PartnershipsSelector';
import {
  loadUserProfilesPartnerships,
  userProfilesPartnershipSetActive,
} from '../../../../../state/modules/userProfilesPartnerships/actions';
import {
  careGuideSetActive,
  careGuideSetAccess,
} from '../../../../../state/modules/careGuides/actions';
import storage from '../../../../../lib/Storage';

export const partnershipAdminScreens = [
  'AdminScreen',
  'CalendarEventsAdminScreen',
  'CareGuidePartnerTagsAdminScreen',
  'CareGuidesAdminScreen',
  'JournalPartnerTagsAdminScreen',
  'PartnershipAccountScreen',
  'RegistrationCodesScreen',
  'ResourcesAdminScreen',
  'UserProfilesPartnershipsAdminScreen',
  'DashboardAdminScreen',
];

export const partnershipScreens = [
  'CareGuideOwnersScreen',
  'CareGuideMembersActions',
  'ChatScreen',
];

const mapStateToProps = (state, ownProps) => {
  const pageUserProfilePartnership = storage.get(
    'local',
    'userProfilePartnershipPage'
  )
    ? storage.get('local', 'userProfilePartnershipPage')
    : 1;
  const limit = 10;
  const orderUserProfilePartnership = 'updated_at.asc';
  const result = state.userProfilesPartnerships.getIn([
    'pages',
    orderUserProfilePartnership,
    limit,
    pageUserProfilePartnership,
  ]);

  const defaultId = storage.get('local', 'userProfilePartnershipId');

  const currentUserProfilePartnership = denormalize(
    state.entities.getIn(['userProfilesPartnerships', defaultId]),
    schema.userProfilePartnership,
    state.entities.toJS()
  );

  const defaultLabel = currentUserProfilePartnership?.partnership.name
    ? currentUserProfilePartnership.partnership.name
    : '';

  return {
    list: result
      ? result
          .map((x) => {
            return {
              key: x,
              ...denormalize(
                state.entities.getIn(['userProfilesPartnerships', x]),
                schema.userProfilePartnership,
                state.entities.toJS()
              ),
            };
          })
          .toArray()
          .filter((x) => x.partnership?.status === 1)
      : [],
    total: state.userProfilesPartnerships.get('total'),
    isLoading: state.userProfilesPartnerships.get('isLoading'),
    defaultId: defaultId,
    defaultLabel: defaultLabel,
    partnershipAdminScreens: partnershipAdminScreens,
    pageUserProfilePartnership,
    limit,
    orderUserProfilePartnership,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (page, limit, order, filter, cb) => {
      dispatch(loadUserProfilesPartnerships(page, limit, order, filter, cb));
    },
    setActive: (id) => {
      dispatch(userProfilesPartnershipSetActive(id));
    },
    setActiveCareGuide: (id) => {
      dispatch(careGuideSetActive(id));
    },
    setAccessCareGuide: (data) => {
      dispatch(careGuideSetAccess(data));
    },
  };
};

const PartnershipsSelectorSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnershipsSelector);

export default PartnershipsSelectorSelectorContainer;
